import React, { useState, useRef } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Calendar.scss';
import moment from 'moment';
import 'moment/locale/ko'; // 한국어 로케일을 임포트합니다

moment.locale('ko'); // 한국어 로케일을 설정합니다

// 법정공휴일 정보 (API로 받아와야함)
const holidays = {
    '2024-01-01': '신정',
    '2024-03-01': '삼일절',
    '2024-05-05': '어린이날',
    '2024-05-15': '석가탄신일',
    '2024-06-06': '현충일',
    '2024-08-15': '광복절',
    '2024-09-13': '추석',
    '2024-10-03': '개천절',
    '2024-12-25': '성탄절'
};

const App = () => {
    const [date, setDate] = useState(new Date()); // 현재 선택된 날짜 상태
    const [activeStartDate, setActiveStartDate] = useState(new Date()); // 현재 표시된 월의 시작 날짜 상태
    const calendarRef = useRef(); // Calendar 컴포넌트에 접근하기 위한 ref 생성

    // 이벤트 정보 (db에 저장된 이벤트 불러와야함)
    const events = {
        '2024-05-16': [{ name: '제179회 CEO조찬집담회', type: 1 }],
        '2024-05-18': [{ name: '임시Event 2', type: 2 }],
        '2024-05-22': [
            { name: '제28회 한국공학한림원 대상 수상후보 추가 검증', type: 2 },
            { name: '제28회 한국공학한림원 젊은공학인상 수상후보 검증', type: 2 },
            { name: '2제28회 한국공학한림원 젊은공학인상 수상후보 검증22', type: 2 },
        ],
        '2024-05-27': [{ name: '제274회 NAEK포럼', type: 3 }],
    };

    // 날짜가 변경될 때 호출되는 함수
    const onChange = date => {
        setDate(date);
    };

    // 활성 시작 날짜가 변경될 때 호출되는 함수
    const onActiveStartDateChange = ({ activeStartDate }) => {
        setActiveStartDate(activeStartDate);
    };

    // 날짜를 'YYYY-MM-DD' 형식으로 포맷하는 함수
    const formatDate = date => {
        return moment(date).format('YYYY-MM-DD');
    };

    // 각 타일에 추가적인 콘텐츠를 렌더링하는 함수
    const tileContent = ({ date, view }) => {
        const eventDate = formatDate(date);
        const dateStr = moment(date).format('YYYY-MM-DD');
        const holiday = holidays[dateStr];

        //년도 선택에는 공휴일이 있을 필요가 없음
        if (view === 'year' || view === 'decade') {
            return null; // 연간 보기에서는 공휴일 표시하지 않음
        }


        return (
            <>
                {events[eventDate] && (
                    <div className="events">
                        {events[eventDate].map((event, index) => (
                            //여기서 type 1이면 event_forum / 2면 event_meet / 3이면 event_etc
                            <div
                                key={index}
                                className={`event ${event.type === 1 ? 'event_forum' : event.type === 2 ? 'event_meet' : 'event_etc'}`}
                                onClick={() => console.log(`Event date: ${eventDate}`)}
                            >
                                <span>{event.name}</span>
                            </div>
                        ))}
                    </div>
                )}
                {holiday && (
                    <div className="holiday-name">
                        <span>{holiday}</span>
                    </div>
                )}
            </>
        );
    };

    // 타일을 비활성화할 조건을 정의하는 함수
    const tileDisabled = ({ date, view }) => {
        if (view === 'month') {
            const selectedMonth = moment(activeStartDate).month();  // 현재 표시된 월
            return date.getMonth() !== selectedMonth;
        }
        return false;
    };

    // 타일에 클래스 이름을 부여하는 함수
    const tileClassName = ({ date, view }) => {
        if (view === 'month') {
            const dateStr = moment(date).format('YYYY-MM-DD');
            if (holidays[dateStr]) {
                return 'holiday';
            }
            if (date.getDay() === 0) {
                return 'sunday';
            }
            if (date.getDay() === 6) {
                return 'saturday';
            }
        }
        return null;
    };

    // const clickFunc = (value, event) => {
    //     const clickDate = formatDate(value);
    //     console.log('Clicked day: '+ clickDate);
    // };


    // 버튼 클릭 시 캘린더의 월을 현재 월로 설정
    const handleToMonthClick = () => {
        const calendar = calendarRef.current; // Calendar 컴포넌트의 ref 가져오기
        const firstDayOfTodaysMonth = moment().date(1).toDate(); // 현재 월의 첫 번째 날 계산
        calendar.setActiveStartDate(firstDayOfTodaysMonth); // 캘린더의 활성 시작 날짜를 현재 월의 첫 번째 날로 설정

    };

    const isSameMonth = moment(date).isSame(activeStartDate, 'month'); // 날짜가 변경될 때 호출되는 함수
    const toMonthBtnClass = isSameMonth ? 'toMonth_btn' : 'toMonth_btn btn_active'; // 활성 시작 날짜가 변경될 때 호출되는 함수


    return (
        <div className="p_calendar_wrap">
            <div className="eventList">
                <span className="list_txt forum">포럼</span>
                <span className="list_txt meet">회의</span>
                <span className="list_txt etc">기타</span>
            </div>
            <button
                className={toMonthBtnClass}
                onClick={handleToMonthClick}
                disabled={isSameMonth}
            >이번 달</button>
            <Calendar
                onChange={onChange} // 날짜가 변경될 때 호출되는 함수
                onActiveStartDateChange={onActiveStartDateChange} // 활성 시작 날짜가 변경될 때 호출되는 함수
                showFixedNumberOfWeeks={true} // 고정된 주 수를 표시
                value={date} // 현재 선택된 날짜 상태
                tileContent={tileContent} // 각 타일에 추가 콘텐츠를 렌더링하는 함수
                tileClassName={tileClassName} // 타일에 클래스 이름을 부여하는 함수
                tileDisabled={tileDisabled} // 타일을 비활성화할 조건을 정의하는 함수
                className="react-calendar" // 사용자 정의 CSS 클래스
                calendarType="gregory" // 그레고리력 사용
                locale="ko-KR" // 한국어 로케일 설정
                next2Label={null} // 두 번째 다음 버튼 숨기기
                prev2Label={null} // 두 번째 이전 버튼 숨기기
                showNeighboringMonth={false} // 이웃한 달의 날짜 숨기기
                minDetail="month" // 10년 단위 년도 숨기기 (month , year , decade , century)
                ref={calendarRef}
            // onClickDay={clickFunc} // 날짜를 클릭했을 때 호출되는 함수
            />
        </div>
    );
};

export default App;
