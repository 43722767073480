import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './Comment.scss';
import axios from 'axios';
import common from '../../common';
import { useSelector } from 'react-redux';
import { GoReply } from 'react-icons/go';
import { AiOutlineLike , AiOutlineMinusCircle , AiOutlinePlusCircle } from 'react-icons/ai';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Comment = ({targetIdx , type , totalCount}) => {


    const member_idx = useSelector((state) => state.user.loginInfo?.idx || null);
    const admin_grade_idx = useSelector((state) => state.user.loginInfo?.adminGradeIdx || null);


    const { pToast, useAlert, useModal, useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pModal, ModalComponent} = useModal();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
    
    const textareaRef = useRef(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // 댓글 데이터
    const [data, setData] = useState([]);
    

    const [formData, setFormData] = useState({
        statusIDX: 570103,
        contents: '',
        type: type,
        targetIdx: targetIdx,
        memberIDX: member_idx,
        momIDX : 0,
        depth : 0,
    });

    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/admin_document_comment/in/get_comment_admin_data`, { targetIdx : parseInt(targetIdx) } , {
                withCredentials: true
            });

            if (response.data.result === 't') {
                const data = response.data.data;
                setData(data)
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('Data fetching failed:', error);
        } finally {
            pLoadingOff();
        }
    }

    useEffect(() => {
        fetchData();
        if (!member_idx || (typeof member_idx === 'object' && Object.keys(member_idx).length === 0)) {
            
        }else{
            // fetchLikeData();
        }
    }, [targetIdx]);

    


    // 댓글 내용
    const handleCommentChange = (e) => {
        const value = e.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            contents: value,
        }));
        autoResizeTextarea();
    };

    // 댓글 textarea 입력 시 자동 높이 설정 (max-height는 css에서 조절)
    const autoResizeTextarea = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = '100px'; // 초기 height 설정
            textarea.style.height = `${textarea.scrollHeight}px`; // 내용에 맞게 height 조정
        }
    };



    const [replyOpen, setReplyOpen] = useState({});
    const [replyContents, setReplyContents] = useState({});
    const [replyAnonymous, setReplyAnonymous] = useState({});

    // 답글 textarea 토글
    const toggleReply = (index) => {
        setReplyOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index], // 현재 인덱스의 상태를 반전시킴
        }));
    };

    // 답글 내용 관리 및 별도의 상태 업데이트
    const handleReplyChange = (commentIdx, value) => {
        setReplyContents((prevState) => ({
            ...prevState,
            [commentIdx]: value,
        }));
    };

    // 댓(답)글 등록
    const submitComment = async (formData, type) => {


        let confirmTitle = '';
        let confirmContent = '';
        let msg = '';



        if (type === 'regist') {
            confirmTitle = '댓글작성';
            confirmContent = '댓글을 작성 하시겠습니까?';
            msg = '댓글 작성이 완료되었습니다.';

        } else if (type === 'reply') {
            confirmTitle = '답글작성';
            confirmContent = '해당 댓글에 답글을 작성 하시겠습니까?';
            msg = '답글 작성이 완료되었습니다.';

        }

        pConfirm(
            confirmTitle,
            <>
                <div>{confirmContent}</div>
            </>,
            '400px',
            'auto',
            async () => {
                try {
                    pLoadingOn();
                    const response = await axios.post(`${API_BASE_URL}/admin_document_comment/in/comment_insert`, formData, {
                        withCredentials: true
                    });

                    if (response.data.result === 't') {
                        pAlert(msg);
                        setFormData({
                            ...formData,
                            contents: '',
                            momIDX: 0,
                            depth: 0,
                        });

                        if(type === 'reply'){
                            setReplyContents('');
                        }


                        await fetchData();
                    } else {
                        pAlert(response.data.msg);
                    }
                } catch (error) {
                    console.error('Data fetching failed:', error);
                } finally {
                    pLoadingOff();
                }
            }
        );
    };

    // 댓글 등록 함수
    const handleAddComment = async () => {
        if (!member_idx || (typeof member_idx === 'object' && Object.keys(member_idx).length === 0)) {
            pAlert('로그인 상태에서 댓글을 작성하실 수 있습니다.');
            return false;
        }

        if (formData.contents === '') {
            pAlert('내용을 입력해주세요.');
            return false;
        }

        await submitComment(formData, 'regist');
    };

    // 답글 등록 함수
    const handleAddReply = async (commentIdx) => {
        if (!member_idx || (typeof member_idx === 'object' && Object.keys(member_idx).length === 0)) {
            pAlert('로그인 상태에서 답글을 작성하실 수 있습니다.');
            return false;
        }

        const replyData = {
            ...formData,
            statusIDX: 570103,
            contents: replyContents[commentIdx] || '',
            momIDX: commentIdx,
            depth: 1,
        };

        if (replyData.contents === '') {
            pAlert('답글 내용을 입력해주세요.');
            return false;
        }

        await submitComment(replyData, 'reply');
    };



















    // 댓글 삭제
    const handleDeleteComment = (idx , type , viewStatusIDX) => {


        let confirmTitle = '';
        let confirmContent = '';
        let msg = '';



        if (type === 'comment_del') {
            confirmTitle = '댓글 비활성';
            confirmContent = '댓글을 비활성 하시겠습니까? 하위 답글들도 모두 비활성됩니다.';
            msg = '댓글 비활성이 완료되었습니다.';

        } else if (type === 'comment_add') {
            confirmTitle = '답글 활성';
            confirmContent = '해당 댓글에 답글을 활성화 하시겠습니까?';
            msg = '댓글이 다시 활성화 되었습니다.';

        } else if (type === 'reply_del') {
            confirmTitle = '답글 비활성';
            confirmContent = '해당 댓글에 답글을 비활성 하시겠습니까?';
            msg = '답글 비활성이 완료되었습니다.';

        } else if (type === 'reply_add') {
            confirmTitle = '답글 활성';
            confirmContent = '해당 댓글에 답글을 활성화 하시겠습니까?';
            msg = '답글이 다시 활성화 완료되었습니다.';

        }



        pConfirm(
            confirmTitle,
            <>
                <div>{confirmContent}</div>
            </>,
            '400px',
            'auto',
            async () => {
                try {
                    pLoadingOn();
                    const response = await axios.post(`${API_BASE_URL}/admin_document_comment/in/comment_status_update`, {targetIdx : idx , viewStatusIDX : viewStatusIDX}, {
                        withCredentials: true
                    });

                    if (response.data.result === 't') {
                        pAlert(msg);
                        setFormData({
                            ...formData,
                            contents: '',
                        });
                        await fetchData();
                    } else {
                        pAlert(response.data.msg);
                    }
                } catch (error) {
                    console.error('Data fetching failed:', error);
                } finally {
                    pLoadingOff();
                }
            }
        );
    };





    



    

    

    

    return (
        <>
            <ModalComponent/>
            <AlertComponent/>
            <ConfirmComponent/>
            <LoadingComponent/>
            <div className="p_comment_wrap">
                <div className="p_comment_title_box">
                    <span className="p_comment_title">댓글 {data.length}개</span>
                    <span className="p_comment_title">게시글 추천수 {totalCount}개</span>
                </div>
                <textarea 
                    ref={textareaRef}
                    value={formData.contents}
                    onChange={handleCommentChange}
                    placeholder="댓글을 입력하세요"
                    className="p_comment_textarea"
                />
                <div className="p_comment_register_btn_box">
                    <button
                        className="p_comment_register_btn"
                        onClick={handleAddComment}>
                    등록
                    </button>
                </div>
                
                <div className="comments_list">
                    {data.filter(comment => comment.momIDX === 0).map((comment) => (
                        <div key={comment.idx} className={`comment_item ${comment.viewStatus === 'inActive' ? 'inActive_background' : ''}`}>
                            <div className="comment_header">
                                {comment.status === 'user' ? (
                                    <>
                                        <span className="comment_name">
                                            <img src={comment.gradeImg ? `/img/${comment.gradeImg}` : '/img/userImg.png'} alt="" className='comment_grade_img' />
                                            <span className='comment_member'>{comment.member}</span>
                                        </span>
                                        <span className="comment_university">
                                            {comment.university || comment.major ? (
                                                <>
                                                    {comment.university || '-'} / {comment.major || '-'}
                                                </>
                                            ) : (
                                                '-'
                                            )}
                                        </span>
                                    </>
                                ) : comment.status === 'anonymous' ? (
                                    <>
                                        {admin_grade_idx === 1 ? (
                                            <>
                                            <span className="comment_name">
                                                <img src={comment.gradeImg ? `/img/${comment.gradeImg}` : '/img/userImg.png'} alt="" className='comment_grade_img' />
                                                    익명
                                                <span className='comment_member'>({comment.member})</span>
                                            </span>
                                            <span className="comment_university">
                                                {comment.university || comment.major ? (
                                                    <>
                                                        {comment.university || '-'} / {comment.major || '-'}
                                                    </>
                                                ) : (
                                                    '-'
                                                )}
                                            </span>
                                            </>
                                        ):(
                                            <>
                                            <span className="comment_name">익명</span>
                                            </>
                                        )}
                                        
                                    </>
                                ) : comment.status === 'admin' ? (
                                    <>
                                        <span className="comment_name">
                                            <img src={comment.gradeImg ? `/img/${comment.gradeImg}` : '/img/userImg.png'} alt="" className='comment_grade_img' />
                                            관리자
                                            <span className='comment_member'>({comment.member})</span>
                                        </span>
                                        <span className="comment_university">
                                            {comment.university || comment.major ? (
                                                <>
                                                    {comment.university || '-'} / {comment.major || '-'}
                                                </>
                                            ) : (
                                                '-'
                                            )}
                                        </span>
                                    </>
                                ) : null}
                                <span className="comment_time">{comment.createTime}</span>
                                {comment.viewStatus === 'active' ? (
                                    <button 
                                        className='comment_del_btn' 
                                        onClick={() => handleDeleteComment(comment.idx , 'comment_del' , 570301)}
                                    >
                                        <AiOutlineMinusCircle />
                                </button>
                                ):(
                                    <button 
                                        className='comment_add_btn' 
                                        onClick={() => handleDeleteComment(comment.idx , 'comment_add' , 570201)}
                                    >
                                        <AiOutlinePlusCircle />
                                    </button>
                                )}
                                
                            </div>
                            <div className="comment_body">
                                <p>{comment.contents}</p>
                            </div>
                            <div className="comment_footer">
                                <span className="comment_likes">
                                    <AiOutlineLike /> {comment.likes}
                                </span>
                                <span 
                                    className="comment_reply" 
                                    onClick={() => toggleReply(comment.idx)}>
                                        답글
                                </span>
                            </div>

                            {data.filter(reply => reply.momIDX === comment.idx).map((reply) => (
                                <div key={reply.idx} className={`reply_item ${reply.viewStatus === 'inActive' ? 'inActive_background' : ''}`}>
                                    <div className="comment_header">
                                        {reply.status === 'user' ? (
                                            <>
                                                <span className="comment_name">
                                                    <img src={reply.gradeImg ? `/img/${reply.gradeImg}` : '/img/userImg.png'} alt="" className='comment_grade_img' />
                                                    <span className='comment_member'>{reply.member}</span>
                                                </span>
                                                <span className="comment_university">
                                                    {reply.university || reply.major ? (
                                                        <>
                                                            {reply.university || '-'} / {reply.major || '-'}
                                                        </>
                                                    ) : (
                                                        '-'
                                                    )}
                                                </span>
                                            </>
                                        ) : reply.status === 'anonymous' ? (
                                            <>
                                                {admin_grade_idx === 1 ? (
                                                    <>
                                                    <span className="comment_name">
                                                        <img src={reply.gradeImg ? `/img/${reply.gradeImg}` : '/img/userImg.png'} alt="" className='comment_grade_img' />
                                                        익명
                                                        <span className='comment_member'>({reply.member})</span>
                                                    </span>
                                                    <span className="comment_university">
                                                        {reply.university || reply.major ? (
                                                            <>
                                                                {reply.university || '-'} / {reply.major || '-'}
                                                            </>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </span>
                                                    </>
                                                ):(
                                                    <>
                                                    <span className="comment_name">익명</span>
                                                    </>
                                                )}
                                                
                                            </>
                                        ) : reply.status === 'admin' ? (
                                            <>
                                                <span className="comment_name">
                                                    <img src={reply.gradeImg ? `/img/${reply.gradeImg}` : '/img/userImg.png'} alt="" className='comment_grade_img' />
                                                    관리자
                                                    <span className='comment_member'>({reply.member})</span>
                                                </span>
                                                <span className="comment_university">
                                                    {reply.university || reply.major ? (
                                                        <>
                                                            {reply.university || '-'} / {reply.major || '-'}
                                                        </>
                                                    ) : (
                                                        '-'
                                                    )}
                                                </span>
                                            </>
                                        ) : null}
                                        <span className="comment_time">{reply.createTime}</span>
                                        {reply.viewStatus === 'active' ? (
                                            <button 
                                                className='comment_del_btn' 
                                                onClick={() => handleDeleteComment(reply.idx , 'reply_del' , 570301)}
                                            >
                                                <AiOutlineMinusCircle />
                                        </button>
                                        ):(
                                            <button 
                                                className='comment_add_btn' 
                                                onClick={() => handleDeleteComment(reply.idx , 'reply_add' , 570201)}
                                            >
                                                <AiOutlinePlusCircle />
                                            </button>
                                        )}
                                    </div>
                                    <div className="comment_body">
                                        <p>{reply.contents}</p>
                                    </div>
                                    <div className="comment_footer">
                                        <span className="comment_likes">
                                            <AiOutlineLike /> {reply.likes}
                                        </span>
                                    </div>
                                </div>
                            ))}

                            {/* 답글 입력창 */}
                            {replyOpen[comment.idx] && (
                                <>
                                    <div className="reply_section">
                                        <div className="comment_flex_wrap">
                                            <GoReply className='reply_icon'/>
                                            <textarea
                                                className="reply_textarea"
                                                placeholder="답글을 입력하세요"
                                                value={replyContents[comment.idx] || ''}
                                                onChange={(e) => handleReplyChange(comment.idx, e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="reply_button_box">
                                        <button
                                            className="reply_button"
                                            onClick={() => handleAddReply(comment.idx)}>
                                                답글 등록
                                        </button>
                                    </div>
                                </>
                            )}
                            
                        </div>
                    ))}
                </div>
            </div>
        </>
        
    );
};

export default Comment;
