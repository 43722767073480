import React, { useMemo, useState, useEffect, useCallback, forwardRef, useImperativeHandle } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import 'ag-grid-community/styles/ag-theme-balham.css';
import "./DataTable.scss";
import axios from 'axios';
import common from '../../common';
import { ExcelModal, DataTableModal } from "../index";

import DatePicker from 'react-datepicker';
import { FaCalendarDay } from 'react-icons/fa';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const DataTable = forwardRef(({ columnDefs, onRowSelected, tableLoadData, UserPopupYear, UserPopupDept, UserPopupDeptName,targetEventIDX }, ref) => {
    const { useAlert, useLoading, useConfirm, useDatePicker } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
    const { pConfirm, ConfirmComponent } = useConfirm();
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState(null);

    const defaultColDef = useMemo(() => ({
        flex: 1,
        minWidth: 70,
        sortable: true,
        filter: true,
        floatingFilter: true,
        resizable: true,
    }), []);

    const localeText = {
        contains: '포함',
        notContains: '포함하지 않음',
        equals: '같음',
        notEqual: '같지 않음',
        startsWith: '시작 문자',
        endsWith: '끝 문자',
        blank: '비어 있음',
        notBlank: '비어 있지 않음',
        page: '페이지',
        more: '더 보기',
        to: '부터',
        of: '까지',
        next: '다음',
        last: '마지막',
        first: '처음',
        previous: '이전',
        loadingOoo: '로딩 중...',
        pageSize: '페이지 크기',
        pageSizeChoose: '페이지 크기 선택',
        pageSizeAll: '모두',
        pagination: '페이지네이션',
        paginationPageSize: '페이지 크기',
        paginationPageSizeChoose: '페이지 크기 선택',
        noRowsToShow: '조회 결과가 없습니다.',
    };

    const addCheckboxColumn = (columnDefs) => {
        const modifiedColumnDefs = columnDefs.map(colDef => {
            if (colDef.field === 'tableType' || colDef.field === 'idx') {
                return { ...colDef, hide: true };
            }
            return {
                ...colDef,
                cellClassRules: {
                    'selected-cell': (params) => params.data.idx === selectedRowId
                }
            };
        });

        const tableLoadType = tableLoadData.tableType;

        if (tableLoadType === 'User') {
            return modifiedColumnDefs;
        }

        return [
            {
                headerCheckboxSelection: tableLoadType === 'UserGrade' || tableLoadType === 'UserPopup' || tableLoadType === 'Forum' ? false : true,
                checkboxSelection: true,
                filter: false,
                maxWidth: 50,
                cellClassRules: {
                    'selected-cell': (params) => params.data.idx === selectedRowId
                },
            },
            ...modifiedColumnDefs
        ];
    };

    const modifiedColumnDefs = useMemo(() => addCheckboxColumn(columnDefs), [columnDefs, selectedRowId]);



    

    // useEffect(() => {
    //     if (selectedRowId && gridApi) {
    //         fetchDetailData(selectedRowId);
    //     }
    // }, [selectedRowId]); // 의존성 배열에서 gridApi 제거

    const handleRowClicked = (event) => {
        const targetIDX = event.data.idx;
        if (targetIDX !== selectedRowId) {
            setSelectedRowId(targetIDX);
            fetchDetailData(event.data); // data 객체를 직접 넘김
        }
    };

    const fetchDetailData = useCallback(async (data) => {
        let response;
        try {
            pLoadingOn();
            if (data.tableType === 'User') {
                response = await axios.post(`${API_BASE_URL}/admin_member/in/get_detail`, { member_idx: data.idx }, {
                    withCredentials: true
                });
            } else if (data.tableType === 'UserGrade') {
                response = await axios.post(`${API_BASE_URL}/admin_grade/in/get_grade_history`, { member_idx: data.idx }, {
                    withCredentials: true
                });
            } else if (data.tableType === 'Forum') {
                response = await axios.post(`${API_BASE_URL}/admin_forum/in/get_forum_detail`, { target_idx: String(data.idx) }, {
                    withCredentials: true
                });
            } else if (data.tableType === 'EventSub') {
                return;
            } else if (data.tableType === 'EventRegist') {
                return;
            } else if (data.tableType === 'UserPopup') {
                return;
            } else {
                response = await axios.post(`${API_BASE_URL}/admin_document/in/get_detail`, { idx: String(data.idx), type: data.tableType }, {
                    withCredentials: true
                });
            }

            if (response.data.result === 't') {
                const data = response.data.data;
                if (onRowSelected) {
                    onRowSelected(data);
                }
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('Data fetching failed:', error);
        } finally {
            pLoadingOff();
            if (gridApi) {
                gridApi.refreshCells();
            }
        }
    }, [onRowSelected, pLoadingOn, pLoadingOff, pAlert, gridApi]);



    const sendSelectedRows = async (action) => {
        if (!gridApi) return;

        const selectedNodes = gridApi.getSelectedNodes();
        const selectedData = selectedNodes.map(node => ({
            idx: node.data.idx,
            tableType: node.data.tableType
        }));

        const idxArray = selectedData.map(item => item.idx);
        const tableType = selectedData[0]?.tableType || '';

        if (!tableType) {
            pAlert('선택된 행이 없습니다.');
            return;
        }

        const payload = {
            idx: idxArray,
            tableType
        };

        if (tableType === 'UserPopup') {
            const selectedData = selectedNodes.map(node => ({
                idx: '-1',
                member_idx: node.data.idx.toString(),
                major: node.data.major,
                university: node.data.university,
                dept_idx: UserPopupDept.toString(),
                position: UserPopupDeptName,
                seq: '0'
            }));

            try {
                pLoadingOn();
                const response = await axios.post(`${API_BASE_URL}/admin_organization/in/organization_edit`, { data_pack: selectedData, year_idx: UserPopupYear }, {
                    withCredentials: true
                });
                if (response.data.result === 't') {
                    pAlert('추가가 완료되었습니다. 확인 버튼을 눌러주세요.');
                } else {
                    pAlert(response.data.msg);
                }
            } catch (error) {
                console.error('Data sending failed:', error);
                pAlert('데이터 전송에 실패했습니다. 다시 시도해주세요.');
            } finally {
                pLoadingOff();
            }
        } else if (tableType === 'User') {

        } else if (tableType === 'UserGrade') {

        } else if (tableType === 'Forum') {
            const idx_pack = selectedData.map(item => item.idx);

            try {
                pLoadingOn();
                const response = await axios.post(`${API_BASE_URL}/admin_forum/in/forum_delete`, { idx_pack: idx_pack }, {
                    withCredentials: true
                });
                if (response.data.result === 't') {
                    if (ref && typeof ref.current.refreshTable === 'function') {
                        ref.current.refreshTable();
                    }
                    pAlert('삭제가 완료되었습니다.');
                } else {
                    pAlert(response.data.msg);
                }
            } catch (error) {
                console.error('Data sending failed:', error);
                pAlert('데이터 전송에 실패했습니다. 다시 시도해주세요.');
            } finally {
                pLoadingOff();
            }


        } else if (tableType === 'EventSub') {
            const idx_pack = selectedNodes.map(node => node.data.memberIDX);

            let status_name = '';
            let apiUrl = '';

            if(action === 'approve'){
                status_name = '최종참가';
                apiUrl = `${API_BASE_URL}/admin_event/in/event_attend`;
            } else if(action === 'decline'){
                status_name = '최종불참';
                apiUrl = `${API_BASE_URL}/admin_event/in/event_attend`;
            } else if(action === 'eventCancel'){
                status_name = '불참';
                apiUrl = `${API_BASE_URL}/admin_event/in/event_register`;
            }

            const sendFormData = {
                idx_pack : idx_pack,
                status_name : status_name,
                event_idx : targetEventIDX
            };

            const sendRequest = async (url, data) => {
                try {
                    pLoadingOn();
                    const response = await axios.post(url, data, {
                        withCredentials: true
                    });
                    if (response.data.result === 't') {
                        pAlert(status_name + ' 처리가 완료되었습니다');
                        if (ref && typeof ref.current.refreshTable === 'function') {
                            ref.current.refreshTable();
                        }
                    } else {
                        pAlert(response.data.msg);
                    }
                } catch (error) {
                    console.error('Data sending failed:', error);
                    pAlert('데이터 전송에 실패했습니다. 다시 시도해주세요.');
                } finally {
                    pLoadingOff();
                }
            };

            if (status_name) {
                sendRequest(apiUrl, sendFormData);
            }

        } else if (tableType === 'EventRegist') {
            const idx_pack = selectedData.map(item => item.idx);
            let status_name = '';
            if(tableType === 'EventRegist'){
                status_name = '참가';
            }

            const sendFormData = {
                idx_pack : idx_pack,
                status_name : status_name,
                event_idx : targetEventIDX
            };

            try {
                pLoadingOn();
                const response = await axios.post(`${API_BASE_URL}/admin_event/in/event_register`, sendFormData, {
                    withCredentials: true
                });
                if (response.data.result === 't') {
                    pAlert(status_name+' 신청이 완료되었습니다. 확인버튼을 눌러주세요.');
                    if (ref && typeof ref.current.refreshTable === 'function') {
                        ref.current.refreshTable();
                    }
                } else {
                    pAlert(response.data.msg);
                }
            } catch (error) {
                console.error('Data sending failed:', error);
                pAlert('데이터 전송에 실패했습니다. 다시 시도해주세요.');
            } finally {
                pLoadingOff();
            }

        } else {
            try {
                pLoadingOn();
                const response = await axios.post(`${API_BASE_URL}/admin_document/in/document_inactive`, payload, {
                    withCredentials: true
                });
                if (response.data.result === 't') {
                    if (ref && typeof ref.current.refreshTable === 'function') {
                        ref.current.refreshTable();
                    }
                } else {
                    pAlert(response.data.msg);
                }
            } catch (error) {
                console.error('Data sending failed:', error);
                pAlert('데이터 전송에 실패했습니다. 다시 시도해주세요.');
            } finally {
                pLoadingOff();
            }
        }
    };


    const handleSendSelectedRows = async (event) => {
        if (!gridApi) return;

        const selectedNodes = gridApi.getSelectedNodes();
        const selectedData = selectedNodes.map(node => ({
            idx: node.data.idx,
            tableType: node.data.tableType,
        }));

        const tableType = selectedData[0]?.tableType || '';

        if (!tableType) {
            pAlert('선택된 행이 없습니다.');
            return;
        }
        
        let confirmTitle;
        let dataTableModalwidth = '350px';
        let dataTableModalheight = 'auto';
        let dataTableModalCheck = '확인';
        let dataTableModalCancel = '취소';

        const action = event.currentTarget.getAttribute('data-action');

        if (tableType === 'EventSub') {
            confirmTitle = '해당 데이터들을 비공개로 변경하시겠습니까?';
        } else if (tableType === 'User') {
            confirmTitle = '회원';
        } else if (tableType === 'UserPopup') {
            confirmTitle = '회원을 추가하시겠습니까?';
        } else if (tableType === 'EventRegist') {
            confirmTitle = '해당 회원을 참가시키겠습니까?';
        } else if (tableType === 'EventCancel') {
            confirmTitle = '해당 회원을 불참시키겠습니까?';
        } else if (tableType === 'Forum') {
            confirmTitle = '해당 포럼을 삭제하시겠습니까?';
        } else {
            confirmTitle = '해당 데이터들을 비공개로 변경하시겠습니까?';
        }



        if(action === 'approve'){
            confirmTitle = '해당 회원을 최종 승인 하시겠습니까?';
        } else if(action === 'decline'){
            confirmTitle = '해당 회원을 최종 불참 시키겠습니까?';
        } else if(action === 'eventCancel'){
            confirmTitle = '해당 신청건을 불참처리 시키겠습니까?';
        }
        



        

        pConfirm(
            '확인',
            confirmTitle,
            dataTableModalwidth,
            dataTableModalheight,
            () => sendSelectedRows(action), // 콜백 함수로 전달
            dataTableModalCheck,
            dataTableModalCancel

        );
    };
















    const loadData = async (params, tableLoadData) => {
        try {
            pLoadingOn();
            let response;
            if (tableLoadData.tableType === 'User') {
                const UserSearchformValues = tableLoadData.UserSearchformValues;
                response = await axios.post(`${API_BASE_URL}/admin_member/in/get_table`, UserSearchformValues, {
                    withCredentials: true
                });
            } else if (tableLoadData.tableType === 'UserPopup') {
                response = await axios.post(`${API_BASE_URL}/admin_member/in/select_popup`, tableLoadData, {
                    withCredentials: true
                });
            } else if (tableLoadData.tableType === 'UserGrade') {
                response = await axios.post(`${API_BASE_URL}/admin_grade/in/get_grade_table`, tableLoadData, {
                    withCredentials: true
                });
            } else if (tableLoadData.tableType === 'EventSub') {
                response = await axios.post(`${API_BASE_URL}/admin_document/in/event_attend_list`, tableLoadData, {
                    withCredentials: true
                });
            } else if (tableLoadData.tableType === 'Forum') {
                response = await axios.post(`${API_BASE_URL}/admin_forum/in/get_forum_table`, tableLoadData, {
                    withCredentials: true
                });
            } else if (tableLoadData.tableType === 'EventRegist') {
                response = await axios.post(`${API_BASE_URL}/admin_member/in/popup_member`,tableLoadData , {
                    withCredentials: true
                });
            } else {
                response = await axios.post(`${API_BASE_URL}/admin_document/in/get_table`, tableLoadData, {
                    withCredentials: true
                });
            }

            if (response.data.result === 't') {
                const allData = [];
                // console.log(response.data.data)
                params.api.forEachNode((node) => allData.push(node.data));
                params.api.applyTransaction({ remove: allData });

                params.api.applyTransaction({ add: response.data.data });
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('Data fetching failed:', error);
            pAlert('데이터를 불러오지 못했습니다. 새로고침 해주세요.');
        } finally {
            pLoadingOff();
        }
    };

    const onGridReady = useCallback(async (params) => {
        setGridApi(params.api);
        loadData(params, tableLoadData);
    }, [tableLoadData]);



    // 회원 정보 검색할때 다시 로드
    useEffect(() => {
        if (tableLoadData.tableType === 'User' && gridApi) {
            loadData({ api: gridApi }, tableLoadData);
        }
    }, [tableLoadData, gridApi]);



    useImperativeHandle(ref, () => ({
        refreshTable: async (selectedId) => {
            if (gridApi) {
                await loadData({ api: gridApi }, tableLoadData);
                if (selectedId !== undefined) {
                    setTimeout(() => {
                        const allNodes = gridApi.getRenderedNodes();
                        const node = allNodes.find(node => node.data.idx === selectedId);
                        if (node) {
                            gridApi.ensureIndexVisible(node.rowIndex);
                            fetchDetailData(node.data.idx, node.data.menuIdx);
                        }
                    }, 0);
                }
            }
        },
        selectRowById: (id) => {
            if (gridApi) {
                const allNodes = gridApi.getRenderedNodes();
                const node = allNodes.find(node => node.data.idx === id);
                if (node) {
                    gridApi.ensureIndexVisible(node.rowIndex);
                    fetchDetailData(node.data.idx, node.data.menuIdx);
                }
            }
        },
        clearSelectedRow: () => {
            setSelectedRowId(null);
            if (gridApi) {
                gridApi.refreshCells(); // 셀 새로 고침
            }
        }
    }));



    const handleCellValueChanged = async (event) => {
        const { data, newValue, oldValue } = event;
        const displayOldValue = oldValue ? oldValue : '내용없음';

        pConfirm(
            '비고수정',
            <>
                <div>해당 비고를 수정하시겠습니까?</div>
                <div>{displayOldValue} {'->'} {newValue}</div>
            </>,
            '300px',
            'auto',
            async () => {
                try {
                    pLoadingOn();
                    const response = await axios.post(`${API_BASE_URL}/admin_document/in/event_register_ex_update`, { idx: data.idx, ex: newValue }, {
                        withCredentials: true
                    });
                    if (response.data.result === 't') {
                        if (ref && ref.current && typeof ref.current.refreshTable === 'function') {
                            ref.current.refreshTable();
                        }
                    } else {
                        pAlert(response.data.msg);
                    }
                } catch (error) {
                    console.error('Data sending failed:', error);
                    pAlert('데이터 전송에 실패했습니다. 다시 시도해주세요.');
                } finally {
                    pLoadingOff();
                }
            }
        );
    };









    // 회원 등급은 모달 안에 컨텐츠를 수정해야되서 따로 뺌
    const handleSendModalSelectedRows = async (event) => {
        if (!gridApi) return;
    
        const selectedNodes = gridApi.getSelectedNodes();
        const gradeSelectedData = selectedNodes.map(node => ({
            idx: node.data.idx,
        }));
    
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/admin_grade/in/get_grade_name_list`, {}, {
                withCredentials: true
            });
    
            if (response.data.result === 't') {
                let returnData = null;  // returnData를 저장할 변수

                const handleReturnDataUpdate = (updatedData) => {
                    returnData = updatedData;
                };
    
                // pConfirm을 사용하여 모달을 열고 데이터를 받음
                pConfirm(
                    'User Grade 설정',
                    <DataTableModal
                        tableType={'UserGrade'}
                        gradeSelectedData={gradeSelectedData}
                        data={response.data.data} // 받아온 데이터를 모달로 전달
                        onUpdate={handleReturnDataUpdate} // 모달에서 업데이트된 데이터를 수신하는 콜백
                    />,
                    '800px',
                    '550px',
                    async () => {
                        try {
                            pLoadingOn();
    
                            // returnData에 필요한 데이터가 있는지 확인
                            if (!returnData) {
                                pAlert('데이터가 존재하지 않습니다.');
                                return;
                            }

                            try {
                                pLoadingOn();
                                const response = await axios.post(`${API_BASE_URL}/admin_grade/in/add_grade`, returnData, {
                                    withCredentials: true
                                });
                                if (response.data.result === 't') {
                                    if (ref && typeof ref.current.refreshTable === 'function') {
                                        ref.current.refreshTable();
                                    }
                                    pAlert('추가가 완료되었습니다.');
                                } else {
                                    pAlert(response.data.msg);
                                }
                            } catch (error) {
                                console.error('Data sending failed:', error);
                                pAlert('데이터 전송에 실패했습니다. 다시 시도해주세요.');
                            } finally {
                                pLoadingOff();
                            }
                            
    
                        } catch (error) {
                            console.error('Data sending failed:', error);
                            pAlert('데이터 전송에 실패했습니다. 다시 시도해주세요.');
                        } finally {
                            pLoadingOff();
                        }
                    },
                    '확인',
                    '취소',
                );
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('Data fetching failed:', error);
            pAlert('데이터를 가져오는데 실패했습니다. 다시 시도해주세요.');
        } finally {
            pLoadingOff();
        }
    };
    

















    //엑셀선택
    const handleExcelSelectedRows = async () => {
        if (!gridApi) return;
        // const menuIdx = tableLoadData.menuIdx;

        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/admin_member/in/get_excel_colunm`,
                {
                    // menuIdx: menuIdx
                },
                {
                    withCredentials: true
                }
            );

            if (response.data.result === 't') {
                let returnData = null;  // returnData를 저장할 변수

                const handleReturnDataUpdate = (updatedData) => {
                    returnData = updatedData;
                };

                pConfirm(
                    'EXCEL 설정',
                    <ExcelModal data={response.data.data} onUpdate={handleReturnDataUpdate} />,
                    '600px',
                    'auto',
                    async () => {
                        try {
                            pLoadingOn();

                            // 날짜가 설정되지 않은 경우 경고 메시지 출력
                            // if (!returnData?.startDate || !returnData?.endDate) {
                            //     pAlert('날짜를 선택해 주세요');
                            //     return;
                            // }

                            // chkArr가 빈 배열이면 경고 메시지 출력
                            if (!returnData?.chkArr || returnData.chkArr.length === 0) {
                                pAlert('최소 하나의 항목을 선택해 주세요');
                                return;
                            }

                            // 서버로 데이터 전송
                            // const postResponse = await axios.post(`${API_BASE_URL}/admin_member/in/get_excel`,
                            //     {
                            //         data: returnData
                            //     },
                            //     {
                            //         withCredentials: true
                            //     }
                            // );

                            // if (postResponse.data.result === 't') {
                            //     const fileUrl = postResponse.data.data;
                            //     const link = document.createElement('a');
                            //     link.href = fileUrl;
                            //     document.body.appendChild(link);
                            //     link.click();
                            //     document.body.removeChild(link);
                            // } else {
                            //     pAlert(postResponse.data.msg);
                            // }



                            // 서버로 데이터 전송
                            const postResponse = await axios.post(
                                `${API_BASE_URL}/admin_member/in/get_excel`,
                                {
                                    data: returnData
                                },
                                {
                                    withCredentials: true,
                                    responseType: 'blob'  // 응답 타입을 blob으로 설정
                                }
                            );
                            let fileName = '회원목록.xlsx';

                            if (postResponse.status === 200) { // HTTP 응답 상태 코드가 200인 경우
                                const blob = new Blob([postResponse.data], { type: postResponse.headers['content-type'] }); // Blob 객체 생성
                                const downloadUrl = window.URL.createObjectURL(blob); // Blob URL 생성

                                // 파일 다운로드 링크 생성 및 클릭
                                const link = document.createElement('a');
                                link.href = downloadUrl;
                                link.download = fileName;  // 원하는 파일 이름으로 설정
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);

                                // Blob URL 해제
                                window.URL.revokeObjectURL(downloadUrl);
                            } else {
                                pAlert(postResponse.error);
                                return false;
                            }

                        } catch (error) {
                            console.error('Data sending failed:', error);
                            pAlert('데이터 전송에 실패했습니다. 다시 시도해주세요.');
                        } finally {
                            pLoadingOff();
                        }
                    },
                    '다운로드',
                    '취소',
                );
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('Data sending failed:', error);
            pAlert('데이터 전송에 실패했습니다. 다시 시도해주세요.');
        } finally {
            pLoadingOff();
        }
    };






    return (
        <>
            <ConfirmComponent />
            <AlertComponent />
            <LoadingComponent />
            <div style={containerStyle} className="p_dataTable_wrap">
                <div style={gridStyle} className={`ag-theme-balham ${isFetching ? 'fetching' : ''}`}>
                    <div className="sub_p_dataTable_chk_btn_wrap">
                        {
                            tableLoadData.tableType === 'EventSub' ? (
                                <div className="sub_p_dataTable_chk_btn_box">
                                    <span className="f_bigger f_500">참석회원</span>
                                    <div className="sub_p_dataTable_chk_btn_wrap">
                                        <button data-action="eventCancel" onClick={handleSendSelectedRows}>불참신청</button>
                                        <button data-action="approve" onClick={handleSendSelectedRows}>최종승인</button>
                                        <button data-action="decline" onClick={handleSendSelectedRows}>최종불참</button>
                                    </div>
                                </div>
                            ) : tableLoadData.tableType === 'User' ? (
                                <>
                                <div className="p_dataTable_chk_btn_box">
                                    <button
                                    className="p_dataTable_chk_btn"
                                    onClick={handleExcelSelectedRows}>
                                        EXCEL
                                    </button>
                                    <button
                                    className="p_dataTable_chk_btn" 
                                    onClick={() => window.open('https://www.naekyehs.org/join', '_blank')}>
                                        회원추가
                                    </button>
                                </div>
                                </>
                            ) : tableLoadData.tableType === 'UserPopup' ? (
                                <div className="p_dataTable_chk_btn_box">
                                    <button
                                    className="p_dataTable_chk_btn"
                                    onClick={handleSendSelectedRows}>
                                        선택 회원 추가
                                    </button>
                                </div>
                            ) : tableLoadData.tableType === 'EventRegist' ? (
                                <div className="p_dataTable_chk_btn_box">
                                    <button
                                    className="p_dataTable_chk_btn"
                                    onClick={handleSendSelectedRows}>
                                        선택 회원 참석
                                    </button>
                                </div>
                            ) : tableLoadData.tableType === 'EventCancel' ? (
                                <div className="p_dataTable_chk_btn_box">
                                    <button
                                    className="p_dataTable_chk_btn"
                                    onClick={handleSendSelectedRows}>
                                        선택 회원 불참
                                    </button>
                                </div>
                            ) : tableLoadData.tableType === 'UserGrade' ? (
                                <div className="p_dataTable_chk_btn_box">
                                    <button
                                    className="p_dataTable_chk_btn"
                                    onClick={handleSendModalSelectedRows}>
                                        선택 회원 변경
                                    </button>
                                </div>
                            ) : tableLoadData.tableType === 'Forum' ? (
                                <div className="p_dataTable_chk_btn_box">
                                    <button
                                    className="p_dataTable_chk_btn"
                                    onClick={handleSendSelectedRows}>
                                        선택 삭제
                                    </button>
                                </div>
                            ) : (
                                <div className="p_dataTable_chk_btn_box">
                                    <button
                                    className="p_dataTable_chk_btn"
                                    onClick={handleSendSelectedRows}>
                                        선택 비공개
                                    </button>
                                </div>
                            )
                        }
                    </div>
                    

                    <AgGridReact
                        animateRows={false}
                        rowData={rowData}
                        columnDefs={modifiedColumnDefs}
                        defaultColDef={defaultColDef}
                        rowSelection={'multiple'}
                        localeText={localeText}
                        pagination
                        paginationPageSize={100}
                        onGridReady={onGridReady}
                        onRowClicked={handleRowClicked}
                        suppressMovableColumns
                        rowMultiSelectWithClick={true}
                        suppressRowClickSelection={true}
                        onCellValueChanged={handleCellValueChanged}
                    />
                </div>
            </div>
        </>
    );
});

export default DataTable;