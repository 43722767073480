import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet, useLocation  } from 'react-router-dom';
import { User, Event, EventList, Login, Organization, Notice, Periodical, HallOfFame, MemberNews, PromotionBoard, FreeBoard, MainSlider, QuestionBoard, MembershipRecruitment, UserGrade, Forum, Intro, Popup     , EventBoard, Yehstudy, YehsGroup } from "./pages";
import Header from './Header';
import Block from './Block';
import RequireAuth from './RequireAuth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';





import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';




function Layout({ openLoginModal }) {
    const location = useLocation();
    const isLoginPage = location.pathname === '/login' || location.pathname === '/';

    return (
        <>
            {!isLoginPage && <Header />}
            {!isLoginPage && <RequireAuth />}
            <Outlet />
        </>
    );
}
function AppContent() {
    return (
        <>
            <div className="App">
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/*" element={<Block />} />
                    <Route element={<Layout />}>
                        <Route path="/" element={<><Login /></>} />
                        <Route path="/event" element={<><Event cateIdx={9999} type={'ev'} /></>} />
                        <Route path="/eventList" element={<><EventList cateIdx={7} type={'do'} /></>} />

                        {/* 회원관리 */}
                        <Route path="/user" element={<><User /></>} />
                        <Route path="/userGrade" element={<><UserGrade /></>} />
                        <Route path="/Forum" element={<><Forum /></>} />

                        {/* 소개 */}
                        <Route path="/organization" element={<><Organization /></>} />
                        <Route path="/intro" element={<><Intro cateIdx={11} type={'do'} /></>} />

                        {/* 소식 */}
                        <Route path="/notice" element={<><Notice cateIdx={1} type={'do'} /></>} />
                        <Route path="/periodical" element={<><Periodical cateIdx={2} type={'do'} /></>} />
                        <Route path="/hallOfFame" element={<><HallOfFame cateIdx={5} type={'do'} /></>} />
                        <Route path="/memberNews" element={<><MemberNews cateIdx={6} type={'do'} /></>} />

                        {/* 커뮤니티 */}
                        <Route path="/promotionBoard" element={<><PromotionBoard cateIdx={3} type={'do'} /></>} />
                        <Route path="/freeBoard" element={<><FreeBoard cateIdx={9} type={'do'} /></>} />
                        <Route path="/questionBoard" element={<><QuestionBoard cateIdx={8} type={'do'} /></>} />
                        <Route path="/membershipRecruitment" element={<><MembershipRecruitment cateIdx={10} type={'do'} /></>} />
                        
                        <Route path="/eventBoard" element={<><EventBoard cateIdx={12} type={'do'} /></>} />
                        <Route path="/yehstudy" element={<><Yehstudy cateIdx={13} type={'do'} /></>} />
                        <Route path="/yehsGroup" element={<><YehsGroup cateIdx={14} type={'do'} /></>} />

                        {/* 관리자 */}
                        <Route path="/mainSlider" element={<><MainSlider /></>} />
                        <Route path="/popup" element={<><Popup cateIdx={9998} type={'popup'} /></>} />

                    </Route>
                </Routes>
            </div>
        </>
    );
}

function App() {
    return (
        <>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <AppContent />
                    <ToastContainer
                        position="bottom-center" // 알람 위치 지정
                        autoClose={3000} // 자동 off 시간
                        hideProgressBar={true} // 진행시간바 숨김
                        closeOnClick // 클릭으로 알람 닫기
                        rtl={false} // 알림 좌우 반전
                        pauseOnFocusLoss // 화면을 벗어나면 알람 정지
                        draggable // 드래그 가능
                        pauseOnHover // 마우스를 올리면 알람 정지
                        theme="dark"
                    />
                </Router>
            </PersistGate>
        </Provider>
        </>
    );
}

export default App;