// Forum.jsx
import styles from './Forum.module.scss';
import { useState, useRef } from 'react';
import { DataTable } from "../../components";
import common from '../../common';
import { FaMousePointer } from 'react-icons/fa';
// import ForumDetail from './ForumDetail';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Forum() {
    const { useAlert,useModal, useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent } = useConfirm();
    const { pModal, ModalComponent } = useModal(); // 훅 사용
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
    const dataTableRef = useRef(null); // 등록 시 데이터테이블 리로드를 위한 함수
    

    const [columnDefs] = useState([
        { field: 'tableType', headerName: 'tableType' },
        { field: "idx", headerName: "번호" },
        { field: "time", headerName: "참여포럼" },
        { field: "period", headerName: "기간" },
        { field: "year", headerName: "입회연도" },
        { field: "month", headerName: "입회월" },
        { field: "createTime", headerName: "생성시간", minWidth: 150, sort: 'desc', sortIndex: 0 },
    ]);

    const [tableLoadData, setTableLoadData] = useState({
        tableType: 'Forum',
    });

    // ====================== 디테일 정보 뿌리기 ======================

    // info_cont 데이터
    const [selectedData, setSelectedData] = useState(null);
    const [type, setType] = useState('');

    const handleRowSelected = (data) => {
        setType('upd');
        setSelectedData({
            ...data,
            resetStatus: false
        });
    };

    const handleAddForumClicked = () => {
        setType('ins');
        setSelectedData({
            resetStatus: false
        });
    };

    const handleBackClicked = () => {
        setSelectedData(null);
        setType('');
    };

    
    // ====================== 디테일 정보 뿌리기 ======================

    function memberListData() {
        return (
            <>
                <table className="modal_sub_table">
                    <thead>
                        <tr>
                            <th>이름</th>
                            <th>생년월일</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedData.memberList.map((event, index) => (
                            <tr key={index}>
                                <td className="modal_sub_table_text_center">{event.name}</td>
                                <td className="modal_sub_table_text_center">{event.birth}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        );
    }

    const [postData, setPostData] = useState({
        time: '',
        period: '',
        year: '',
        month: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPostData(prevData => ({
            ...prevData,
            [name]: parseInt(value, 10)
        }));
    };

    const handleSubmit = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/admin_forum/in/forum_insert`, postData, {
                withCredentials: true
            });
            if (response.data.result === 't') {
                pAlert('추가가 완료되었습니다.');
                await dataTableRef.current.refreshTable();
                setSelectedData(prevFormData => {
                    const updatedData = {
                        ...prevFormData,
                        resetStatus: true
                    };
                    return updatedData;

                });
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('Data fetching failed:', error);
            pAlert('데이터 전송에 실패했습니다. 다시 시도해주세요.');
        } finally {
            pLoadingOff();
        }
    };

    const handleConfirmAndSubmit = () => {
        pConfirm('제목', '포럼을 추가하시겠습니까?', '300px', 'auto', handleSubmit);
    };

    const handleRefresh = () => {
        window.location.reload();
    };
    
    return (
        <>
            <ModalComponent />
            <AlertComponent />
            <ConfirmComponent />
            <LoadingComponent />
            <div className="components_wrap">
                <p className='page_title'>포럼 목록</p>
                
                <div className={styles.cm_table_wrap}>
                    <div className={styles.p_table_wrap}>
                        <div className={styles.p_table_wrap}>
                            <DataTable
                                columnDefs={columnDefs}
                                ref={dataTableRef}  // ref 전달
                                onRowSelected={handleRowSelected}
                                tableLoadData={tableLoadData}  // DataTable에 전달할 값
                            />
                        </div>
                        <div className={`${styles.p_detail_box} ${styles.scroll_change}`}>
                            {selectedData && selectedData.resetStatus === false ? (
                                <div className="p_detail_wrap">
                                    <div className="p_detail_title">상세 정보</div>
                                        <div className="p_detail_info_wrap">
                                            <div className="p_detail_info_box">
                                                {type === 'upd' ? (
                                                    <>
                                                    <div className="p_detail_info">
                                                        <span className="p_info_title">참여포럼</span>
                                                        <span className="p_info_cont">{selectedData.time}회</span>
                                                    </div>
                
                                                    <div className="p_detail_info">
                                                        <span className="p_info_title">기간</span>
                                                        <span className="p_info_cont">{selectedData.period}</span>
                                                    </div>
                
                                                    <div className="p_detail_info">
                                                        <span className="p_info_title">입회연도</span>
                                                        <span className="p_info_cont">{selectedData.year}</span>
                                                    </div>
                
                                                    <div className="p_detail_info">
                                                        <span className="p_info_title">입회월</span>
                                                        <span className="p_info_cont">{selectedData.month}</span>
                                                    </div>
                
                                                    <div className="p_detail_info">
                                                        <span className="p_info_title">생성시간</span>
                                                        <span className="p_info_cont">{selectedData.createTime}</span>
                                                    </div>
                
                                                    <div className="p_detail_info">
                                                        <span className="p_info_title">참여인원</span>
                                                        <button onClick={() => pModal('참여인원', memberListData, '600px', '600px')} className={`p_info_cont ${styles.user_detail_btn}`}>참여인원</button>
                                                    </div>
                                                    </>
                                                ) : type === 'ins' ? (
                                                    <>
                                                    <div className="p_detail_info">
                                                        <span className="p_info_title">참여포럼</span>
                                                        <input 
                                                            type="number" 
                                                            className="p_info_cont" 
                                                            name="time" 
                                                            value={postData.time} 
                                                            onChange={handleInputChange} 
                                                        />
                                                    </div>
                
                                                    <div className="p_detail_info">
                                                        <span className="p_info_title">기간</span>
                                                        <input 
                                                            type="number" 
                                                            className="p_info_cont" 
                                                            name="period" 
                                                            value={postData.period} 
                                                            onChange={handleInputChange} 
                                                        />
                                                    </div>
                
                                                    <div className="p_detail_info">
                                                        <span className="p_info_title">입회연도</span>
                                                        <input 
                                                            type="number" 
                                                            className="p_info_cont" 
                                                            name="year" 
                                                            value={postData.year} 
                                                            onChange={handleInputChange} 
                                                        />
                                                    </div>
                
                                                    <div className="p_detail_info">
                                                        <span className="p_info_title">입회월</span>
                                                        <input 
                                                            type="number" 
                                                            className="p_info_cont" 
                                                            name="month" 
                                                            value={postData.month} 
                                                            onChange={handleInputChange} 
                                                        />
                                                    </div>
                                                    </>
                                                ) : null}
                                            </div>
                                            <div className="p_detail_btn_box">
                                                {type === 'upd' ? (
                                                    <button className='p_detail_btn p_detail_etc_btn' onClick={handleBackClicked}>뒤로 가기</button>
                                                ) : type === 'ins' ? (
                                                    <>
                                                        <button 
                                                        className='p_detail_btn p_detail_regist_btn'
                                                        onClick={handleConfirmAndSubmit}>추가</button>
                                                        <button className='p_detail_btn p_detail_etc_btn' onClick={handleBackClicked}>뒤로 가기</button>
                                                        <button className='p_detail_btn p_detail_clear_btn' onClick={handleRefresh}>새로고침</button>
                                                    </>
                                                ) : null}
                                            </div>
                                        </div>
                                </div>
                            ) : (
                                <div className={styles.default_wrap}>
                                    <FaMousePointer className={`${styles.xi_mouse_pointer} ${styles.mouse_icon}`} />
                                    <span className={styles.exp_default_txt}>데이터를 선택해주세요.</span>
                                    <button className={styles.add_btn} onClick={handleAddForumClicked}>포럼 추가</button>
                                </div>
                            )}
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    );
}

export default Forum;
