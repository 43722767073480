// User.jsx
import styles from './User.module.scss';
import { useState, useRef, useEffect } from 'react';
import { DataTable } from "../../components";
import common from '../../common';
import { FaMousePointer } from 'react-icons/fa';
import axios from 'axios';


import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/locale';
import { FaCalendarDay } from 'react-icons/fa';


import { useSelector } from 'react-redux';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function User() {


    const admin_grade_idx = useSelector((state) => state.user.loginInfo?.adminGradeIdx || null);


    const { pToast, useAlert, useModal, useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pModal, ModalComponent } = useModal(); // 훅 사용
    const { pConfirm, ConfirmComponent } = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
    const dataTableRef = useRef(null); // 등록 시 데이터테이블 리로드를 위한 함수

    const [columnDefs] = useState([
        { field: 'tableType', headerName: 'tableType' },
        { field: "idx", headerName: "번호" },
        { field: "name", headerName: "이름", maxWidth: 120 },
        { field: "code", headerName: "회원번호", minWidth: 90 },
        { field: "phone", headerName: "전화번호", minWidth: 130 },
        { field: "email", headerName: "이메일", minWidth: 200 },
        { field: "birth", headerName: "생년월일" },
        { field: "adminGradeIDX", headerName: "관리자 등급", hide: true },
        { field: "adminGradeName", headerName: "관리자 등급" },
        { field: "statusIDX", headerName: "상태IDX", hide: true },
        { field: "statusName", headerName: "상태" },
        { field: "gradeIDX", headerName: "등급", hide: true },
        { field: "gradeName", headerName: "등급" },
        { field: "joinMonth", headerName: "입회일" },
        // { field: "createTime", headerName: "생성시간", minWidth: 150, sort: 'desc', sortIndex: 0 },
        { field: "createTime", headerName: "생성시간", minWidth: 150,},
    ]);


    const [UserSearchformValues, setUserSearchformValues] = useState({
        k_hash_tag: '',
        k_university: '',
        k_major: '',
        k_job: '',
        k_career: '',
        k_forum: '',
        k_code: '',
        k_grade_pack: []
    });

    const [tableLoadData, setTableLoadData] = useState({
        tableType: 'User',
        UserSearchformValues: UserSearchformValues
    });

    // useEffect(() => {
    //     setTableLoadData({
    //         tableType: 'User',
    //         formValues: formValues
    //     });
    // }, [formValues]);



    

    // ====================== 검색 정보 뿌리기 ======================

    const [userGradeContent, setUserGradeContent] = useState([]);
    const fetchUserGradeContent = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/admin_grade/in/get_grade_name_list`, {}, {
                withCredentials: true
            });
            if (response.data.result === 't') {
                setUserGradeContent(response.data.data);
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('Data fetching failed:', error);
            pAlert('데이터 전송에 실패했습니다. 다시 시도해주세요.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        fetchUserGradeContent();
    }, []);


    const handleSearchInputChange = (e) => {
        const { name, value } = e.target;
        setUserSearchformValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (idx) => {
        setUserSearchformValues((prevValues) => {
            const new_k_grade_pack = prevValues.k_grade_pack.includes(idx)
                ? prevValues.k_grade_pack.filter(item => item !== idx)
                : [...prevValues.k_grade_pack, idx];
            return {
                ...prevValues,
                k_grade_pack: new_k_grade_pack,
            };
        });
    };

    const handleSearchSubmit = () => {
        setTableLoadData({
            tableType: 'User',
            UserSearchformValues: UserSearchformValues
        });
    };

































    // ===========================================================


    // ====================== 디테일 정보 뿌리기 ======================

    const [formData, setFormData] = useState({
        thumbnail: '',
        thumbnailPreview: '',
        email: '',
        name: '',
        code: '',
        phone: '',
        birth: '',
        address: '',
        addressDetail: '',
        gradeName: '',
        statusName: '',
        createTime: '',
        job: '',
        adminGradeIDX: null,
        forumIDX: 0,
        future: '',
        keyword: '',
        careerPack: [],
        universityPack: [],
        forumList: [],
        statusIDX: '',
        eventHistory: [],
    });

    const [selectedData, setSelectedData] = useState(null); // 데이터테이블 선택 시 디테일 

    const handleRowSelected = (data) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            member_idx: data.idx,
            thumbnail: data.thumbnail || '',
            thumbnailPreview: data.thumbnailPreview || '',
            email: data.email || '',
            name: data.name || '',
            code: data.code || '',
            phone: data.phone || '',
            birth: data.birth || '',
            address: data.address || '', 
            addressDetail: data.addressDetail || '',  
            adminGradeIDX: data.adminGradeIDX || 0,
            statusName: data.statusName || '',
            gradeName: data.gradeName || '',
            createTime: data.createTime || '',
            future: data.future || '',
            keyword: data.keyword || '',
            job: data.job || '',
            universityPack: data.university && data.university.length > 0
                ? data.university.map((item, index) => ({
                    idx: item.idx || (index + 1),
                    university: item.university,
                    major: item.major,
                    degree: item.degree,
                    degreeAttend: item.degreeAttend,
                    status: 'U'
                }))
                : [{ idx: 1, university: '', major: '', status: 'I' }],
            careerPack: data.career && data.career.length > 0
                ? data.career.map((item, index) => ({
                    idx: item.idx || (index + 1),
                    career: item.career || '',
                    status: 'U'
                }))
                : [{ idx: 1, career: '', status: 'I' }],

            statusIDX: data.statusIDX || '',
            forumIDX: data.forumIDX || 0,
            forumList: data.forumList || [],
            eventHistory: data.eventHistory || [],
            organization: data.organization || [],
        }));

        setSelectedData(prevFormData => ({
            ...prevFormData,
            member_idx: data.idx,
            thumbnail: data.thumbnail || '',
            thumbnailPreview: data.thumbnailPreview || '',
            email: data.email || '',
            name: data.name || '',
            code: data.code || '',
            phone: data.phone || '',
            birth: data.birth || '', 
            address: data.address || '', 
            addressDetail: data.addressDetail || '', 
            adminGradeIDX: data.adminGradeIDX || 0,
            statusName: data.statusName || '',
            gradeName: data.gradeName || '',
            createTime: data.createTime || '',
            future: data.future || '',
            keyword: data.keyword || '',
            job: data.job || '',
            universityPack: data.university && data.university.length > 0
                ? data.university.map((item, index) => ({
                    idx: item.idx || (index + 1),
                    university: item.university,
                    major: item.major,
                    degree: item.degree,
                    degreeAttend: item.degreeAttend,
                    status: 'U'
                }))
                : [{ idx: 1, university: '', major: '', status: 'I' }],
            careerPack: data.career && data.career.length > 0
                ? data.career.map((item, index) => ({
                    idx: item.idx || (index + 1),
                    career: item.career || '',
                    status: 'U'
                }))
                : [{ idx: 1, career: '', status: 'I' }],

            statusIDX: data.statusIDX || '',
            forumIDX: data.forumIDX || 0,
            forumList: data.forumList || [],
            eventHistory: data.eventHistory || [],
            organization: data.organization || [],
            resetStatus: false
        }));
    };



    // 휴대폰
    const formatPhoneNumber = (input) => {
        input = input.replace(/\D/g, ''); // 숫자만 남기기

        if (input.length > 3 && input.length <= 7) {
            return `${input.slice(0, 3)}-${input.slice(3)}`;
        } else if (input.length > 7) {
            return `${input.slice(0, 3)}-${input.slice(3, 7)}-${input.slice(7, 11)}`;
        }
        return input;
    };

    const handlePhoneNumberChange = (e) => {
        const formattedInput = formatPhoneNumber(e.target.value);
        setFormData(prevFormData => ({
            ...prevFormData,
            phone: formattedInput,
        }));
    };


    // useEffect(() => {
    //     console.log(data);
    //     
    // }, [data]);

    // 기본 인풋 변경
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    // 기본 인풋 숫자로 변경
    const handleNumberChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: parseInt(value, 10), // 문자열을 숫자로 변환
        }));
    };

    // 날짜 형식 변환
    const formattedDate = (data) => {
        return data.toISOString().split('T')[0]; // yyyy-MM-dd 형식으로 변환
    }

    // 생년월일 날짜 수정
    const handleDateChange = (date) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            birth: formattedDate(date)
        }));
    };

    const handleClick = () => {
        new window.daum.Postcode({
            oncomplete: (data) => {
                setFormData({
                    ...formData,
                    address: data.address,
                });
            },
        }).open();
    };

    // ==================== 경력 ====================

    
    // 경력 인풋 변경 시
    const handleCareerChange = (idx, field, value) => {
        setFormData(prevFormData => {
            const updatedCareers = prevFormData.careerPack.map((box, index) =>
                box.idx === idx ? { ...box, [field]: value, idx: box.idx || (index + 1), status: box.status === 'I' ? 'I' : 'U' } : box
            );
            return { ...prevFormData, careerPack: updatedCareers };
        });
    };

    // 경력 + 버튼
    const handleAddCareerBox = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            careerPack: [
                ...prevFormData.careerPack,
                { idx: prevFormData.careerPack.length + 1, career: '', status: 'I' }
            ]
        }));
    };

    // 경력 - 버튼
    const handleRemoveCareerBox = (idx) => {
        setFormData(prevFormData => {
            const selectedItems = selectedData ? selectedData.careerPack : [];
            const selectedItem = selectedItems.find(item => item.idx === idx);

            if (!selectedItem) {
                return {
                    ...prevFormData,
                    careerPack: prevFormData.careerPack.map(item =>
                        item.idx === idx ? { ...item, status: 'F' } : item
                    )
                };
            }

            return {
                ...prevFormData,
                careerPack: prevFormData.careerPack.map(item =>
                    item.idx === idx ? { ...item, status: 'D' } : item
                )
            };
        });
    };
    

    // ==================== 학력 ====================

    // 학력 인풋 변경 시
    const handleSchoolChange = (idx, field, value) => {
        setFormData(prevFormData => {
            const updatedSchools = prevFormData.universityPack.map((box, index) =>
                box.idx === idx ? { ...box, [field]: value, idx: box.idx || (index + 1), status: box.status === 'I' ? 'I' : 'U' } : box
            );
            return { ...prevFormData, universityPack: updatedSchools };
        });
    };

    // 학력 + 버튼
    const handleAddUniversityBox = (field) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [field]: [
                ...prevFormData[field],
                { idx: (prevFormData[field].length + 1), university: '', major: '', degree: '', degreeAttend: '', status: 'I' }
            ]
        }));
    };
    
    // 학력 - 버튼
    const handleRemoveUniversityBox = (field, idx) => {
        setFormData(prevFormData => {
            const selectedItems = selectedData[field] || [];
            const selectedItem = selectedItems.find(item => item.idx === idx);
            
            // selectedData에 없는 항목은 무시
            if (!selectedItem) {
                return {
                    ...prevFormData,
                    [field]: prevFormData[field].map(item =>
                        item.idx === idx ? { ...item, status: 'F' } : item
                    )
                };
            }
    
            return {
                ...prevFormData,
                [field]: prevFormData[field].map(item =>
                    item.idx === idx ? { ...item, status: 'D' } : item
                )
            };
        });
    };
    
    
















    

    // 파일 변경 시
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    thumbnail: file,
                    thumbnailPreview: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    
    // 
    const handleThumbnailClick = () => {
        document.getElementById('thumbnailUpload').click();
    };


    // 수정 버튼
    const handleSubmit = async () => {
        let newFormData = {};
        let newUpdateFormData = new FormData();
    
        // 변경된 데이터만 추출하여 newFormData에 저장
        Object.keys(formData).forEach(key => {
            if (Array.isArray(formData[key])) {
                // 배열인 경우 각 항목을 비교하여 변경된 항목만 newFormData에 추가
                const changedItems = formData[key].filter((item, index) => {
                    const selectedItem = (selectedData[key] || []).find(selectedItem => selectedItem.idx === item.idx);
                    return (JSON.stringify(item) !== JSON.stringify(selectedItem) || item.status === 'D') && item.status !== 'F';
                });
                if (changedItems.length > 0) {
                    newFormData[key] = changedItems;
                }
            } else if (formData[key] !== selectedData[key]) {
                newFormData[key] = formData[key];
            }
        });
    
        // console.log('newFormData:', newFormData);
        const targetIdx = formData.member_idx;
        newUpdateFormData.append('targetIdx', targetIdx);
    
        // newFormData의 데이터를 newUpdateFormData에 추가
        Object.entries(newFormData).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                newUpdateFormData.append(key, JSON.stringify(value));
            } else {
                newUpdateFormData.append(key, value);
            }
        });
    
        // console.log('------------------------------------')
    
        // for (let pair of newUpdateFormData.entries()) {
        //     console.log('newUpdateFormData 여기서 확인',`${pair[0]}: ${pair[1]}`);
        // }
    
        if (Object.keys(newFormData).length === 0) {
            pToast('변경된 내용이 없습니다.');
            return false;
        }
    
        pConfirm(
            '수정',
            <>
                <div>내용을 수정 하시겠습니까?</div>
            </>,
            '400px',
            'auto',
            async () => {
                try {
                    pLoadingOn();
                    const response = await axios.post(`${API_BASE_URL}/admin_member/in/update_member`, newUpdateFormData, {
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    if (response.data.result === 't') {
                        pAlert('수정이 완료되었습니다.');
                        await dataTableRef.current.refreshTable();
                        setSelectedData(prevFormData => {
                            const updatedData = {
                                ...prevFormData,
                                resetStatus: true
                            };
                            return updatedData;

                        });
                    } else {
                        pAlert(response.data.msg);
                    }
                } catch (error) {
                    console.error('Data fetching failed:', error);
                    pAlert('데이터 전송에 실패했습니다. 다시 시도해주세요.');
                } finally {
                    pLoadingOff();
                }
            }
        );
    };
    
    
    

    // 행사 참여 이력
    function eventHistoryData() {
        
        return (
            <>
                <table className="modal_sub_table">
                    <thead>
                        <tr>
                            <th>구분</th>
                            <th>참여행사 목록</th>
                            <th>행사시작일</th>
                        </tr>
                    </thead>
                    <tbody>
                        {formData.eventHistory.map((event, index) => (
                            <tr key={index}>
                                <td className="modal_sub_table_categoryName">{event.categoryName}</td>
                                <td className="modal_sub_table_title">
                                    {event.statusIDX === 520301 ? <b className='modal_sub_table_higilight'>[삭제된 행사] </b> : null}
                                    {event.title}
                                </td>
                                <td className="modal_sub_table_startDate">{event.startDate || ''}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        );
    }












    // 로그 데이터
    const [logData, setLogData] = useState([]); // 로그 데이터를 상태로 관리
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
		if (showModal) {
			pModal('', <LogData logData={logData} />, '1200px', 'auto');
			setShowModal(false);  // Reset the flag
		}
	}, [logData, showModal]);

	const handleLog = async () => {
        if (admin_grade_idx !== 1) {
            pAlert('TOPADMIN 등급만 확인 가능합니다.');
            return null; // 이 부분에서 아무것도 렌더링하지 않도록 null을 반환
        }
		await fetchLogData();
		setShowModal(true);
	};

	const fetchLogData = async () => {
		try {
			pLoadingOn();
			const response = await axios.post(`${API_BASE_URL}/admin_member/in/get_member_log`, {
				target_idx: formData.member_idx,
			}, {
				withCredentials: true
			});
			if (response.data.result === 't') {
				setLogData(Array.isArray(response.data.data) ? response.data.data : []); // logData가 항상 배열이 되도록 설정
			} else {
				pAlert(response.data.msg);
			}
		} catch (error) {
			console.error('Error fetching log data:', error);
			pAlert('로그 데이터를 가져오는데 실패했습니다. 다시 시도해주세요.');
		} finally {
			pLoadingOff();
		}
	};


	function LogData() {
        return (
            <>
                <table className='modal_sub_table'>
                    <thead>
                        <tr>
                            <th className='modal_1_sub'>제목</th>
                            <th className='modal_2_sub'>내용</th>
                            <th className='modal_3_sub'>처리인</th>
                            <th className='modal_4_sub'>처리일</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logData && logData.length > 0 ? (
                            logData.map((data, index) => (
                                <tr key={index}>
                                    <td>{data.status}</td>
                                    <td>{data.ex}</td>
                                    <td>{data.name}</td>
                                    <td>{data.createTime || 'null'}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4">데이터가 없습니다.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </>
        );
		
	}






    return (
        <>
            <ModalComponent />
            <AlertComponent />
            <ConfirmComponent />
            <LoadingComponent />
            <div className="components_wrap">
                <p className='page_title'>회원 목록</p>
                <div className={styles.cm_table_wrap}>
                    <div className={styles.user_table_wrap}>
                        <div className={styles.user_table_search_container}>
                            <div className={styles.user_search_container}>
                                <div className={styles.user_search_flex_warp}>
                                    <div className={styles.user_search_flex_box}>
                                        <div className={styles.user_search_box}>
                                            <span className={styles.user_search_box_title}>회원번호</span>
                                            <input
                                                type="text"
                                                name="k_code"
                                                placeholder='회원번호를 입력해주세요.'
                                                value={UserSearchformValues.k_code}
                                                className={styles.user_search_input}
                                                onChange={handleSearchInputChange}
                                            />
                                        </div>
                                        <div className={styles.user_search_box}>
                                            <span className={styles.user_search_box_title}>참여포럼</span>
                                            <input
                                                type="text"
                                                name="k_forum"
                                                placeholder='참여포럼을 입력해주세요.'
                                                value={UserSearchformValues.k_forum}
                                                className={styles.user_search_input}
                                                onChange={handleSearchInputChange}
                                            />
                                        </div>
                                        <div className={styles.user_search_box}>
                                            <span className={styles.user_search_box_title}>키워드</span>
                                            <input
                                                type="text"
                                                name="k_hash_tag"
                                                placeholder='키워드를 입력해주세요.'
                                                value={UserSearchformValues.k_hash_tag}
                                                className={styles.user_search_input}
                                                onChange={handleSearchInputChange}
                                            />
                                        </div>
                                        <div className={styles.user_search_box}>
                                            <span className={styles.user_search_box_title}>학교</span>
                                            <input
                                                type="text"
                                                name="k_university"
                                                placeholder='학교를 입력해주세요.'
                                                value={UserSearchformValues.k_university}
                                                className={styles.user_search_input}
                                                onChange={handleSearchInputChange}
                                            />
                                        </div>
                                        <div className={styles.user_search_box}>
                                            <span className={styles.user_search_box_title}>학과</span>
                                            <input
                                                type="text"
                                                name="k_major"
                                                placeholder='학과를 입력해주세요.'
                                                value={UserSearchformValues.k_major}
                                                className={styles.user_search_input}
                                                onChange={handleSearchInputChange}
                                            />
                                        </div>
                                        <div className={styles.user_search_box}>
                                            <span className={styles.user_search_box_title}>직장</span>
                                            <input
                                                type="text"
                                                name="k_job"
                                                placeholder='직장을 입력해주세요.'
                                                value={UserSearchformValues.k_job}
                                                className={styles.user_search_input}
                                                onChange={handleSearchInputChange}
                                            />
                                        </div>
                                        <div className={styles.user_search_box}>
                                            <span className={styles.user_search_box_title}>경력</span>
                                            <input
                                                type="text"
                                                name="k_career"
                                                placeholder='경력을 입력해주세요.'
                                                value={UserSearchformValues.k_career}
                                                className={styles.user_search_input}
                                                onChange={handleSearchInputChange}
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.user_search_flex_box}>
                                        <div className={styles.user_search_box}>
                                            <span className={styles.user_search_box_title}>등급</span>
                                            <div className={styles.p_chkbox_box}>
                                                {userGradeContent.map((item) => (
                                                    <label className="p_chkbox" key={item.idx}>
                                                        <input
                                                            type="checkbox"
                                                            name={`checkbox${item.idx}`}
                                                            checked={UserSearchformValues.k_grade_pack.includes(item.idx)}
                                                            onChange={() => handleCheckboxChange(item.idx)}
                                                        />
                                                        <span>{item.name}</span>
                                                    </label>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.search_btn_box}>
                                    <button className={styles.search_btn} onClick={handleSearchSubmit}>검색</button>
                                </div>
                            </div>
                            <div className={styles.user_table_box}>
                                <DataTable
                                    columnDefs={columnDefs}
                                    ref={dataTableRef}  // ref 전달
                                    onRowSelected={handleRowSelected}
                                    tableLoadData={tableLoadData}  // DataTable에 전달할 값
                                />
                            </div>
                        </div>

                        <div className={`${styles.user_detail_box} ${styles.scroll_change}`}>
                            <div className={styles.detail_wrap}>
                                {selectedData && selectedData.resetStatus === false ? (
                                    <>
                                    <div className={styles.detail_title}>상세 정보</div>
                                        <div className={styles.detail_info_box}>
                                            <div
                                                className={styles.member_thumbnail_img}
                                                style={{ backgroundImage: `url(${formData.thumbnailPreview || formData.thumbnail || 'default_image_url'})` }}
                                                onClick={handleThumbnailClick}
                                            ></div>
                                            <input
                                                id="thumbnailUpload"
                                                type="file"
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange}
                                                accept="image/*"
                                            />
                                            <div className={styles.detail_info}>
                                                <span className={styles.info_title}>이름</span>
                                                <input type="text" placeholder='이름' name="name" value={formData.name} className={`${styles.info_cont} ${styles.info_name}`} onChange={handleInputChange} />
                                            </div>

                                            <div className={styles.detail_info}>
                                                <span className={styles.info_title}>회원번호</span>
                                                <input type="text" placeholder='회원번호' name="code" value={formData.code} className={`${styles.info_cont} ${styles.info_name}`} onChange={handleInputChange} />
                                            </div>
                    
                                            <div className={styles.detail_info}>
                                                <span className={styles.info_title}>이메일</span>
                                                <input type="text" placeholder='이메일' name="email" value={formData.email} className={`${styles.info_cont} ${styles.info_email}`} onChange={handleInputChange} />
                                            </div>
                    
                                            <div className={styles.detail_info}>
                                                <span className={styles.info_title}>관리자등급명</span>
                                                <select className={`${styles.info_select} ${styles.info_agree}`} name="adminGradeIDX" value={formData.adminGradeIDX ?? null} onChange={handleNumberChange}>
                                                    <option value="0">권한없음</option>
                                                    <option value="1">TOPADMIN</option>
                                                    <option value="2">ADMIN</option>
                                                </select>
                                            </div>
                    
                                            <div className={styles.detail_info}>
                                                <span className={styles.info_title}>전화번호</span>
                                                <input type="text" placeholder='전화번호' name="phone" value={formData.phone} className={`${styles.info_cont} ${styles.info_phone}`} onChange={handlePhoneNumberChange} />
                                            </div>
                                            <div className={styles.detail_info}>
                                                <span className={styles.info_title}>생년월일</span>
                                                <div className={`${styles.info_cont} ${styles.no_padding}`}>
                                                    <div className="datePickerWrapper">
                                                        <DatePicker
                                                            locale={ko}
                                                            selected={formData.birth ? new Date(formData.birth) : null}
                                                            onChange={handleDateChange}
                                                            name="birth"
                                                            className='pDatePicker padding_3'
                                                            dateFormat="yyyy-MM-dd"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                        />
                                                        <FaCalendarDay className="calendarIcon" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={styles.detail_info}>
                                                <span className={styles.info_title}>주소
                                                    <button
                                                        className={styles.address_btn}
                                                        onClick={handleClick}
                                                        >
                                                        검색
                                                    </button>
                                                </span>
                                                <input
                                                    type="text"
                                                    className={`${styles.info_cont_2}`}
                                                    placeholder="주소"
                                                    name="address"
                                                    value={formData.address}
                                                    onChange={handleInputChange}
                                                />
                                                <input
                                                    type="text"
                                                    className={`${styles.info_cont_2}`}
                                                    placeholder="상세주소"
                                                    name="addressDetail"
                                                    value={formData.addressDetail}
                                                    onChange={handleInputChange}
                                                />
                                            </div>


                                            <div className={styles.detail_info}>
                                                <span className={styles.info_title}>상태</span>
                                                <select className={`${styles.info_select} ${styles.info_agree}`} name="statusIDX" value={formData.statusIDX ?? ''} onChange={handleNumberChange}>
                                                    <option value="601101">정상</option>
                                                    <option value="601203">비밀번호재설정필요</option>
                                                    <option value="601204">차단</option>
                                                </select>
                                            </div>
                    
                                            <div className={styles.detail_info}>
                                                <span className={styles.info_title}>참여포럼 및 입회시기</span>
                                                <select 
                                                    className={styles.info_select}
                                                    value={formData.forumIDX ?? ''}
                                                    name="forumIDX" 
                                                    onChange={handleNumberChange}
                                                >
                                                    <option value="0">참여포럼 없음</option>
                                                    {formData.forumList.map((forum, index) => (
                                                        <option
                                                            key={index}
                                                            value={forum.idx}
                                                        >
                                                            {forum.time}회 / [{forum.year}년 {forum.month}월]
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className={styles.detail_info}>
                                                <span className={styles.info_title}>진로</span>
                                                <select className={`${styles.info_select} ${styles.info_agree}`} name="future" value={formData.future ?? ''} onChange={handleNumberChange}>
                                                    <option value="0">미정</option>
                                                    <option value="1">대학생</option>
                                                    <option value="2">대학원생</option>
                                                    <option value="3">취업(직장생활)</option>
                                                </select>
                                            </div>
                                            <div className={styles.detail_info}>
                                                <span className={styles.info_title}>등급명</span>
                                                <span className={`${styles.info_cont} ${styles.info_gradeName}`}>{formData.gradeName}</span>
                                                <span className={styles.info_important}>등급은 회원 등급에서 변경할 수 있습니다.</span>
                                            </div>
                    
                                            <div className={styles.detail_line}></div>
                    
                                            <div className={styles.select_box_container}>
                                                {formData.organization && formData.organization.length > 0 ? (
                                                    formData.organization.map((item, index) => (
                                                        <div className={styles.detail_info} key={index}>
                                                            {index === 0 ? (
                                                                <>
                                                                    <span className={styles.info_title}>YEHS 경력</span>
                                                                    <div className={styles.info_cont}>
                                                                        {item.yearName} {item.deptName} {item.position}
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className={styles.info_cont}>
                                                                    <span className={styles.info_title}></span>
                                                                    {item.yearName} {item.deptName} {item.position}
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className={styles.detail_info}>
                                                        <span className={styles.info_title}>YEHS 경력</span>
                                                        <div className={styles.info_cont}>-</div>
                                                    </div>
                                                )}
                                                <span className={styles.info_important}>YEHS경력은 YEHS소개 → 조직 구성에서 변경할 수 있습니다.</span>
                                            </div>
                    
                                            <div className={styles.detail_info}>
                                                <span className={styles.info_title}>키워드</span>
                                                <input type="text" name="keyword" value={formData.keyword} placeholder='키워드1, 키워드2' className={`${styles.info_cont} ${styles.info_keyword}`} onChange={handleInputChange} />
                                            </div>
                    
                                            <div className={styles.select_box_container}>
                                            {formData.universityPack
                                                .filter(box => box.status !== 'D' && box.status !== 'F')
                                                .map((box, index) => (
                                                    <div className={styles.detail_info} key={box.idx}>
                                                        <span className={styles.info_title}>학교/학과</span>
                                                        <input
                                                            type="text"
                                                            value={box.university}
                                                            placeholder="학교"
                                                            className={`${styles.info_cont_2} `}
                                                            onChange={(event) => handleSchoolChange(box.idx, 'university', event.target.value)} />
                                                        <input
                                                            type="text"
                                                            value={box.major}
                                                            placeholder="학과"
                                                            className={`${styles.info_cont_2}  `}
                                                            onChange={(event) => handleSchoolChange(box.idx, 'major', event.target.value)} />
                                                        <select
                                                            className={`${styles.info_cont_2}`}
                                                            value={box.degree} // 학위에 대한 값
                                                            onChange={(event) => handleSchoolChange(box.idx, 'degree', parseInt(event.target.value, 10))} // 학위를 변경
                                                        >
                                                            <option value="1">학사</option>
                                                            <option value="2">석사</option>
                                                            <option value="3">박사</option>
                                                        </select>
                                                        <select
                                                            className={`${styles.info_cont_2}`}
                                                            value={box.degreeAttend} // 학위 상태에 대한 값
                                                            onChange={(event) => handleSchoolChange(box.idx, 'degreeAttend', parseInt(event.target.value, 10))} // 학위 상태를 변경
                                                        >
                                                        <option value="1">재학</option>
                                                        <option value="2">수료</option>
                                                        <option value="3">졸업</option>
                                                    </select>
                                                        {index === 0 ? (
                                                            <button onClick={() => handleAddUniversityBox('universityPack')} className={styles.add_button}> + </button>
                                                        ) : (
                                                            <button onClick={() => handleRemoveUniversityBox('universityPack', box.idx)} className={styles.remove_button}> - </button>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                    
                                            <div className={styles.detail_info}>
                                                <span className={styles.info_title}>직장</span>
                                                <input type="text" name="job" placeholder='직장' value={formData.job} className={`${styles.info_cont} ${styles.info_company}`} onChange={handleInputChange} />
                                            </div>
                    
                                            <div className={styles.select_box_container}>
                                            {formData.careerPack
                                            .filter(box => box.status !== 'D' && box.status !== 'F')
                                            .map((box, index) => (
                                                <div className={styles.detail_info} key={box.idx}>
                                                    <span className={styles.info_title}>경력</span>
                                                    <input
                                                        type="text"
                                                        placeholder="경력"
                                                        value={box.career}
                                                        className={`${styles.info_cont} ${styles.info_statusIDX}`}
                                                        onChange={(event) => handleCareerChange(box.idx, 'career', event.target.value)}
                                                    />
                                                    {index === 0 ? (
                                                        <button onClick={handleAddCareerBox} className={styles.add_button}> + </button>
                                                    ) : (
                                                        <button onClick={() => handleRemoveCareerBox(box.idx)} className={styles.remove_button}> - </button>
                                                    )}
                                                </div>
                                            ))}
                                            </div>
                    
                                            <div className={styles.detail_line}></div>
                    
                                            <div className={styles.detail_info}>
                                                <span className={styles.info_title}>생성시간</span>
                                                <span className={`${styles.info_cont} ${styles.info_createTime}`}>{formData.createTime}</span>
                                            </div>
                    
                                            <div className={styles.detail_info}>
                                                <span className={styles.info_title}>참여행사</span>
                                                <button onClick={() => pModal('행사참여', eventHistoryData, '1200px', '600px')} className={`${styles.info_cont} ${styles.user_detail_btn}`}>참여내역</button>
                                            </div>
                    

                                            
                                            <div className={styles.detail_btn_box}>
                                                <button
                                                onClick={() => handleLog()}
                                                className={`${styles.user_detail_btn}  ${styles.log_btn} `}
                                                >
                                                    로그
                                                </button>
                                                <button 
                                                onClick={() => handleSubmit()} 
                                                className={`${styles.user_detail_btn}  ${styles.update_btn} `}
                                                >
                                                    수정
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                    
                                ) : (
                                    <div className={styles.default_wrap}>
                                        <FaMousePointer className={`${styles.xi_mouse_pointer} ${styles.mouse_icon}`} />
                                        <span className={styles.exp_default_txt}>데이터를 선택해주세요.</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default User;
