import React, { useEffect, useRef, useState, useCallback, useImperativeHandle } from 'react';
import _ from 'lodash'; // lodash 임포트 추가
import DatePicker from 'react-datepicker';
import { FaCalendarDay, FaDownload } from 'react-icons/fa';
import { format, addHours } from 'date-fns';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize';
import axios from 'axios';
import common from '../../common';
import './Editor.scss';
import Switch from 'react-switch';
import Select from 'react-select';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Quill에 모듈 등록
Quill.register('modules/imageResize', ImageResize);

// Quill의 기본 Link 클래스를 확장하여 CustomLink 클래스를 정의합니다.
const Link = Quill.import('formats/link');
class CustomLink extends Link {
	static create(value) {
		const node = super.create(value);
		node.setAttribute('class', 'pl_blog_link');
		return node;
	}
}
CustomLink.blotName = 'customLink';
CustomLink.tagName = 'a';
CustomLink.className = 'pl_blog_link';

Quill.register(CustomLink, true);

const QuillEditor = ({ selectedData, setSelectedData, formData, setFormData, refreshTable, dataTableRef, tempFormRef, quillRef }) => {
	const { useAlert, useLoading, useModal, useConfirm, pToast } = common();
	const { pModal, ModalComponent } = useModal(); // 훅 사용
	const { pAlert, AlertComponent } = useAlert();
	const { pConfirm, ConfirmComponent } = useConfirm();
	const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

	const editorRef = useRef(null);
	const [logData, setLogData] = useState([]); // 로그 데이터를 상태로 관리

	//에디터 내 이미지 업로드
	const imageHandler = async (file) => {
		const edImgFormData = new FormData();
		edImgFormData.append('file', file);
		try {
			pLoadingOn();
			const response = await axios.post(`${API_BASE_URL}/admin_document/in/upload_image`, edImgFormData, {
				withCredentials: true
			});
			if (response.data.result === 't') {
				const imageUrl = response.data.url;
				// console.log("Uploaded image URL:", imageUrl);  // URL 확인

				const quill = quillRef.current;
				const range = quill.getSelection(true);
				quill.insertEmbed(range.index, 'image', imageUrl);
				quill.setSelection(range.index + 1);
			} else {
				pAlert(response.data.msg);
			}
		} catch (error) {
			console.error('Image upload failed:', error);
			pAlert('이미지 업로드에 실패했습니다. 다시 시도해주세요.');
		} finally {
			pLoadingOff();  // 로딩 종료
		}
	};

	//에디터 내 블로그 바로가기 버튼
	// const insertLink = () => {
	// 	const quill = quillRef.current;
	// 	quill.focus();
	// 	let range = quill.getSelection();
	// 	if (!range) {
	// 		// range가 null이면 커서 위치를 설정합니다.
	// 		range = { index: quill.getLength(), length: 0 };
	// 		quill.setSelection(range);
	// 	}
	// 	console.log("range:", range);
	// 	if (range) {
	// 		const linkText = "네이버 블로그 바로가기";
	// 		const linkUrl = "https://blog.naver.com/naek1995";
	// 		const node = document.createElement('a');
	// 		node.setAttribute('href', linkUrl);
	// 		node.setAttribute('class', 'pl_blog_link');
	// 		node.setAttribute('target', '_blank');
	// 		node.setAttribute('rel', 'noopener noreferrer');
	// 		node.innerHTML = linkText;
	// 		quill.clipboard.dangerouslyPasteHTML(range.index, node.outerHTML);
	// 		quill.insertText(range.index + linkText.length, ' ');  // 링크 삽입 후 띄어쓰기 추가
	// 		quill.setSelection(range.index + linkText.length + 1);  // 커서 위치 조정
	// 		console.log(`링크 삽입됨: ${linkUrl}`);
	// 	}
	// };

	useEffect(() => {
		if (editorRef.current) {
			const quill = new Quill(editorRef.current, {
				theme: 'snow',
				modules: {
					toolbar: {
						container: [
							[{ 'font': [] }],
							[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
							['bold', 'underline', 'italic', 'strike'],
							[{ 'background': [] }, 'clean'],
							[{ 'script': 'sub' }, { 'script': 'super' }],
							[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'align': [] }],
							['link', 'image', 'video'],
							['blockquote', 'code-block'],
						],
						handlers: {
							image: () => {
								const input = document.createElement('input');
								input.setAttribute('type', 'file');
								input.setAttribute('accept', 'image/*');
								input.click();
								input.onchange = () => {
									const file = input.files[0];
									imageHandler(file);
								};
							},
							// customLink: insertLink
						}
					},
					imageResize: {
						modules: ['Resize', 'DisplaySize', 'Toolbar']
					},
					clipboard: {
						matchers: [
							['p', (node, delta) => {
								// 'style' 속성이 있는 경우
								if (node.hasAttribute('style')) {
									const styleValue = node.getAttribute('style'); // 현재 스타일 값 가져오기
									const cleanedStyle = styleValue
										.split(';') // 각 스타일을 ';'로 분리
										.map(style => style.trim()) // 각 스타일 앞뒤 공백 제거
										.filter(style => {
											const [property, value] = style.split(':').map(s => s.trim());
											// CSS 속성(property)과 값(value) 모두 존재하고, 특정 속성(property)이 '13px'과 관련되지 않으면 유지
											return property && value && !property.includes('13px');
										})
										.join('; '); // 유효한 스타일들을 다시 ';'로 결합

									if (cleanedStyle) {
										// 유효한 스타일이 남아있으면, 다시 노드에 스타일 설정
										node.setAttribute('style', cleanedStyle);
									} else {
										// 유효한 스타일이 없다면, 스타일 속성 제거
										node.removeAttribute('style');
									}
								}
								return delta; // 처리된 내용을 반환하여 Quill이 다음 작업을 할 수 있도록 함
							}]
						]
					}
				},
				formats: ['link', 'bold', 'underline', 'italic', 'strike', 'image', 'video', 'header', 'list', 'align', 'background', 'color', 'font', 'code-block', 'script', 'blockquote', 'customLink']
			});

			quillRef.current = quill;

			const toolbar = quill.getModule('toolbar');
			const customButtonClass = 'ql-customLink';

			// if (!toolbar.container.querySelector(`.${customButtonClass}`)) {
			// 	const customButton = document.createElement('button');
			// 	customButton.innerHTML = '네이버 블로그 바로가기 링크';
			// 	customButton.className = customButtonClass;
			// 	customButton.type = 'button';
			// 	customButton.onclick = insertLink;
			// 	toolbar.container.appendChild(customButton); // 툴바의 가장 마지막에 버튼 추가
			// }

			const handleDrop = async (event) => {
				event.preventDefault();
				event.stopPropagation();

				const files = event.dataTransfer.files;
				if (files && files.length > 0) {
					const file = files[0];
					await imageHandler(file);
				}
			};

			const handleDragOver = (event) => {
				event.preventDefault();
				event.stopPropagation();
			};

			quill.root.addEventListener('drop', handleDrop, true);
			quill.root.addEventListener('dragover', handleDragOver, true);

			const editorContainer = editorRef.current;
			editorContainer.addEventListener('drop', handleDrop);
			editorContainer.addEventListener('dragover', handleDragOver);

			// MutationObserver를 사용하여 DOMNodeInserted 이벤트 대체
			const observer = new MutationObserver((mutations) => {
				mutations.forEach((mutation) => {
					if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
						// 새로운 노드가 추가되었을 때 필요한 작업을 여기에 추가합니다.
						// console.log('MutationObserver detected nodes being added:', mutation.addedNodes);
					}
				});
			});

			observer.observe(quill.root, { childList: true });

			return () => {
				quill.root.removeEventListener('drop', handleDrop, true);
				quill.root.removeEventListener('dragover', handleDragOver, true);
				editorContainer.removeEventListener('drop', handleDrop);
				editorContainer.removeEventListener('dragover', handleDragOver);

				const buttonToRemove = toolbar.container.querySelector(`.${customButtonClass}`);
				if (buttonToRemove) {
					buttonToRemove.remove();
				}

				observer.disconnect();
			};
		}
	}, []);

	useEffect(() => {
		if (quillRef.current && formData && formData.contents) {
			const quill = quillRef.current;
			const clipboard = quill.getModule('clipboard');
			clipboard.dangerouslyPasteHTML(formData.contents);
		}
	}, [formData]);








	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		if (showModal) {
			pModal('', <LogData logData={logData} />, '1200px', 'auto');
			setShowModal(false);  // Reset the flag
		}
	}, [logData, showModal]);

	const handleLog = async () => {
		await fetchLogData();
		setShowModal(true);
	};

	const fetchLogData = async () => {
		try {
			pLoadingOn();
			const response = await axios.post(`${API_BASE_URL}/admin_log/in/get_log`, {
				target_idx: selectedData.idx,
				type: formData.type
			}, {
				withCredentials: true
			});
			// console.log(response.data);
			if (response.data.result === 't') {
				setLogData(Array.isArray(response.data.data) ? response.data.data : []); // logData가 항상 배열이 되도록 설정
			} else {
				pAlert(response.data.msg);
			}
		} catch (error) {
			console.error('Error fetching log data:', error);
			pAlert('로그 데이터를 가져오는데 실패했습니다. 다시 시도해주세요.');
		} finally {
			pLoadingOff();
		}
	};


	function LogData() {
		return (
			<>
				<table className='modal_sub_table'>
					<thead>
						<tr>
							<th className='modal_1_sub'>제목</th>
							<th className='modal_2_sub'>내용</th>
							<th className='modal_3_sub'>처리인</th>
							<th className='modal_4_sub'>처리일</th>
						</tr>
					</thead>
					<tbody>
						{logData && logData.length > 0 ? (
							logData.map((data, index) => (
								<tr key={index}>
									<td>{data.status}</td>
									<td>{data.ex}</td>
									<td>{data.name}</td>
									<td>{data.createTime || 'null'}</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan="4">데이터가 없습니다.</td>
							</tr>
						)}
					</tbody>
				</table>
			</>
		);
	}

	const convertURLToFile = async (url, fileName) => {
		const response = await fetch(url);
		const data = await response.blob();
		return new File([data], fileName, { type: data.type });
	};

	const fieldNames = {
		headCate: '카테고리',
		viewStatus: '홈페이지 노출',
		title: '제목',
		contents: '내용',
		place: '장소',
		startDate: '시작일',
		endDate: '종료일',
		acceptStartTime: '행사 시작일',
		acceptEndTime: '행사 종료일',
		file: '썸네일',
		fileDel: '첨부파일',
		thumbnailFile: '첨부파일',
		thumbnailDel: '첨부파일',
		writeType: '글형식',
	};

	const insertFieldNames = {
		headCate: '카테고리',
		viewStatus: '홈페이지 노출',
		title: '제목',
		contents: '내용',
		place: '장소',
		startDate: '시작일',
		endDate: '종료일',
		acceptStartTime: '행사 시작일',
		acceptEndTime: '행사 종료일',
		file: '썸네일',
		thumbnailFile: '첨부파일',
		writeType: '글형식',
	};

	const handleSave = async (e) => {
		e.preventDefault();

		setFormData(prevState => ({
			...prevState,
			title: tempFormData.title,
			place: tempFormData.place,
			link: tempFormData.link,
		}));


		const newFormData = {}; // 변화된 데이터만 기록
		let newUpdateFormData = new FormData();

		if (tempFormData.title !== formData.title) {
			newUpdateFormData.append('title', tempFormData.title);
		}
		if (tempFormData.place !== formData.place) {
			newUpdateFormData.append('place', tempFormData.place);
		}
		if (tempFormData.link !== formData.link) {
			newUpdateFormData.append('link', tempFormData.link);
		}

		if (!formData.type || !formData.cateIdx) {
			pAlert('잘못된 접근입니다. 새로고침 후 다시 시도해 주세요.');
			return;
		}
		if (!tempFormData.title) {
			pAlert('제목을 입력해 주세요.');
			return;
		}
		if (formData.headCate && !formData.headCate.value) {
			pAlert('카테고리를 선택해 주세요.');
			return;
		}
		if (tempFormData.place && !tempFormData.place) {
			pAlert('장소를 입력해 주세요.');
			return;
		}

		const quill = quillRef.current;
		const contents = quill.root.innerHTML; // quill의 컨텐츠 가져오기
		// console.log("Quill Contents Before Normalization:", contents);
		if (!contents || contents.trim() === '<p><br></p>') {
			pAlert('내용을 입력해 주세요.');
			return;
		}

		// 상태 업데이트와 동기화하여 진행
		setFormData(prevState => ({
			...prevState,
			contents: contents, // 폼 데이터에 업데이트
		}));

		

		Object.keys(formData).forEach(key => {
			if (insertFieldNames[key]) {
				if (key === 'headCate' || key === 'writeType') {
					newFormData[key] = formData[key].value;
				} else if (key === 'contents') {
					newFormData[key] = contents;
				} else if (key === 'viewStatus') {

					newFormData[key] = formData[key] ? '1' : '0';
				} else {
					newFormData[key] = formData[key];
				}
			}
		});

		newUpdateFormData.append('cateIdx', formData.cateIdx);
		newUpdateFormData.append('type', formData.type);

		Object.entries(newFormData).forEach(([key, value]) => {
			if ((key === 'file' || key === 'thumbnailFile') && Array.isArray(value)) {
				value.forEach((file) => {
					newUpdateFormData.append(key, file);
				});
			} else if (Array.isArray(value)) {
				newUpdateFormData.append(key, JSON.stringify(value));
			} else {
				newUpdateFormData.append(key, value);
			}
		});

		// console.log('------------------폼데이타시작------------------')
		// for (let [key, value] of newUpdateFormData.entries()) {
		// 	console.log(`${key}: ${value instanceof File ? value.name : value}`);
		// }
		// console.log('------------------폼데이타끝------------------')

		pConfirm(
			'게시글 등록',
			<>
				<div>해당 게시글을 등록하시겠습니까?</div>
			</>,
			'400px',
			'auto',
			async () => {
				try {
					pLoadingOn();
					const response = await axios.post(`${API_BASE_URL}/admin_document/in/document_insert`,
						newUpdateFormData,
						{ withCredentials: true }
					);
					if (response.data.result === 't') {
						pToast('게시물 등록이 완료되었습니다.');
						dataTableRef.current.refreshTable();
						setSelectedData(prevFormData => ({
							...prevFormData,
							resetStatus: true
						}));
					} else {
						pAlert(response.data.msg);
					}
				} catch (error) {
					console.error('등록 실패:', error);
					pAlert('등록에 실패했습니다. 다시 시도해 주세요.');
				} finally {
					pLoadingOff();
				}
			}
		);
	};


	const normalizeHtml = (htmlString) => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlString, 'text/html');

		const removeWhitespace = (node) => {
			if (node.nodeType === Node.TEXT_NODE) {
				node.nodeValue = node.nodeValue.trim();
			} else if (node.nodeType === Node.ELEMENT_NODE) {
				Array.from(node.childNodes).forEach(removeWhitespace);
			}
		};

		removeWhitespace(doc.body);

		const normalizeElement = (element) => {
			if (element.tagName.toLowerCase() === 'iframe') {
				const src = element.getAttribute('src');
				element.innerHTML = ''; // 내용 제거
				Array.from(element.attributes).forEach(attr => {
					if (attr.name !== 'src') {
						element.removeAttribute(attr.name);
					}
				});
				if (src) {
					element.setAttribute('src', src);
				}
			} else {
				if (element.hasAttribute('style')) {
					const styleValue = element.getAttribute('style');
					const cleanedStyle = styleValue
						.split(';')
						.map(style => style.trim())
						.filter(style => {
							const [property, value] = style.split(':').map(s => s.trim());
							// 잘못된 속성 값(예: '13px;')을 필터링
							return property && value && /^[a-z-]+$/i.test(property) && !property.includes('13px');
						})
						.join('; ');

					if (cleanedStyle) {
						element.setAttribute('style', cleanedStyle);
					} else {
						element.removeAttribute('style');
					}
				}

				Array.from(element.children).forEach(normalizeElement);
			}
		};

		Array.from(doc.body.children).forEach(normalizeElement);

		return doc.body.innerHTML.trim();
	};

	const handleUpdate = async () => {
		const quill = quillRef.current;
		const contents = quill.root.innerHTML; // quill의 컨텐츠 가져오기

		let updatedFormData = {
			...formData,
			contents: contents, // 폼 데이터에 업데이트
		};
		
		// 에디터 내용 업데이트
		// setFormData(prevState => {
		// 	const updatedFormData = {
		// 		...prevState,
		// 		contents: contents, // 폼 데이터에 업데이트
		// 	};
		// 	return updatedFormData;
		// });

		const newFormData = {}; // 변화된 데이터만 기록
		let newUpdateFormData = new FormData();

		await new Promise((resolve) => setTimeout(resolve, 0)); // 상태 업데이트 후 다음 틱으로 이동

		const normalizedFormDataContents = normalizeHtml(contents);
		const normalizedSelectedDataContents = normalizeHtml(selectedData.contents);

		// 임시 formData에 값과 선택된 값과 조사해서 값이 틀리면 append
		if (tempFormData.title !== selectedData.title) {
			newUpdateFormData.append('title', tempFormData.title);
			updatedFormData.title = tempFormData.title;
		}
		if (tempFormData.place !== selectedData.place) {
			newUpdateFormData.append('place', tempFormData.place);
			updatedFormData.place = tempFormData.place;
		}
		if (tempFormData.link !== selectedData.link) {
			newUpdateFormData.append('link', tempFormData.link);
			updatedFormData.link = tempFormData.link;
		}


		Object.keys(formData).forEach(key => {
			if (fieldNames[key]) {
				if (key === 'headCate' || key === 'writeType') {
					if (formData[key]?.value !== selectedData[key]?.value) {
						newFormData[key] = formData[key].value;
					}
				} else if (key === 'contents') {
					if (normalizedFormDataContents !== normalizedSelectedDataContents) {
						newFormData[key] = contents;
					}
				} else if (['fileDel', 'thumbnailDel'].includes(key)) {
					if (Array.isArray(formData[key]) && formData[key].length > 0) {
						newFormData[key] = formData[key];
					}
				} else if (key === 'viewStatus') {
					if (Boolean(formData[key]) !== Boolean(selectedData[key])) {
						newFormData[key] = formData[key] ? '1' : '0';
					}
				} else if (formData[key] !== selectedData[key]) {
					newFormData[key] = formData[key];
				}
			}
		});

		newUpdateFormData.append('cateIdx', formData.cateIdx);
		newUpdateFormData.append('type', formData.type);
		newUpdateFormData.append('targetIdx', selectedData.idx);


		Object.entries(newFormData).forEach(([key, value]) => {
			if ((key === 'file' || key === 'thumbnailFile') && Array.isArray(value)) {
				value.forEach((file) => {
					newUpdateFormData.append(key, file);
				});
			} else if (Array.isArray(value)) {
				newUpdateFormData.append(key, JSON.stringify(value));
			} else {
				newUpdateFormData.append(key, value);
			}
		});

		if (Array.from(newUpdateFormData.keys()).filter(key => key !== 'cateIdx' && key !== 'type' && key !== 'targetIdx').length === 0) {
			pToast('변경된 내용이 없습니다.');
			return;
		}

		// console.log('------------------폼데이타시작------------------')
		// for (let [key, value] of newUpdateFormData.entries()) {
		// 	console.log(`${key}: ${value instanceof File ? value.name : value}`);
		// }
		// console.log('------------------폼데이타끝------------------')


		pConfirm(
			'수정',
			<>
				<div>해당 게시물을 수정 하시겠습니까?</div>
			</>,
			'400px',
			'auto',
			async () => {
				try {

					const response = await axios.post(`${API_BASE_URL}/admin_document/in/document_update`, newUpdateFormData, {
						withCredentials: true,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					});
					if (response.data.result === 't') {
						await dataTableRef.current.refreshTable();
						setSelectedData(prevFormData => {
							const updatedData = {
								...prevFormData,
								...newFormData
							};
							updatedData.fileDel = [];
							updatedData.file = [];

							const selectedHeadCateOption = formData?.headCateOptions?.find(option => option.value === updatedData?.headCate);

							if (newFormData.headCate) {
								updatedData.headCate = selectedHeadCateOption;
							}

							// tempFormData를 updatedData의 최신값으로 설정
							setTempFormData({
								title: updatedData.title,
								place: updatedData.place,
								link: updatedData.link,
							});

							return updatedData;
						});
						
						// formData에도 동일한 업데이트 반영
						setFormData(prevFormData => ({
							...prevFormData,
							...updatedFormData, // 업데이트된 데이터를 반영
							file: [],
							fileDel: []
						}));

						pToast('수정 완료.');
					} else {
						pAlert(response.data.msg);
					}
				} catch (error) {
					console.error('등록 실패:', error);
					pAlert('등록에 실패했습니다. 다시 시도해 주세요.');
				} finally {
					pLoadingOff();
				}
			}
		);
	};


	const handleDelete = async () => {
		pConfirm(
			'삭제',
			<>
				<div>해당 게시물을 삭제 하시겠습니까?</div>
			</>,
			'400px',
			'auto',
			async () => {
				try {
					pLoadingOn();
					const response = await axios.post(`${API_BASE_URL}/admin_document/in/document_delete`, { cateIdx: formData.cateIdx, type: formData.type, targetIdx: selectedData.idx }, { withCredentials: true });
					if (response.data.result === 't') {
						pToast('해당 게시물이 삭제되었습니다.');
						dataTableRef.current.refreshTable();

						setSelectedData(prevFormData => ({
							...prevFormData,
							resetStatus: true
						}));


					} else {
						pAlert(response.data.msg);
					}
				} catch (error) {
					pAlert('삭제에 실패했습니다. 다시 시도해 주세요.');
				} finally {
					pLoadingOff();
				}
			}
		);
	};


	const isValidDate = (date) => {
		return !isNaN(Date.parse(date));
	};

	const [selectedFiles, setSelectedFiles] = useState([]);
	const imageInputRef = useRef(null);
	const [inputKey, setInputKey] = useState(Date.now());  // 파일 입력 요소의 키를 설정하여 고유하게 만듦























	// 수정 중 게시물 작성 클릭하면 ref 받아서 제목과 장소 초기화
	useImperativeHandle(tempFormRef, () => ({
        resetTempFormData() {
            setTempFormData({
                title: '',
                place: '',
                link: '',
                // 기타 초기값 설정
            });
        }
    }));

	// 제목과 장소를 임시로 tempFormData에 추가
	// 기존 props로 받아온 formData에서 직접 수정 하게되면 수정하는 순간 리렌더링 되서 포커스가 에디터로 이동함

	const [tempFormData, setTempFormData] = useState({
		title: formData.title || '',
		place: formData.place || '',
		link: formData.link || '',
		// 필요한 다른 필드들도 여기에 포함할 수 있습니다.
	});

	useEffect(() => {
		if (formData.title || formData.place || formData.link) {
		  setTempFormData({
			title: formData.title || '',
			place: formData.place || '',
			link: formData.link || '',
		  });
		}
	  }, [formData]);

	const titleInputRef = useRef(null);
	const placeInputRef = useRef(null);
	const linkInputRef = useRef(null);

	const handleTitleChange = (e) => {
		const value = e.target.value;
		setTempFormData((prevState) => ({
			...prevState,
			title: value,
		}));
	};

	const handlePlaceChange = (e) => {
		const value = e.target.value;
		setTempFormData((prevState) => ({
			...prevState,
			place: value,
		}));
	};

	const handleLinkChange = (e) => {
		const value = e.target.value;
		setTempFormData((prevState) => ({
			...prevState,
			link: value,
		}));
	};





















	const handleFileDownload = async (file) => {
		if (file.isLocal) {
			const link = document.createElement('a');
			link.href = file.serverName;
			link.download = file.name;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} else {
			try {
				pLoadingOn();


				// 원본
				// const response = await axios.post(`${API_BASE_URL}/admin_document/in/get_file`, {
				// 	cateIdx: formData.cateIdx,
				// 	idx: [file.idx],
				// 	serverName: file.serverName,
				// 	name: file.name
				// }, {
				// 	withCredentials: true,
				// 	responseType: 'blob'
				// });

				// if (response.status === 200) {
				// 	const blob = response.data;
				// 	const downloadUrl = window.URL.createObjectURL(blob);

				// 	const link = document.createElement('a');
				// 	link.href = downloadUrl;
				// 	link.download = file.name;
				// 	document.body.appendChild(link);
				// 	link.click();
				// 	document.body.removeChild(link);

				// 	window.URL.revokeObjectURL(downloadUrl);
				// } else {
				// 	pAlert('파일 다운로드에 실패했습니다.');
				// }

				// 20240812 추가
				const type = formData.type;
				const fileFormData = new FormData();
				fileFormData.append('type', type);
				fileFormData.append('idx', file.idx);
				fileFormData.append('serverName', file.serverName);
				fileFormData.append('name', file.name);

				// const response = await axios.post(`${API_BASE_URL}/admin_document/in/get_file`, fileFormData, {
				// 	withCredentials: true,
				// });
				// if (response.status === 200) {
				// 	const fileUrl = response.data.data;

				// 	// Fetching the file as a Blob
				// 	const fileResponse = await fetch(fileUrl);
				// 	const blob = await fileResponse.blob();

				// 	const downloadUrl = window.URL.createObjectURL(blob);
				// 	const link = document.createElement('a');
				// 	link.href = downloadUrl;
				// 	link.download = file.name; // 원하는 파일 이름으로 설정
				// 	document.body.appendChild(link);
				// 	link.click();
				// 	document.body.removeChild(link);

				// 	window.URL.revokeObjectURL(downloadUrl);
				// } else {
				// 	pAlert('파일 다운로드에 실패했습니다.');
				// }
				// 20240812 추가




				const response = await axios.post(`${API_BASE_URL}/admin_document/in/get_file`, fileFormData , {
					withCredentials: true,
					responseType: 'blob'  // 응답 타입을 blob으로 설정
				});

				if (response.status === 200) { // HTTP 응답 상태 코드가 200인 경우
					const blob = response.data;
					const downloadUrl = window.URL.createObjectURL(blob);

					// Blob을 사용하여 파일 다운로드
					const link = document.createElement('a');
					link.href = downloadUrl;
					link.download = file.name;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);

					// Blob URL을 해제
					window.URL.revokeObjectURL(downloadUrl);
				} else {
					pAlert('파일 다운로드에 실패했습니다.');
				}
			} catch (error) {
				pAlert(`파일다운에 실패했습니다. 다시 시도해 주세요.`);
			} finally {
				pLoadingOff();
			}
		}
	};
	const handleFileChange = (event) => {
		const files = Array.from(event.target.files);
		const newFileList = files.map((file, index) => ({
			idx: `${Date.now()}-${index}`,  // 고유 ID 생성
			name: file.name,
			serverName: URL.createObjectURL(file),  // 로컬 파일 URL 생성
			isLocal: true  // 로컬 파일 표시
		}));

		setFormData(prevFormData => ({
			...prevFormData,
			fileList: [...(prevFormData.fileList || []), ...newFileList],  // 기존 파일 리스트에 새로운 파일을 추가
			file: [...(prevFormData.file || []), ...files]  // 기존 파일 리스트에 새로운 파일을 추가
		}));

		// 파일 입력 요소 초기화
		setInputKey(Date.now());
	};

	// 파일 업로드
	const handleFileSelect = (file) => {
		setSelectedFiles((prevSelected) => {
			if (prevSelected.includes(file)) {
				return prevSelected.filter((f) => f !== file);
			} else {
				return [...prevSelected, file];
			}
		});
	};
	const handleFileDeleteSelected = () => {
		const newFileList = formData.fileList.filter(file => !selectedFiles.includes(file));
		const deletedFiles = formData.fileList.filter(file => selectedFiles.includes(file));


		// 원본
		// const newFileDel = deletedFiles.filter(file =>
		// 	(selectedData.fileBefore || []).some(beforeFile => beforeFile.idx === file.idx)
		// );
		// const remainingFiles = (formData.file || []).filter(file => !selectedFiles.some(selected => selected.name === file.name));
		// setFormData(prevFormData => ({
		// 	...prevFormData,
		// 	fileList: newFileList,
		// 	fileDel: [...(prevFormData.fileDel || []), ...newFileDel], // 기존 fileDel 유지하면서 삭제된 파일 추가
		// 	file: remainingFiles  // 새로운 파일 리스트에서 삭제된 파일 제거
		// }));





		// 20240812 추가
		const remainingFiles = (formData.file || []).filter(file => !selectedFiles.some(selected => selected.name === file.name));
		const updatedFormData = {
			...formData,
			fileList: newFileList,
			fileDel: [...(formData.fileDel || []), ...deletedFiles], // 기존 fileDel 유지하면서 삭제된 파일 추가
			file: remainingFiles  // 새로운 파일 리스트에서 삭제된 파일 제거
		};
		setFormData(updatedFormData);
		// 20240812 추가





		setSelectedFiles([]);
		// 파일 입력 요소 초기화
		setInputKey(Date.now());
	};

	// 썸네일 변경
	const handleImageChange = (e) => {
		const file = e.target.files[0];

		if (!file) return; // 파일이 없을 경우 함수를 종료
		pLoadingOn();

		// 이미지 파일 타입 체크
		if (!file.type.startsWith('image/')) {
			pLoadingOff();
			pToast('유효하지 않은 이미지입니다.');
			return;
		}

		const reader = new FileReader();
		reader.onloadend = () => {
			if (reader.result && reader.result.startsWith('data:image/')) {
				const img = new Image();
				img.onerror = () => {
					pLoadingOff();
					pToast('유효하지 않은 이미지입니다.');
					return;
				};
				img.onload = () => {
					// 이미지가 정상적으로 로드되면 formData.thumbnail 업데이트
					setFormData((prevState) => ({
						...prevState,
						thumbnail: [{ name: file.name, url: reader.result }],
						thumbnailFile: file,
						thumbnailDel: [],
					}));
					pLoadingOff(); // 로드 완료 후 로딩 중지
				};
				img.src = reader.result; // 이미지 소스로 reader.result 설정
			} else {
				pLoadingOff();
				pToast('유효하지 않은 이미지입니다.');
				return;
			}
		};
		reader.onerror = () => {
			pLoadingOff();
			pToast('유효하지 않은 이미지입니다.');
			return;
		};

		reader.readAsDataURL(file);

		// 같은 파일을 다시 업로드할 수 있도록 input 초기화
		e.target.value = '';
	};

	const handleImageDelete = () => {
		setFormData((prevState) => {
			// selectedData.thumbnail이 undefined이거나 빈 배열인지 확인
			const newThumbnailDel = selectedData?.thumbnail?.length > 0 ? prevState.thumbnail : prevState.thumbnailDel;

			return {
				...prevState,
				thumbnailDel: newThumbnailDel,
				thumbnail: [],
				thumbnailFile: []
			};
		});

		// 같은 파일을 다시 업로드할 수 있도록 input 초기화
		if (imageInputRef.current) {
			imageInputRef.current.value = '';
		}
	};


	const handleDateChange = (date, fieldName) => {
		// 선택한 날짜를 한국 시간(KST)으로 변환하여 저장
		const kstDate = addHours(date, 9);
		const formattedDate = kstDate ? format(kstDate, 'yyyy-MM-dd') : "0000-00-00";
		setFormData(prevState => ({
			...prevState,
			[fieldName]: formattedDate,
			[`${fieldName}Data`]: kstDate  // 원본 KST Date 객체도 저장
		}));
	};



	const handleAcceptDateChange = (date, fieldName) => {
		// 선택한 날짜를 한국 시간(KST)으로 변환하여 저장
		const kstDate = date ? addHours(date, 0) : null;  // KST 변환
		const formattedDate = kstDate ? format(kstDate, 'yyyy-MM-dd HH:mm:ss') : "0000-00-00 00:00:00";
		
		// 현재 시간을 가져옴
		const currentTime = new Date();
	
		// distinction 값 계산
		let distinction = 'AFTER';  // 기본값으로 'AFTER' 설정
	
		setFormData(prevState => {
			// 현재 formData에서 acceptStartTime 및 acceptEndTime을 가져옴
			const startTime = fieldName === 'acceptStartTime' ? kstDate : (prevState.acceptStartTimeData ? new Date(prevState.acceptStartTimeData) : null);
			const endTime = fieldName === 'acceptEndTime' ? kstDate : (prevState.acceptEndTimeData ? new Date(prevState.acceptEndTimeData) : null);
	
			if (startTime && startTime > currentTime) {
				distinction = 'BEFORE';  // 예약
			} else if (startTime && startTime <= currentTime) {
				if (!endTime || (endTime && endTime > currentTime)) {
					distinction = 'NOW';  // 모집중
				}
			}
	
			return {
				...prevState,
				[fieldName]: formattedDate,
				[`${fieldName}Data`]: kstDate,  // 원본 KST Date 객체도 저장
				distinction: distinction
			};
		});
	};



	// useEffect(() => {
    //     console.log('formData:', formData);  // formData 값 확인
    // }, [formData]);


	return (
		<>
			<AlertComponent />
			<LoadingComponent />
			<ModalComponent />
			<ConfirmComponent />
			<div className="input_wrap">
				<div className="input_box">
					<div className="switch_box">
						<label>
							<Switch
								checked={formData.viewStatus}
								onChange={(checked) => {
									setFormData({ ...formData, viewStatus: checked });
								}}
								onColor="#014099"
								offColor="#ccc"
								checkedIcon={false}
								uncheckedIcon={false}
							/>
							<span className='switch_label_txt'>홈페이지 노출</span>
						</label>

						{/* {formData.cateIdx === 9999 && (
							<div className="event_accept_status_box">
								<label className='event_accept_status_box'>
									<Switch
										checked={formData.acceptStatus}
										onChange={(checked) => {
											setFormData({ ...formData, acceptStatus: checked });
										}}
										onColor="#014099"
										offColor="#ccc"
										checkedIcon={false}
										uncheckedIcon={false}
									/>
									<span className='switch_label_txt'>접수버튼 노출</span>
								</label>
							</div>
						)} */}

						{formData.cateIdx !== 9998 ? (
							<>
							<div className="head_sel_wrap">
								<Select
									options={formData.writeTypeOption}
									value={formData.writeType}  // formData.writeType을 그대로 사용
									onChange={(value) => {
										setFormData({ ...formData, writeType: value });
									}} // 선택된 값(value)을 저장
								/>
							</div>
							</>
						):null}
					</div>
					{(formData.cateIdx === 9999 || formData.cateIdx === 2 || formData.cateIdx === 3 || formData.cateIdx === 11) ? (
						<div className="head_sel_wrap flex_between">
							<Select
								options={formData.headCateOptions} // 수정
								value={formData.headCate} // 수정
								onChange={(option) => setFormData({ ...formData, headCate: option })}
							/>
							<div className="input_tit_box">
								<input
									type="text"
									placeholder="제목을 입력하세요"
									className="p_input"
									value={tempFormData.title}
									onChange={handleTitleChange}
									name="title"
									ref={titleInputRef}
								/>
							</div>
						</div>
					) : (
						
						<>
							<div className="input_tit_box">
						 	<input
								type="text"
								placeholder="제목을 입력하세요"
								className="p_input"
								value={tempFormData.title}
								onChange={handleTitleChange}
								name="title"
								ref={titleInputRef}
								/>
							</div>
						</>
					)}
					{formData.cateIdx === 9998 && (
						<div className="input_tit_box">
							<span className='f_bigger f_500' style={{display:'block',padding:'10px 0px'}}>링크</span>
							<input
								type="text"
								placeholder="링크를 입력해주세요"
								className="p_input"
								value={tempFormData.link}
								onChange={handleLinkChange}
								name="link"
								ref={linkInputRef}
							/>
					   </div>
					)}
					{formData.cateIdx === 9999 && (
						<>
							<div className="input_file_txt_box input_event_box display_flex">
								<div className="input_event_place_box display_flex">
									<span className='f_bigger f_500'>장소</span>
									<input
										type="text"
										className='p_input'
										value={tempFormData.place}
										onChange={handlePlaceChange}
										name="place"
										ref={placeInputRef}
									/>
								</div>
								<div className="input_event_date_box display_flex">
									<span className='f_bigger f_500 p_0_5'>일정</span>
									<div className=" display_flex">
										<div className="datePickerWrapper">
											<DatePicker
												className='p_ip p_e_datepicker'
												dateFormat="yyyy-MM-dd"
												showMonthDropdown
												showYearDropdown
												dropdownMode="select"
												selected={isValidDate(formData?.startDateData) ? new Date(formData.startDateData) : null}
												onChange={(date) => handleDateChange(date, 'startDate')}
												placeholderText="0000-00-00"
											/>
											<FaCalendarDay className="calendarIcon" />
										</div>
										<span className='p_0_5'>-</span>
										<div className="datePickerWrapper">
											<DatePicker
												className='p_ip p_e_datepicker'
												dateFormat="yyyy-MM-dd"
												showMonthDropdown
												showYearDropdown
												dropdownMode="select"
												selected={isValidDate(formData?.endDateData) ? new Date(formData.endDateData) : null}
												onChange={(date) => handleDateChange(date, 'endDate')}
												placeholderText="0000-00-00"
											/>
											<FaCalendarDay className="calendarIcon" />
										</div>
									</div>
								</div>
							</div>
							<div className="input_file_txt_box input_event_box display_flex">
								<div className="input_event_date_box display_flex">
									<span className='f_bigger f_500 p_r_5'>접수버튼노출 시간</span>
									<div className=" display_flex">
										<div className="datePickerWrapper">
											<DatePicker
												className='p_ip p_e_datepicker'
												dateFormat="yyyy-MM-dd HH:mm:ss"
												showMonthDropdown
												showYearDropdown
												dropdownMode="select"
												selected={isValidDate(formData?.acceptStartTime) ? new Date(formData.acceptStartTime) : null}
												onChange={(date) => handleAcceptDateChange(date, 'acceptStartTime')}
												placeholderText="0000-00-00 00:00:00"
												showTimeSelect // 시간 선택 가능하게 함
												showTimeSelectOnly={false} // 날짜와 시간 모두 선택 가능하게 함
												timeFormat="HH:mm:ss"
												timeIntervals={1}
												timeCaption="Time"
											/>
											<FaCalendarDay className="calendarIcon" />
										</div>
										<span className='p_0_5'>-</span>
										<div className="datePickerWrapper">
											<DatePicker
												className='p_ip p_e_datepicker'
												dateFormat="yyyy-MM-dd HH:mm:ss"
												showMonthDropdown
												showYearDropdown
												dropdownMode="select"
												selected={isValidDate(formData?.acceptEndTime) ? new Date(formData.acceptEndTime) : null}
												onChange={(date) => handleAcceptDateChange(date, 'acceptEndTime')}
												placeholderText="0000-00-00 00:00:00"
												showTimeSelect // 시간 선택 가능하게 함
												showTimeSelectOnly={false} // 날짜와 시간 모두 선택 가능하게 함
												timeFormat="HH:mm:ss" // 시간, 분, 초 형식 설정
												timeIntervals={1} // 시간 선택 간격을 1분으로 설정 (필요에 따라 조정 가능)
												timeCaption="Time" // 시간 선택의 라벨

											/>
											<FaCalendarDay className="calendarIcon" />
										</div>
									</div>
									{selectedData ? (
										<span 
											className='f_bigger f_500 p_e_distinction'
											style={{ 
												backgroundColor: 
													formData.distinction === 'BEFORE' ? 'blue' :  // 예약: 파란색
													formData.distinction === 'NOW' ? 'red' :     // 모집중: 빨간색
													formData.distinction === 'AFTER' ? 'gray' :  // 마감: 회색
													'transparent' 
											}}
										>
											{formData.distinction === 'BEFORE' ? '예약' : 
											formData.distinction === 'NOW' ? '모집중' : 
											formData.distinction === 'AFTER' ? '마감' : ''}
										</span>
									) : null}
								</div>
							</div>
						</>

					)}
					{formData.cateIdx !== 9998 && (
						<>
						<div className="input_file_box">
							<div className="input_file_txt_box flex_between">
								<span className='f_bigger f_500'>첨부파일</span>
								<div className="input_file_btn_box display_flex">
									<button
										className="input_file_button_del"
										onClick={handleFileDeleteSelected}
										disabled={selectedFiles.length === 0}
									>
										선택 삭제
									</button>
									<label className="input_file_button" htmlFor="input-file">
										파일 업로드
									</label>
									<input
										key={inputKey}
										type="file"
										id="input-file"
										multiple
										onChange={handleFileChange}
										style={{ display: 'none' }}
									/>
								</div>
							</div>
							<div className="file_list">
								{formData?.fileList.length > 0 && formData?.fileList.map((file, index) => (
									<div key={index}>

										<>
											<label className="p_chkbox">
												<input
													type="checkbox"
													id={`file-${index}`}
													checked={selectedFiles.includes(file)}
													onChange={() => handleFileSelect(file)}
												/>
												<span>{file.name}</span>
												<button onClick={() => handleFileDownload(file)}>
													<FaDownload />
												</button>
											</label></>

									</div>
								))}
							</div>
						</div>
						</>
					)}
					{formData.cateIdx !== 1 && formData.cateIdx !== 3 && formData.cateIdx !== 7 && formData.cateIdx !== 8 && formData.cateIdx !== 9 && formData.cateIdx !== 13 && formData.cateIdx !== 14 && formData.cateIdx !== 10 && formData.cateIdx !== 9998 && (
						<div className="input_image_box">
							<div className="input_file_txt_box flex_between">
								<span className='f_bigger f_500'>썸네일</span>
								<div className="input_image_btn_box display_flex">
									<button onClick={handleImageDelete} className="input_file_button_del">
										삭제
									</button>
									<label className="input_file_button" htmlFor="input-image">
										이미지 업로드
									</label>
								</div>
							</div>
							<input
								type="file"
								id="input-image"
								accept="image/*"
								onChange={handleImageChange}
								ref={imageInputRef}
								style={{ display: 'none' }}
							/>
							<div className="image_preview">
								{formData.thumbnail.length > 0 && (
									<img src={formData.thumbnail[0].url} alt={formData.thumbnail[0].name} className="preview_image" />
								)}
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="editor_wrap">
				<div className="ed_wrap">
					<div className="ed_box">
						<div ref={editorRef} />
					</div>
				</div>
				<div className="edit_btn_wrap">
					{selectedData?.resetStatus === false ? (
						<>
							<div className="edit_btn_box_isdata flex_end">
								<button onClick={handleLog} className='edit_btn_bor'>로그</button>
								<button className='edit_btn_ccc' onClick={handleDelete}>삭제</button>
								<button onClick={handleUpdate}>수정</button>
							</div>
						</>
					) : (
						<>
							<div className="edit_btn_box_nondata flex_end">
								<button onClick={handleSave}>등록</button>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default QuillEditor;
