import React, { useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from './MainSlider.module.scss';
import common from '../../common';
import axios from 'axios';
import Switch from 'react-switch'; // react-switch 라이브러리를 사용할 경우

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function MainSlider() {
    const [images, setImages] = useState([]);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [buttonUrl, setButtonUrl] = useState('');  // buttonUrl 상태 추가
    

    const [selectedData, setSelectedData] = useState(null);
    const [status, setStatus] = useState(true);

    const { useAlert,useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/admin_banner/in/get_list`, {}, {
                withCredentials: true
            });

            if (response.data.result === 't') {

                const formattedData = response.data.data.map(item => ({
                    id: item.idx.toString(),
                    src: item.thumbnailPath,
                    statusName: item.statusName,
                    buttonUrl: item.buttonUrl
                }));
                setImages(formattedData);
                if (formattedData.length > 0) {
                    setStatus(formattedData[0].statusName === 'Y');
                }
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const newImages = Array.from(images);
        const [removed] = newImages.splice(result.source.index, 1);
        newImages.splice(result.destination.index, 0, removed);

        const updatedSeqData = newImages.map((image, index) => ({
            idx: image.id,
            seq: index + 1
        }));
    
        // console.log(updatedSeqData);
        setImages(newImages.map((image, index) => ({
            ...image,
            seq: index + 1
        })));

        sendSequenceData(updatedSeqData);
    };

    const sendSequenceData = async (updatedSeqData) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/admin_banner/in/change_seq`, {banner_pack : updatedSeqData}, {
                withCredentials: true
            });
            if (response.data.result === 't') {
                pAlert('순서 업데이트가 완료되었습니다.');
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            pAlert('순서 업데이트 실패');
        }
    };

    const imageInputRef = useRef(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
                if (selectedData) {
                    setSelectedData({ ...selectedData, src: reader.result });
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageDelete = () => {
        setImagePreviewUrl(null);
        if (imageInputRef.current) {
            imageInputRef.current.value = '';
        }
        if (selectedData) {
            setSelectedData({ ...selectedData, src: null });
        }
    };

    const handleSwitchChange = (checked) => {
        setStatus(checked);
    };

    const handleImageClick = (image) => {
        setImagePreviewUrl(image.src);
        setSelectedData(image);
        setStatus(image.statusName === 'Y');
    };

    const handleBack = () => {
        setImagePreviewUrl(null);
        setSelectedData(null);
        setButtonUrl('');
        setStatus(true);
    };

    const handleButtonUrlChange = (e) => {
        const newValue = e.target.value;
        if (selectedData) {
            setSelectedData({ ...selectedData, buttonUrl: newValue }); // selectedData가 있을 때
        } else {
            setButtonUrl(newValue); // selectedData가 없을 때
        }
    };

    const handleSave = () => {
        if (selectedData) {
            // const { id } = selectedData;
            const data = {
                target_idx: selectedData.id,
                status_name: status ? 'Y' : 'N',
                buttonUrl : selectedData.buttonUrl
            };
            sendUpdateStatusData(data);
        }
    };

    const sendUpdateStatusData = async (newData) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/admin_banner/in/change_status`, newData, {
                withCredentials: true
            });
            if (response.data.result === 't') {
                pAlert('수정이 완료되었습니다.')
                fetchData();
                handleBack();
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            pAlert('배너 상태 업데이트 실패');
        } 
    };

    const handleInsert = () => {
        if (imagePreviewUrl) {  // 이미지가 존재할 때만 실행
            sendInsertImgData(imagePreviewUrl, buttonUrl); 
        } else {
            pAlert('이미지를 업로드하세요.');  
        }
    };

    const sendInsertImgData = async (newData, buttonUrl) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/admin_banner/in/insert_img`, {
                img: newData,
                buttonUrl: buttonUrl  // 버튼 URL을 함께 전송
            }, {
                withCredentials: true
            });
            
            if (response.data.result === 't') {
                pAlert('이미지 추가가 완료되었습니다.');
                fetchData();
                handleBack();
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            pAlert('이미지 추가를 실패하였습니다.');
        }
    };

    
    const sendDeleteImgData = async (targetIDX) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/admin_banner/in/delete_img`, {target_idx : targetIDX} , {
                withCredentials: true
            });
            if (response.data.result === 't') {
                pAlert('삭제가 완료되었습니다.');
                fetchData();
                handleBack();
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            pAlert('이미지 삭제 실패');
        }
    };

    const checkFunction = (targetIDX) => {
        sendDeleteImgData(targetIDX);
    };


    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <ConfirmComponent />
            <div className={styles.components_wrap}>
                <div className={styles.web_mainSlider_wrap}>
                    <p className={styles.page_title}>메인슬라이드</p>
                    <div className={styles.cm_table_wrap}>
                        <div className={styles.web_mainSlider_box}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="images" direction="vertical">
                                    {(provided) => (
                                        <div
                                            className={styles.imageContainer}
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {images.map((image, index) => (
                                                <div key={image.id} className={styles.imageWrapper}>
                                                    <Draggable key={image.id} draggableId={image.id} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                className={styles.imageItem}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={{
                                                                    backgroundImage: `url(${image.src})`,
                                                                    ...provided.draggableProps.style,
                                                                }}
                                                                onClick={() => handleImageClick(image)}
                                                            >
                                                                <span className={styles.imageIndex}>순서 : {index + 1}</span>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                        <div className={`${styles.main_slider_table_detail_box} table_detail_box`}>
                            
                            <div className={`${styles.switch_box}`}>
                                <label>
                                    <Switch
                                        checked={selectedData ? status : false}
                                        onChange={handleSwitchChange}
                                        onColor="#014099"
                                        offColor="#ccc"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                    />
                                    <span className='switch_label_txt'>홈페이지 노출</span>
                                </label>
                                <div className={styles.slide_input_tit_box}>
                                    <span>버튼 링크</span>
                                    <input 
                                        type="text" 
                                        placeholder="버튼 링크를 입력해주세요. 링크가 없으면 빈값으로 넣어주세요." 
                                        className="p_input" 
                                        name="title" 
                                        value={selectedData ? selectedData.buttonUrl : buttonUrl} // selectedData가 존재할 때와 없을 때를 처리
                                        onChange={handleButtonUrlChange}
                                    />
                                </div>
                            </div>
                            
                            <div className={`${styles.input_image_box} input_image_box`}>
                                <div className="input_file_txt_box flex_between">
                                    <span className='f_bigger f_500'>썸네일</span>
                                    <div className="input_image_btn_box display_flex">
                                        {!selectedData ? (
                                            <>
                                            <button onClick={handleImageDelete} className="input_file_button_del">
                                                    삭제
                                                </button>
                                                <label className="input_file_button" htmlFor="input-image">
                                                    이미지 업로드
                                                </label>
                                            </>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <input
                                    type="file"
                                    id="input-image"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    ref={imageInputRef}
                                    style={{ display: 'none' }}
                                />
                                <div className={styles.image_preview}>
                                    {imagePreviewUrl && (
                                        <div
                                            className={styles.preview_image}
                                            style={{ backgroundImage: `url(${imagePreviewUrl})` }}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className={`${styles.edit_btn_wrap} edit_btn_wrap flex_end`}>
                                {selectedData ? (
                                    <>
                                        <button className='p_detail_btn p_detail_clear_btn' onClick={handleBack}>뒤로가기</button>
                                        <button className='p_detail_btn p_detail_etc_btn' onClick={() => pConfirm('이미지 삭제', '메인 배너를 삭제하시겠습니까?', '500px','auto', () => checkFunction(selectedData.id))}>삭제</button>
                                        <button className='p_detail_btn p_detail_update_btn' onClick={handleSave}>수정</button>
                                    </>
                                ) : (
                                    <>
                                        <button className='p_detail_btn p_detail_regist_btn' onClick={handleInsert}>등록</button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MainSlider;
