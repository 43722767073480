import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from './Organization.module.scss';
import common from '../../common';
import axios from 'axios';
import { IoAddCircleOutline, IoCloseCircleOutline } from 'react-icons/io5';
import { DataTable } from "../../components";

// API 베이스 URL 설정
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// 초기 데이터 설정
const initialData = {
    tasks: {},
    columns: {},
    columnOrder: []
};

//조직관리 컨펌 컨텐츠
const DeptContent = ({ depts }) => {
    const { useAlert } = common();
    const { pAlert, AlertComponent } = useAlert();

    const handleAddDept = () => {
        if (newDept) {
            sendDeptInsertData(newDept);
        } else {
            pAlert('부서를 입력해주세요.');
        }
    };

    // 부서 삭제 함수
    const handleDeleteDept = (idx) => {
        sendDeptDeleteData(idx);
    };
    const [newDept, setNewDept] = useState(''); // 새로운 부서 입력 상태

    // 새 부서 추가하기
    const sendDeptInsertData = async (newDept) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/admin_organization/in/organization_dept_insert`, { dept: newDept }, {
                withCredentials: true
            });
            if (response.data.result === 't') {
                pAlert('기추가가 완료되었습니다. 확인 버튼을 눌러주세요.');
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            pAlert('기준정보 부서 추가 실패');
        }
    };

    // 부서 삭제하기
    const sendDeptDeleteData = async (idx) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/admin_organization/in/organization_dept_delete`, { target_idx: idx }, {
                withCredentials: true
            });
            if (response.data.result === 't') {
                pAlert('삭제가 완료되었습니다. 확인 버튼을 눌러주세요.');
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            pAlert('기준정보 부서 삭제 실패');
        }
    };

    return (
        <>
            <AlertComponent />
            <div className={styles.deptListContainer}>
                {depts.map((deptData) => (
                    <div key={deptData.idx} className={styles.deptItem}>
                        <span>{deptData.dept}</span>
                        <button className={styles.dept_delete_btn} onClick={() => handleDeleteDept(deptData.idx)}>삭제</button>
                    </div>
                ))}
            </div>
            <div className={styles.addDeptContainer}>
                <input
                    type="text"
                    value={newDept}
                    onChange={(e) => setNewDept(e.target.value)}
                    placeholder="부서를 입력하세요"
                />
                <button onClick={handleAddDept}>추가</button>
            </div>
        </>
    );
};

// 연도관리 컨펌 컨텐츠
const YearContent = ({ years }) => {
    const { useAlert } = common();
    const { pAlert, AlertComponent } = useAlert();

    const handleAddYear = () => {
        if (newYear) {
            sendYearInsertData(newYear);
        } else {
            pAlert('연도를 입력해주세요.');
        }

    };

    // 연도 삭제 함수
    const handleDeleteYear = (idx) => {
        sendYearDeleteData(idx);
    };
    const [newYear, setNewYear] = useState(''); // 새로운 연도 입력 상태

    // 새 조직연도 추가하기
    const sendYearInsertData = async (newYear) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/admin_organization/in/organization_year_insert`, { year: newYear }, {
                withCredentials: true
            });
            if (response.data.result === 't') {
                pAlert('추가가 완료되었습니다. 확인 버튼을 눌러주세요.');
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            pAlert('기준정보 연도 추가 실패');
        }
    };

    // 새 조직연도 삭제하기
    const sendYearDeleteData = async (idx) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/admin_organization/in/organization_year_delete`, { target_idx: idx }, {
                withCredentials: true
            });
            if (response.data.result === 't') {
                pAlert('삭제가 완료되었습니다. 확인 버튼을 눌러주세요.');
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            pAlert('기준정보 연도 삭제 실패');
        }
    };

    return (
        <>
            <AlertComponent />
            <div className={styles.yearListContainer}>
                {years.map((yearData) => (
                    <div key={yearData.idx} className={styles.yearItem}>
                        <span>{yearData.year}</span>
                        <button className={styles.year_delete_btn} onClick={() => handleDeleteYear(yearData.idx)}>삭제</button>
                    </div>
                ))}
            </div>
            <div className={styles.addYearContainer}>
                <input
                    type="number"
                    value={newYear}
                    onChange={(e) => setNewYear(e.target.value)}
                    placeholder="연도를 입력하세요"
                />
                <button onClick={handleAddYear}>추가</button>
            </div>
        </>
    );
};


// 회원추가 컨펌 컨텐츠
const MemberContent = ({ year, dept, deptName }) => {
    const { useAlert } = common();
    const { pAlert, AlertComponent } = useAlert();

    const dataTableRef = useRef(null); // 등록 시 데이터테이블 리로드를 위한 함수

    const columnDefs = useMemo(() => [
        { field: 'tableType', headerName: 'tableType' },
        { field: "idx", headerName: "번호" },
        { field: "name", headerName: "이름" },
        { field: "major", headerName: "학과" },
        { field: "university", headerName: "학교" },
        { field: "gradeName", headerName: "등급" },
    ], []);

    const tableLoadData = {
        tableType: 'UserPopup',
    };

    return (
        <>
            <AlertComponent />
            <div className={styles.organization_table_wrap}>
                <span className={styles.member_conf_title}>체크 후 선택 회원 추가를 누르면 {deptName} 추가가 완료됩니다.</span>
                <div className={styles.user_table_box}>
                    <DataTable
                        columnDefs={columnDefs}
                        ref={dataTableRef}  // ref 전달
                        tableLoadData={tableLoadData}  // DataTable에 전달할 값
                        UserPopupYear={year}
                        UserPopupDept={dept}
                        UserPopupDeptName={deptName}
                    />
                </div>
            </div>
        </>
    );
};




const Organization = () => {
    const { useAlert, useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent } = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    const [state, setState] = useState(initialData); // 상태 관리
    const [depts, setDepts] = useState([]); // 부서 리스트 상태
    const [years, setYears] = useState([]); // 연도 리스트 상태
    const [yearTargetIdx, setYearTargetIdx] = useState(39); // 초기 연도 설정



    // 조직 확인 함수
    const deptFunction = () => {
        // console.log(yearTargetIdx)
        fetchOrganizationData(yearTargetIdx, depts);
        fetchOrganizationDataDept();
    };
    // 연도 확인 함수
    const yearFunction = () => {
        // console.log(yearTargetIdx)
        fetchOrganizationData(yearTargetIdx, depts);
        fetchOrganizationYear(yearTargetIdx);
    };
    // 회원 추가 확인 함수
    const memberInsertFunction = () => {
        // console.log(yearTargetIdx)
        fetchOrganizationData(yearTargetIdx, depts);
    };

    // 멤버 리스트를 서버에서 가져오는 함수
    const fetchMemberList = useCallback(async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/admin_member/in/get_table`, {}, {
                withCredentials: true
            });

            if (response.data.result === 't') {
                const data = response.data.data;
                // console.log('멤버리스트 : ', data);
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            pAlert('실패 error 001');
        } finally {
            pLoadingOff();
        }
    }, [pAlert, pLoadingOn, pLoadingOff]);

    // 조직 데이터를 서버에서 가져오는 함수
    const fetchOrganizationData = useCallback(async (yearTargetIdx, deptData) => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/admin_organization/in/get_organization_detail`, { target_idx: yearTargetIdx }, {
                withCredentials: true
            });

            if (response.data.result === 't') {
                const data = response.data.data;
                // console.log('부서데이터 : ', data);
                const tasks = {};
                const columns = { ...state.columns };

                // 부서 데이터를 columns에 추가
                deptData.forEach((dept) => {
                    columns[dept.idx] = {
                        id: dept.idx,
                        title: dept.dept,
                        taskIds: []
                    };
                });

                // 부서에 속한 멤버 데이터를 tasks에 추가
                data.forEach((dept) => {
                    if (columns[dept.deptIDX]) {
                        const taskIds = dept.list.map((item, index) => {
                            const taskId = `task-${item.deptIDX}-${item.name}`;
                            tasks[taskId] = {
                                id: taskId,
                                idx: item.idx,
                                memberIDX: item.memberIDX,
                                name: item.name,
                                position: item.position,
                                university: item.university,
                                seq: index + 1
                            };
                            return taskId;
                        });
                        columns[dept.deptIDX] = {
                            ...columns[dept.deptIDX],
                            taskIds
                        };
                    }
                });

                setState({
                    tasks,
                    columns,
                    columnOrder: deptData.map(dept => dept.idx)
                });
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            pAlert('실패 error 002');
        } finally {
            pLoadingOff();
        }
    }, [pAlert, pLoadingOn, pLoadingOff, state.columns]);

    // 부서 데이터를 서버에서 가져오는 함수
    const fetchOrganizationDataDept = useCallback(async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/admin_organization/in/get_organization_dept_list`, {}, {
                withCredentials: true
            });

            if (response.data.result === 't') {
                const data = response.data.data;
                // console.log('Dept데이터 : ', data);
                setDepts(data); // 부서 리스트 상태 업데이트
                fetchOrganizationData(yearTargetIdx, data); // 전달된 deptData 사용
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            pAlert('실패 error 003');
        } finally {
            pLoadingOff();
        }
    }, [pAlert, pLoadingOn, pLoadingOff, fetchOrganizationData, yearTargetIdx]);

    // 연도 데이터를 서버에서 가져오는 함수
    const fetchOrganizationYear = useCallback(async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/admin_organization/in/get_organization_year`, {}, {
                withCredentials: true
            });

            if (response.data.result === 't') {
                const data = response.data.data;
                data.sort((a, b) => parseInt(a.year) - parseInt(b.year));
                // console.log('Year데이터 : ', data);

                // 현재 연도 설정
                const currentYear = new Date().getFullYear();
                const currentYearData = data.find(yearData => yearData.year === currentYear);
                const currentYearIDX = currentYearData ? currentYearData.idx : 39; // 일치하는 데이터가 없으면 기본값 39

                // 상태 업데이트
                setYears(data);
                setYearTargetIdx(currentYearIDX);
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            pAlert('실패 error 004');
        } finally {
            pLoadingOff();
        }
    }, [pAlert, pLoadingOn, pLoadingOff]);


    // 컴포넌트가 마운트될 때 초기 데이터 가져오기
    useEffect(() => {
        fetchMemberList();
        fetchOrganizationDataDept();
        fetchOrganizationYear();
    }, []);

    // 드래그 종료 시 호출되는 함수
    const onDragEnd = (result) => {
        const { destination, source, draggableId, type } = result;
    
        // 목적지가 없는 경우
        if (!destination) {
            return;
        }
    
        // 같은 위치로 드래그한 경우
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }
    
        // 컬럼을 드래그 앤 드롭하는 경우
        if (type === "column") {
            const newColumnOrder = Array.from(state.columnOrder);
            // 컬럼의 현재 위치에서 제거
            newColumnOrder.splice(source.index, 1);
            // 새로운 위치에 컬럼 추가
            newColumnOrder.splice(destination.index, 0, draggableId);
    
            // 상태 업데이트
            setState((prevState) => ({
                ...prevState,
                columnOrder: newColumnOrder,
            }));



            const newDepts = Array.from(depts);
            const [movedDept] = newDepts.splice(source.index, 1);
            newDepts.splice(destination.index, 0, movedDept);
            // 업데이트된 순서를 반영하여 seq 값을 재설정
            const updatedDepts = newDepts.map((dept, index) => ({ ...dept, seq: index + 1 }));

            setDepts(updatedDepts);
            return;
        }
    
        // 태스크를 드래그 앤 드롭하는 경우
        const start = state.columns[source.droppableId];
        const finish = state.columns[destination.droppableId];
    
        if (start === finish) {
            // 같은 컬럼 내에서의 태스크 이동
            const newTaskIds = Array.from(start.taskIds);
            // 태스크를 기존 위치에서 제거
            newTaskIds.splice(source.index, 1);
            // 새로운 위치에 태스크 추가
            newTaskIds.splice(destination.index, 0, draggableId);
    
            const newColumn = {
                ...start,
                taskIds: newTaskIds,
            };
    
            const newTasks = newTaskIds.reduce((acc, taskId, idx) => {
                acc[taskId] = { ...state.tasks[taskId], seq: idx + 1 };
                return acc;
            }, {});
    
            // 상태 업데이트
            setState((prevState) => ({
                ...prevState,
                columns: {
                    ...prevState.columns,
                    [newColumn.id]: newColumn,
                },
                tasks: {
                    ...prevState.tasks,
                    ...newTasks,
                },
            }));
            return;
        }
    
        // 다른 컬럼으로 태스크 이동
        const startTaskIds = Array.from(start.taskIds);
        // 시작 컬럼에서 태스크 제거
        startTaskIds.splice(source.index, 1);
        const newStart = {
            ...start,
            taskIds: startTaskIds,
        };
    
        const finishTaskIds = Array.from(finish.taskIds);
        // 종료 컬럼에 태스크 추가
        finishTaskIds.splice(destination.index, 0, draggableId);
        const newFinish = {
            ...finish,
            taskIds: finishTaskIds,
        };
    
        const newTasks = {
            ...state.tasks,
            ...finishTaskIds.reduce((acc, taskId, idx) => {
                acc[taskId] = { ...state.tasks[taskId], seq: idx + 1 };
                return acc;
            }, {}),
        };
    
        // 상태 업데이트
        setState((prevState) => ({
            ...prevState,
            columns: {
                ...prevState.columns,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish,
            },
            tasks: newTasks,
        }));
    };


    // 연도 버튼 클릭 시 호출되는 함수
    const handleYearButtonClick = (idx) => {
        setYearTargetIdx(idx);
        fetchOrganizationData(idx, depts); // 현재 depts 사용
    };

    // 입력 필드 변경 시 호출되는 함수
    const handleInputChange = (e, taskId, field) => {
        const newText = e.target.value || '';
        setState(prevState => ({
            ...prevState,
            tasks: {
                ...prevState.tasks,
                [taskId]: {
                    ...prevState.tasks[taskId],
                    [field]: newText
                }
            }
        }));
    };

    // 업데이트 클릭 함수
    const clickFunction = async () => {
        const output = {
            year_idx: yearTargetIdx, // 선택된 연도의 idx 추가
            data_pack: state.columnOrder.map(columnId => {
                const column = state.columns[columnId];
                const tasks = column.taskIds.map(taskId => {
                    const task = state.tasks[taskId];
                    return {
                        idx: task.idx,
                        dept_idx: parseInt(columnId),
                        name: task.name,
                        position: task.position,
                        university: task.university,
                        seq: task.seq,
                        member_idx: task.memberIDX
                    };
                });
                return tasks;
            }).flat(),
            dept_pack: depts.map(dept => ({ idx: dept.idx, seq: dept.seq }))


        };
        // console.log(output);

        pConfirm(
			'수정',
			<>
				<div>최종수정을 하시겠습니까?</div>
			</>,
			'400px',
			'auto',
			async () => {
                try {
                    pLoadingOn();
                    const response = await axios.post(`${API_BASE_URL}/admin_organization/in/organization_edit`, output, {
                        withCredentials: true
                    });
                    if (response.data.result === 't') {
                        fetchOrganizationData(yearTargetIdx, depts); // 현재 depts 사용
                        pAlert('수정이 완료되었습니다.');
                    } else {
                        pAlert(response.data.msg);
                    }
                } catch (error) {
                    console.error('Data sending failed:', error);
                    pAlert('데이터 전송에 실패했습니다. 다시 시도해주세요.');
                } finally {
                    pLoadingOff();
                }
			}
		);

       


    };

    const memberDeleteFunction = async (idx, member_idx) => {

        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/admin_organization/in/organization_delete`, { target_idx: idx }, {
                withCredentials: true
            });
            if (response.data.result === 't') {
                fetchOrganizationData(yearTargetIdx, depts); // 현재 depts 사용
                pAlert('삭제가 완료되었습니다.');
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('Data sending failed:', error);
            pAlert('데이터 전송에 실패했습니다. 다시 시도해주세요.');
        } finally {
            pLoadingOff();
        }
    }


    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <ConfirmComponent />
            <div className={styles.components_wrap}>
                <div className={styles.components_top_wrap}>
                    <div className={styles.components_top_ri_wrap}>
                        <p className={styles.page_title}>조직 구성</p>
                        <div className={styles.yearSelector}>
                            {years.slice().reverse().map(({ year, idx }) => (
                                <button
                                    key={idx}
                                    className={`${styles.yearButton} ${yearTargetIdx === idx ? styles.selected : ''}`}
                                    onClick={() => handleYearButtonClick(idx)}
                                >
                                    {year}년
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className={styles.components_top_le_wrap}>

                        <div className={styles.update_btn_box}>
                            <button className={`${styles.update_btn} ${styles.dept_btn}`} onClick={() => pConfirm('조직관리', <DeptContent depts={depts} />, '1200px', 'auto', deptFunction)}>조직관리</button>
                            <button className={`${styles.update_btn} ${styles.year_btn}`} onClick={() => pConfirm('연도관리', <YearContent years={years} />, '1200px', 'auto', yearFunction)}>연도관리</button>
                        </div>
                    </div>
                </div>

                <div className={styles.cm_table_wrap}>
<DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="all-columns" direction="horizontal" type="column">
        {(provided) => (
            <div
                className={styles.board}
                {...provided.droppableProps}
                ref={provided.innerRef}
            >
                {state.columnOrder.map((columnId, index) => {
                    const column = state.columns[columnId];
                    const tasks = (column.taskIds || []).map(taskId => state.tasks[taskId]);

                    return (
                        // Column Draggable
                        <Draggable key={String(column.id)} draggableId={String(column.id)} index={index}>
                            {(provided) => (
                                <div
                                    className={styles.organization_column}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                    <div className={styles.dept_title_box}>
                                        <span className={styles.dept_title}>{column.title}</span>
                                        <button
                                            className={styles.member_insert_btn}
                                            onClick={() =>
                                                pConfirm(
                                                    `${column.title}`,
                                                    <MemberContent
                                                        dept={columnId}
                                                        year={yearTargetIdx}
                                                        deptName={column.title}
                                                    />,
                                                    '640px',
                                                    '570px',
                                                    memberInsertFunction
                                                )
                                            }
                                        >
                                            <IoAddCircleOutline style={{ fontSize: '1.2rem', marginRight: '8px' }} />
                                        </button>
                                    </div>
                                    {/* Task Droppable */}
                                    <Droppable droppableId={String(column.id)} type="task">
                                        {(provided) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                className={styles.droppableArea}
                                            >
                                                {tasks.map((task, index) => (
                                                    // Task Draggable
                                                    <Draggable key={String(task.id)} draggableId={String(task.id)} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                className={styles.organization_task}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <div className={styles.taskInputContainer}>
                                                                    <div className={styles.member_delete_btn_box}>
                                                                        <button
                                                                            className={styles.member_delete_btn}
                                                                            onClick={() =>
                                                                                pConfirm(
                                                                                    '삭제',
                                                                                    '해당 회원을 삭제하시겠습니까?',
                                                                                    '350px',
                                                                                    'auto',
                                                                                    () => memberDeleteFunction(task.idx, task.memberIDX)
                                                                                )
                                                                            }
                                                                        >
                                                                            <IoCloseCircleOutline />
                                                                        </button>
                                                                    </div>
                                                                    <input
                                                                        type="text"
                                                                        className={styles.itemText}
                                                                        value={task.name || ''}
                                                                        readOnly
                                                                    />
                                                                    <input
                                                                        type="text"
                                                                        className={styles.itemText}
                                                                        value={task.position || ''}
                                                                        onChange={(e) => handleInputChange(e, task.id, 'position')}
                                                                    />
                                                                    <input
                                                                        type="text"
                                                                        className={styles.itemText}
                                                                        value={task.university || ''}
                                                                        onChange={(e) => handleInputChange(e, task.id, 'university')}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            )}
                        </Draggable>
                    );
                })}
                {provided.placeholder}
            </div>
        )}
    </Droppable>
</DragDropContext>
                </div>
                <div className={styles.final_btn_box}>
                    <button className={`${styles.update_btn} ${styles.result_btn}`} onClick={clickFunction}>최종수정</button>
                </div>
            </div>
        </>
    );
};

export default Organization;
