// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './Login.module.scss';
import common from '../../common';




import { useDispatch } from 'react-redux';
import { setLoginInfo } from '../../store/userSlice';



const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function setCookie(name, value, hours) {
    const d = new Date();
    d.setTime(d.getTime() + (hours * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + encodeURIComponent(value) + ";" + expires + ";path=/";
}

function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { useAlert, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert(); // 훅 사용
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    
    const dispatch = useDispatch();


    const handleSubmit = async (e) => {
        e.preventDefault();

        // 이메일과 비밀번호 유효성 검사
        if (!email.trim()) {
            pAlert('이메일을 입력해주세요.');
            return;
        }
        if (!password.trim()) {
            pAlert('비밀번호를 입력해주세요.');
            return;
        }

        try {
            pLoadingOn();

            const response = await axios.post(`${API_BASE_URL}/admin_login/out/login`, { email, password }, {
                withCredentials: true
            });
            if (response.data.result === 't') {
                const { userName, idx, admin_grade_idx } = response.data; // 서버에서 받은 userName, idx, admin_grade_idx

                setCookie('userName', userName, 8); // 쿠키에 userName 저장, 1일 동안 유효

                dispatch(setLoginInfo({ idx, adminGradeIdx: admin_grade_idx })); // Redux에 로그인 정보 및 admin_grade_idx 저장Redux에 로그인 정보 저장

                navigate('/event'); // 로그인 성공 시 메인 페이지로 리디렉트
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('Login failed:', error);
            pAlert('로그인에 실패했습니다. 다시 시도해주세요.');
        } finally {
            pLoadingOff();  // 로딩 종료
        }
    };

    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <div className={styles.login_wrap}>
                <div className={styles.login_box}>
                    <div className={styles.lg_logo_box}>
                        <img src="/img/logo.png" alt="logo" />
                    </div>
                    <div className={styles.lg_input_wrap}>
                        <div className={styles.input_tit_txt}>
                            <span className="f_big">어드민 로그인</span>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className={styles.lg_input_box}>
                                <input
                                    type="text"
                                    autoFocus
                                    placeholder='Email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className={styles.lg_input_box}>
                                <input
                                    type="password"
                                    placeholder='Password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className={styles.lg_btn_box}>
                                <button type="submit">
                                    <span className="f_bigger f_400 f_white">
                                        로그인
                                    </span>
                                </button>
                            </div>
                        </form>
                        <span className={`f_normal ${styles.cont_txt}`}>
                            계정정보를 분실했을 경우 NAEK로 문의 바랍니다.
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;