import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';  // react-icons에서 아이콘 가져오기

function Block({ message = `잘못된 경로로 접근 하셨거나, 사용할 수 없는 웹사이트 주소입니다.
입력하신 페이지 주소를 한번 확인 후 올바른 주소로 서비스를 이용해 주십시오.`}) {
    const handleBack = () => {
        window.history.back();
    };
    return (
        <>
            <div className="components_wrap">
                <div className="block_container">
                    <div className="block_content">
                        <div className="block_logo"></div>
                        <div className="top_content_txt">
                            <h1>Not Found</h1>
                            <span>페이지를 찾을 수 없습니다.</span>
                        </div>
                        <div className="bottom_content_txt">
                            <span>{message}</span>
                            <button className="block_back_button" onClick={handleBack}><FaArrowLeft icon="fa-solid fa-left-long" className="back_icon" />뒤로가기</button>
                        </div>
                    </div>

                </div>
            </div>
        </>

    );
}

export default Block;