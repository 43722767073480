import React, { useState, useEffect } from 'react';
import axios from 'axios';
import common from '../../common';


import DatePicker from 'react-datepicker';
import { FaCalendarDay } from 'react-icons/fa';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function DataTableModal({ tableType, gradeSelectedData , onUpdate, data  }) {

    // 공통
    const { useAlert, useLoading, useConfirm, useDatePicker } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
    const { pConfirm, ConfirmComponent } = useConfirm();








    // -------------------------------- UserGrade --------------------------------
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [textareaValue, setTextareaValue] = useState('');
    const [selectedGrade, setSelectedGrade] = useState(1);
    const [userGradeContent, setUserGradeContent] = useState(data || []);
    
    useEffect(() => {
        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        const grade_pack = {
            member_idx_pack: gradeSelectedData.map(item => item.idx),
            base_date: formatDate(selectedDate),
            leave_reason: textareaValue.toString(),
            grade_name_idx: selectedGrade
        };
        
        // 상위 컴포넌트에 데이터 전달
        if (onUpdate) {
            onUpdate(grade_pack);
        }
    }, [selectedDate, textareaValue, selectedGrade, onUpdate]);
    // -------------------------------- UserGrade --------------------------------
    

    return (
        <div className="p_dataTable_modal_wrap">
            <div className="p_dataTable_modal_info_box">
                <div className="p_dataTable_modal_box_title">날짜</div>
                <div className="datePickerWrapper">
                    <DatePicker
                        selected={selectedDate}
                        dateFormat="yyyy-MM-dd"
                        onChange={date => setSelectedDate(date)}
                        className="pDatePicker" />
                    <FaCalendarDay className="calendarIcon" />
                </div>
            </div>
            <div className="p_dataTable_modal_info_box">
                <div className="p_dataTable_modal_box_title">등급</div>
                <select style={{ width: '100%' }} value={parseInt(selectedGrade)} onChange={(e) => setSelectedGrade(parseInt(e.target.value))}>
                    {userGradeContent.map((item) => (
                        <option key={item.idx} value={item.idx}>{item.name}</option>
                    ))}
                </select>
            </div>
            <div className="p_dataTable_modal_info_box">
                <div className="p_dataTable_modal_box_title">회원자격 상실사유</div>
                {selectedGrade === 4 && (
                    <>
                        <textarea
                            style={{ width: '100%' }}
                            value={textareaValue}
                            onChange={(e) => setTextareaValue(e.target.value)}
                            rows="4"
                            cols="50"
                        />
                    </>
                )}
            </div>
        </div>
    );
}

export default DataTableModal;