import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';
import common from './common';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}. ${month}. ${day} ${hours}:${minutes}:${seconds}`;
};

// 쿠키에서 값을 가져오는 함수
function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        if (c.indexOf(nameEQ) === 0) {
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
    }
    return null;
}

function deleteCookie(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

function Header() {
    const { useAlert, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert(); // 훅 사용
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    const navigate = useNavigate();
    const [currentTime, setCurrentTime] = useState(formatDate(new Date()));
    const location = useLocation();
    const [activeKey, setActiveKey] = useState(null);
    const [maxHeight, setMaxHeight] = useState(0);
    const [userName, setUserName] = useState('');

    useEffect(() => {
        const rawUserName = getCookie('userName');
        setUserName(rawUserName);
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(formatDate(new Date()));
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        const savedKey = localStorage.getItem('activeAccordionKey');
        if (savedKey) {
            setActiveKey(savedKey);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('activeAccordionKey', activeKey);
        if (activeKey !== null) {
            setMaxHeight(500);
        } else {
            setTimeout(() => setMaxHeight(0), 300);
        }
    }, [activeKey]);

    const handleAccordionClick = (key) => {
        setActiveKey(prevKey => (prevKey === key ? null : key));
    };

    const handleLogout = async (e) => {
        e.preventDefault();
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/admin_login/out/logout`, {}, {
                withCredentials: true
            });
            if (response.data.result === 't') {
                deleteCookie('userName'); // 유저네임 쿠키 삭제
                navigate('/login');
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('Logout failed:', error);
            pAlert('로그아웃에 실패했습니다. 다시 시도해주세요.');
        } finally {
            pLoadingOff();  // 로딩 종료
        }
    };

    return (
        <>
            <AlertComponent />
            <LoadingComponent />

            <div className={`header_wrap`}>
                <div className="flex_between header">
                    <div className="hd_left_box display_flex">
                        <div className="hd_logo_img_box display_flex">
                            <img src="/img/logo_w.png" alt="" />
                        </div>
                        <span className='hd_user_name_txt f_normal f_500'>[{userName}]</span>
                        <span className='hd_user_txt f_normal f_white f_300' >&nbsp;님 환영합니다.</span>
                    </div>
                    <div className="hd_right_box display_flex">
                        <div className="hd_time_box">
                            <span className='f_big f_white'>{currentTime}</span>
                        </div>
                        <a href="http://www.yehs.or.kr/" target="_blank" rel="noopener noreferrer" className="go_web_btn hd_btn">
                            <span className="f_small f_500">웹페이지</span>
                        </a>
                        <a href="/" onClick={handleLogout} className="logout_btn hd_btn">
                            <span className="f_small">로그아웃</span>
                        </a>
                    </div>
                </div>
                <nav>
                    <Accordion activeKey={activeKey}>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header
                                aria-expanded={activeKey === "1"}
                                className={`accordion-button ${activeKey === "1" || activeKey === null ? "expanded" : "collapsed"}`}
                                onClick={() => handleAccordionClick("1")}
                            >
                                <Link className='f_big f_black f_400' to={'/event'}>
                                    <span className={`nav_1_btn ${activeKey === "1" || activeKey === null ? "active" : ""} f_big f_white f_400`}>행사 관리</span>
                                    <span className='nav_1_icon'></span>
                                </Link>
                            </Accordion.Header>
                            <Accordion.Body
                                className={activeKey === "1" ? "show" : ""}
                            >
                                <Link className={`nav_2_btn ${location.pathname === '/event' || location.pathname === '/' ? 'active' : ''}`} to={'/event'}>
                                    <span className='f_big f_black f_400'>행사 목록</span>
                                </Link>
                                <Link className={`nav_2_btn ${location.pathname === '/eventList' ? 'active' : ''}`} to={'/eventList'}>
                                    <span className='f_big f_black f_400'>행사 정리</span>
                                </Link>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header
                                aria-expanded={activeKey === "2"}
                                className={`accordion-button ${activeKey === "1" ? "expanded" : "collapsed"}`}
                                onClick={() => handleAccordionClick("2")}
                            >
                                <Link className="f_big f_black f_400" to={'/user'}>
                                    <span className={`nav_1_btn ${activeKey === "1" ? "active" : ""} f_big f_white f_400`}>회원 관리</span>
                                    <span className='nav_1_icon'></span>
                                </Link>
                            </Accordion.Header>
                            <Accordion.Body
                                className={activeKey === "2" ? "show" : ""}
                            >
                                <Link className={`nav_2_btn ${location.pathname === '/user' || location.pathname === '/userDetail' ? 'active' : ''}`} to={'/user'}>
                                    <span className='f_big f_black f_400'>회원 정보</span>
                                </Link>
                                <Link className={`nav_2_btn ${location.pathname === '/userGrade' || location.pathname === '/userGradeDetail' ? 'active' : ''}`} to={'/userGrade'}>
                                    <span className='f_big f_black f_400'>회원 등급</span>
                                </Link>
                                <Link className={`nav_2_btn ${location.pathname === '/forum' || location.pathname === '/forumDetail' ? 'active' : ''}`} to={'/forum'}>
                                    <span className='f_big f_black f_400'>포럼 목록</span>
                                </Link>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header
                                aria-expanded={activeKey === "3"}
                                className={`accordion-button ${activeKey === "3" ? "expanded" : "collapsed"}`}
                                onClick={() => handleAccordionClick("3")}
                            >
                                <Link className='f_big f_black f_400' to={'/organization'}>
                                    <span className={`nav_1_btn ${activeKey === "3" ? "active" : ""} f_big f_white f_400`}>YEHS 소개</span>
                                    <span className='nav_1_icon'></span>
                                </Link>
                            </Accordion.Header>
                            <Accordion.Body
                                className={activeKey === "3" ? "show" : ""}
                            >
                                <Link className={`nav_2_btn ${location.pathname === '/organization' ? 'active' : ''}`} to={'/organization'}>
                                    <span className='f_big f_black f_400'>조직 구성</span>
                                </Link>
                                <Link className={`nav_2_btn ${location.pathname === '/intro' ? 'active' : ''}`} to={'/intro'}>
                                    <span className='f_big f_black f_400'>YEHS 소개</span>
                                </Link>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header
                                aria-expanded={activeKey === "4"}
                                className={`accordion-button ${activeKey === "4" ? "expanded" : "collapsed"}`}
                                onClick={() => handleAccordionClick("4")}
                            >
                                <Link className='f_big f_black f_400' to={'/notice'}>
                                    <span className={`nav_1_btn ${activeKey === "4" ? "active" : ""} f_big f_white f_400`}>소식</span>
                                    <span className='nav_1_icon'></span>
                                </Link>
                            </Accordion.Header>
                            <Accordion.Body
                                className={activeKey === "4" ? "show" : ""}
                            >
                                <Link className={`nav_2_btn ${location.pathname === '/notice' ? 'active' : ''}`} to={'/notice'}>
                                    <span className='f_big f_black f_400'>공지사항</span>
                                </Link>
                                <Link className={`nav_2_btn ${location.pathname === '/periodical' ? 'active' : ''}`} to={'/periodical'}>
                                    <span className='f_big f_black f_400'>정기간행물</span>
                                </Link>
                                <Link className={`nav_2_btn ${location.pathname === '/hallOfFame' ? 'active' : ''}`} to={'/hallOfFame'}>
                                    <span className='f_big f_black f_400'>명예의전당</span>
                                </Link>
                                <Link className={`nav_2_btn ${location.pathname === '/memberNews' ? 'active' : ''}`} to={'/memberNews'}>
                                    <span className='f_big f_black f_400'>회원동정</span>
                                </Link>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header
                                aria-expanded={activeKey === "5"}
                                className={`accordion-button ${activeKey === "5" ? "expanded" : "collapsed"}`}
                                onClick={() => handleAccordionClick("5")}
                            >
                                <Link className='f_big f_black f_400' to={'/membershipRecruitment'}>
                                    <span className={`nav_1_btn ${activeKey === "4" ? "active" : ""} f_big f_white f_400`}>커뮤니티</span>
                                    <span className='nav_1_icon'></span>
                                </Link>
                            </Accordion.Header>
                            <Accordion.Body
                                className={activeKey === "5" ? "show" : ""}
                            >
                                {/* <Link className={`nav_2_btn ${location.pathname === '/membershipRecruitment' ? 'active' : ''}`} to={'/membershipRecruitment'}>
                                    <span className='f_big f_black f_400'>회원모집</span>
                                </Link> */}
                                <Link className={`nav_2_btn ${location.pathname === '/promotionBoard' ? 'active' : ''}`} to={'/promotionBoard'}>
                                    <span className='f_big f_black f_400'>홍보게시판</span>
                                </Link>
                                {/* <Link className={`nav_2_btn ${location.pathname === '/questionBoard' ? 'active' : ''}`} to={'/questionBoard'}>
                                    <span className='f_big f_black f_400'>질문게시판</span>
                                </Link>
                                <Link className={`nav_2_btn ${location.pathname === '/freeBoard' ? 'active' : ''}`} to={'/freeBoard'}>
                                    <span className='f_big f_black f_400'>자유게시판</span>
                                </Link> */}
                                
                                <Link className={`nav_2_btn ${location.pathname === '/eventBoard' ? 'active' : ''}`} to={'/eventBoard'}>
                                    <span className='f_big f_black f_400'>행사 캘린더</span>
                                </Link>
                                <Link className={`nav_2_btn ${location.pathname === '/yehstudy' ? 'active' : ''}`} to={'/yehstudy'}>
                                    <span className='f_big f_black f_400'>YEHStudy</span>
                                </Link>
                                <Link className={`nav_2_btn ${location.pathname === '/yehsGroup' ? 'active' : ''}`} to={'/yehsGroup'}>
                                    <span className='f_big f_black f_400'>YEHS 소모임</span>
                                </Link>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6">
                            <Accordion.Header
                                aria-expanded={activeKey === "6"}
                                className={`accordion-button ${activeKey === "6" ? "expanded" : "collapsed"}`}
                                onClick={() => handleAccordionClick("6")}
                            >
                                <Link className='f_big f_black f_400' to={'/mainSlider'}>
                                    <span className={`nav_1_btn ${activeKey === "4" ? "active" : ""} f_big f_white f_400`}>관리자</span>
                                    <span className='nav_1_icon'></span>
                                </Link>
                            </Accordion.Header>
                            <Accordion.Body
                                className={activeKey === "6" ? "show" : ""}
                            >
                                <Link className={`nav_2_btn ${location.pathname === '/mainSlider' ? 'active' : ''}`} to={'/mainSlider'}>
                                    <span className='f_big f_black f_400'>메인 슬라이더</span>
                                </Link>
                                <Link className={`nav_2_btn ${location.pathname === '/popup' ? 'active' : ''}`} to={'/popup'}>
                                    <span className='f_big f_black f_400'>메인 팝업</span>
                                </Link>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </nav>
            </div>
        </>

    );
}

export default Header;