// 리덕스 툴킷의 configureStore를 임포트
import { configureStore } from '@reduxjs/toolkit';
// redux-persist의 persistStore와 persistReducer를 임포트
import { persistStore, persistReducer } from 'redux-persist';
// storageSession을 사용하여 sessionStorage에 상태를 저장
import storageSession from 'redux-persist/lib/storage/session';
// redux의 combineReducers를 임포트
import { combineReducers } from 'redux';
// user 리듀서를 임포트
import userReducer from './userSlice';

// persist 설정을 구성합니다. key는 루트, storage는 sessionStorage를 사용
const persistConfig = {
    key: 'root',
    storage: storageSession, // sessionStorage로 변경
};

// 여러 리듀서를 결합합니다. 여기서는 user 리듀서만 사용
const rootReducer = combineReducers({
    user: userReducer,
});

// persist 설정을 적용한 리듀서를 생성
const persistedReducer = persistReducer(persistConfig, rootReducer);

// 리덕스 스토어를 설정합니다. persistedReducer를 사용
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

// 스토어를 지속할 수 있도록 persistor를 생성
const persistor = persistStore(store);

// store와 persistor를 익스포트하여 다른 파일에서 사용할 수 있도록 합니다.
export { store, persistor };
