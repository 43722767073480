// src/RequireAuth.js
import React, { useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import axios from 'axios';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


function RequireAuth({ children }) {
    const [loading, setLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await axios.post(`${API_BASE_URL}/admin_login/in/page_login_chk`, {}, {
                    withCredentials: true
                });
                if (response.data.result === 't') {
                    setIsLoggedIn(true);
                } else {
                    setIsLoggedIn(false);
                }
            } catch (error) {
                console.error('Auth check failed:', error);
                setIsLoggedIn(false);
            } finally {
                setIsLoggedIn(false);
            }
        };

        setLoading(true);
        checkAuth();
    }, [location.pathname]);

    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    // return isLoggedIn ? children : <Navigate to="/login" state={{ from: location }} replace />;
    return children;
}

export default RequireAuth;
