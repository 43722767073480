import React, { useState, useRef, useEffect, useCallback } from 'react';
import { DataTable, Editor, Resizer, Comment } from "../../components";
import common from '../../common';
import axios from 'axios';
import './Event.module.scss';
import { ExcelModal, DataTableModal } from "../../components/index";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Event({ cateIdx, type }) {
    const { useAlert, useLoading, pToast, useConfirm } = common();
    const { pAlert } = useAlert();
    const { pLoadingOn, pLoadingOff } = useLoading();
    
    const { pConfirm, ConfirmComponent } = useConfirm();
    const dataTableRef = useRef(null); // 등록 시 데이터테이블 리로드를 위한 함수
    const subTableRef = useRef(null);
    const [leftWidth, setLeftWidth] = useState(50); // 데이터테이블 영역 너비 %
    const [selectedData, setSelectedData] = useState(null); // 데이터테이블 선택 시 디테일 정보
    const quillRef = useRef(null);//에디터초기화
    const tempFormRef = useRef(null); // 제목 장소 초기화
    const [headCateOptions, setHeadCateOptions] = useState([]);
    const [formData, setFormData] = useState({
        cateIdx: cateIdx,//공통
        type: type,//공통
        viewStatus: true,//공통
        title: '',//공통
        contents: '',//공통

        writeType: '', // 공통
        writeTypeOption: [{ label: '일반', value: 'N' },{ label: '중요', value: 'Y' }], // 공통

        file: [], //공통
        fileDel: [], //공통
        fileList: [],//공통
        place: '',
        startDate: '0000-00-00',
        endDate: '0000-00-00',
        acceptStartTime: '0000-00-00 00:00:00',
        acceptEndTime: '0000-00-00 00:00:00',
        headCate: null,
        headCateOptions: [],
        thumbnail: [],
        thumbnailDel: [],
        thumbnailFile: null,
        distinction: '',
    });
    const columnDefs = [
        { headerName: 'tableType', field: 'tableType' },
        { headerName: 'idx', field: 'idx' },
        { headerName: '구분', field: 'distinction', minWidth: 70 },
        { headerName: '분류', field: 'cate', minWidth: 100 },
        { headerName: '행사명', field: 'title', flex: 1, minWidth: 400 },
        { headerName: '신청수', field: 'registerCount', minWidth: 75 },
        { headerName: '최종참가/최종불참', field: 'attended', minWidth: 100 },
        { headerName: '일정', field: 'date', minWidth: 200 },
        { headerName: '작성일', field: 'createTime', sort: 'desc', minWidth: 160 },
        { headerName: '조회수', field: 'view', minWidth: 80 },
        { headerName: '추천수', field: 'likeCount', minWidth: 80 },
        { headerName: '공개', field: 'status', minWidth: 65 },
        { headerName: '중요글', field: 'writeType', minWidth: 75 },
    ];

    const columnDefsSub = [
        { headerName: 'tableType', field: 'tableType' },
        { headerName: 'idx', field: 'idx' },
        { headerName: '신청일', field: 'createTime', sort: 'asc', minWidth: 180 },
        { headerName: '구분', field: 'grade', minWidth: 80 },
        { headerName: '이름', field: 'name', minWidth: 70 },
        { headerName: '학교/학과', field: 'university', minWidth: 150 },
        { headerName: '직장', field: 'job', minWidth: 150 },
        { headerName: '연락처', field: 'phone', minWidth: 150 },
        { headerName: '최종참석여부', field: 'status', minWidth: 150 },
        { headerName: '비고', field: 'ex', flex: 1, editable: true },
    ];

    const writeTypeOption = [{ label: '일반', value: 'N' },{ label: '중요', value: 'Y' }];

    const handleRowSelected = async (data) => {
        if (headCateOptions.length === 0) {
            pAlert('카테고리를 불러오지 못했습니다. 관리자에게 문의하세요.');
        };

        const selectedWriteTypeOption = writeTypeOption.find(option => option.value == data?.writeType);

        const selectedHeadCateOption = headCateOptions.find(option => option.value == data?.headCateIdx);
        let startDateData = null;
        if (data?.startDate && data.startDate !== '0000-00-00') {
            const parts = data.startDate.split('-');
            if (parts.length === 3) {
                const [year, month, day] = parts.map(part => parseInt(part, 10));
                startDateData = new Date(year, month - 1, day);
            }
        }
        let endDateData = null;
        if (data?.endDate && data.endDate !== '0000-00-00') {
            const parts = data.endDate.split('-');
            if (parts.length === 3) {
                const [year, month, day] = parts.map(part => parseInt(part, 10));
                endDateData = new Date(year, month - 1, day);
            }
        }


        let acceptStartTimeData = null;
        if (data?.acceptStartTime && data.acceptStartTime !== '0000-00-00 00:00:00') {
            const [datePart, timePart] = data.acceptStartTime.split(' ');
            const dateParts = datePart.split('-');
            const timeParts = timePart.split(':');

            if (dateParts.length === 3 && timeParts.length === 3) {
                const [year, month, day] = dateParts.map(part => parseInt(part, 10));
                const [hours, minutes, seconds] = timeParts.map(part => parseInt(part, 10));
                acceptStartTimeData = new Date(year, month - 1, day, hours, minutes, seconds);
            }
        }


        let acceptEndTimeData = null;
        if (data?.acceptEndTime && data.acceptEndTime !== '0000-00-00 00:00:00') {
            const [datePart, timePart] = data.acceptEndTime.split(' ');
            const dateParts = datePart.split('-');
            const timeParts = timePart.split(':');

            if (dateParts.length === 3 && timeParts.length === 3) {
                const [year, month, day] = dateParts.map(part => parseInt(part, 10));
                const [hours, minutes, seconds] = timeParts.map(part => parseInt(part, 10));
                acceptEndTimeData = new Date(year, month - 1, day, hours, minutes, seconds);
            }
        }


        setFormData((prevFormData) => ({
            ...prevFormData,
            viewStatus: data?.viewStatus === '1' ? true : false,
            title: data?.title || '',
            contents: data?.contents || '',

            writeType: selectedWriteTypeOption,
            writeTypeOption: writeTypeOption,

            place: data?.place || '',
            fileList: data?.file || [],

            startDateData: startDateData,
            startDate: data?.startDate || '0000-00-00',
            acceptStartTimeData: acceptStartTimeData,
            acceptStartTime: data?.acceptStartTime || '0000-00-00 00:00:00',

            endDateData: endDateData,
            endDate: data?.endDate || '0000-00-00',
            acceptEndTimeData: acceptEndTimeData,
            acceptEndTime: data?.acceptEndTime || '0000-00-00 00:00:00',

            file: [],
            fileDel: [],
            headCate: selectedHeadCateOption || headCateOptions[0],
            headCateIdx: data?.headCateIdx || '',
            thumbnail: data?.thumbnail || [],
            thumbnailDel: [],
            thumbnailFile: null,
            distinction: data?.distinction || '',
        }));

        setSelectedData({
            ...data,
            headCate: selectedHeadCateOption,
            viewStatus: data?.viewStatus === '1' ? true : false,
            title: data?.title || '',
            place: data?.place || '',
            contents: data?.contents || '',

            writeType: selectedWriteTypeOption,
            writeTypeOption: writeTypeOption,

            startDateData: startDateData,
            startDate: data?.startDate || '0000-00-00',
            acceptStartTimeData: acceptStartTimeData,
            acceptStartTime: data?.acceptStartTime || '0000-00-00 00:00:00',

            endDateData: endDateData,
            endDate: data?.endDate || '0000-00-00',
            acceptEndTimeData: acceptEndTimeData,
            acceptEndTime: data?.acceptEndTime || '0000-00-00 00:00:00',

            file: [], //
            fileDel: [], //
            fileBefore: data?.file || [],//파일비교시
            thumbnail: data?.thumbnail || [],
            thumbnailDel: [],
            thumbnailFile: null,
            distinction: data?.distinction || '',
            resetStatus: false, // 필수값임!!! 수정이나 삭제할 때 디테일 창 초기화 시킴.
        });
    };

    const handleBack = useCallback(() => {
        const currentFormData = formData || {};
        const currentQuill = quillRef.current;

        setFormData(() => ({
            cateIdx: cateIdx,
            type: type,
            viewStatus: true,
            title: '',
            place: '',
            contents: '',

            writeType: writeTypeOption[0] || '',
            writeTypeOption: writeTypeOption,

            startDateData: null,
            startDate: '0000-00-00',
            acceptStartTimeData: null,
            acceptStartTime: '0000-00-00 00:00:00',

            endDateData: null,
            endDate: '0000-00-00',
            acceptEndTimeData: null,
            acceptEndTime: '0000-00-00 00:00:00',

            fileList: [],
            headCate: headCateOptions[0] || null, // headCate 초기화 추가
            headCateOptions: headCateOptions, // headCateOptions 초기화 추가
            thumbnail: [],
            distinction: '',
        }));
        setSelectedData(() => ({
            resetStatus: true, // 필수값임!!! 수정이나 삭제할 때 디테일창 초기화 시킴.
        }));
        if (dataTableRef.current) {
            dataTableRef.current.clearSelectedRow(); // 선택된 행 초기화
        }
        if (currentQuill) {
            currentQuill.root.innerHTML = '';
        } else {
            pToast("시스템 오류");

        }

        if (tempFormRef.current) {
            tempFormRef.current.resetTempFormData(); // 제목,장소 초기화
        }


        // table_detail_box 클래스를 가진 요소를 선택하여 맨 위로 스크롤
        const tableDetailBox = document.querySelector('.table_detail_box');
        if (tableDetailBox) {
            tableDetailBox.scrollTo({
                top: 0,
                behavior: 'smooth' // 부드럽게 스크롤
            });
        }
    }, [formData, headCateOptions]);

    const tableLoadData = {
        tableType: 'Event',
        type: type,
        cate: [cateIdx]
    };

    const userTableLoadData = {
        tableType: 'EventSub',
        idx: selectedData?.idx || '', // 선택된 행의 idx를 사용
    };


    // useEffect(() => {
    //     console.log('formData 변경됨:', formData);
    // }, [formData]);
    // useEffect(() => {
    //     console.log('selectedData 변경됨:', selectedData);
    // }, [selectedData]);


    // 카테고리 불러오기
    useEffect(() => {
        const fetchOptions = async () => {
            try {
                pLoadingOn();
                const response = await axios.post(`${API_BASE_URL}/admin_document/in/get_cate`, { type: type, cateIdx: cateIdx }, {
                    withCredentials: true
                });
                if (response.data.result === 't') {
                    const headCate = response.data.data.map(option => ({
                        value: option.idx,
                        label: option.name
                    }));
                    setHeadCateOptions(headCate);
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        headCate: headCate[0] || null,
                        headCateOptions: headCate,
                    }));
                } else {
                    pAlert(response.data.msg);
                }
            } catch (error) {
                console.error('Error fetching options:', error);
                pAlert('데이터 가져오기에 실패했습니다. 다시 시도해주세요.');
            } finally {
                pLoadingOff();
            }
        };

        fetchOptions();
    }, [cateIdx]);

    useEffect(() => {
        if (subTableRef.current && selectedData?.idx) {
            subTableRef.current.refreshTable();
        }
    }, [selectedData?.idx]);

    // useEffect(() => {
    //     console.log('Selected data updated:', selectedData);
    //     // 여기에 selectedData가 업데이트되었을 때 수행할 작업을 추가
    // }, [selectedData]);























    

    // 참가신청 버튼
    const handleRegist = async () => {


        const tableModalLoadData = {
            tableType: 'EventRegist',
        };
    
        const columnDefsModal = [
            { headerName: 'tableType', field: 'tableType' },
            { headerName: 'idx', field: 'idx' },
            { headerName: '구분', field: 'gradeName', maxWidth: 100 },
            { headerName: '이름', field: 'name', maxWidth: 70 },
            { headerName: '이메일', field: 'email', maxWidth: 200 },
            { headerName: '연락처', field: 'phone', maxWidth: 120 },
            { headerName: '학교', field: 'university'},
            { headerName: '학과', field: 'major'},
            { headerName: '입회시기', field: 'joinMonth'},
        ];


        pConfirm(
            '참가신청',
            <>
                <div className="event_modal_p_dataTable_box no-overflow">
                    <DataTable
                        columnDefs={columnDefsModal}
                        ref={dataTableRef}  // ref 전달
                        tableLoadData={tableModalLoadData}  // DataTable에 전달할 값
                        targetEventIDX = {selectedData.idx}
                    />
                </div>
            </>,
            '1200px',
            '500px',
            async () => {
                window.location.reload();
            }
        );
    };


    // 엑셀
    
    //엑셀선택
    const handleExcelSelectedRows = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/admin_event/in/get_excel_colunm`,
                {
                    // menuIdx: menuIdx
                },
                {
                    withCredentials: true
                }
            );

            if (response.data.result === 't') {
                let returnData = null;  // returnData를 저장할 변수

                const handleReturnDataUpdate = (updatedData) => {
                    returnData = updatedData;
                };

                pConfirm(
                    'EXCEL 설정',
                    <ExcelModal data={response.data.data} onUpdate={handleReturnDataUpdate} />,
                    '600px',
                    'auto',
                    async () => {
                        try {
                            pLoadingOn();
                            // chkArr가 빈 배열이면 경고 메시지 출력
                            if (!returnData?.chkArr || returnData.chkArr.length === 0) {
                                pAlert('최소 하나의 항목을 선택해 주세요');
                                return;
                            }

                            const event_idx = selectedData.idx;

                            // 서버로 데이터 전송
                            const postResponse = await axios.post(
                                `${API_BASE_URL}/admin_event/in/get_excel`,
                                {
                                    data: returnData,
                                    event_idx: event_idx
                                    
                                },
                                {
                                    withCredentials: true,
                                    responseType: 'blob'  // 응답 타입을 blob으로 설정
                                }
                            );
                            let fileName = '참여회원목록.xlsx';

                            if (postResponse.status === 200) { // HTTP 응답 상태 코드가 200인 경우
                                const blob = new Blob([postResponse.data], { type: postResponse.headers['content-type'] }); // Blob 객체 생성
                                const downloadUrl = window.URL.createObjectURL(blob); // Blob URL 생성

                                // 파일 다운로드 링크 생성 및 클릭
                                const link = document.createElement('a');
                                link.href = downloadUrl;
                                link.download = fileName;  // 원하는 파일 이름으로 설정
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);

                                // Blob URL 해제
                                window.URL.revokeObjectURL(downloadUrl);
                            } else {
                                pAlert(postResponse.error);
                                return false;
                            }

                        } catch (error) {
                            console.error('Data sending failed:', error);
                            pAlert('데이터 전송에 실패했습니다. 다시 시도해주세요.');
                        } finally {
                            pLoadingOff();
                        }
                    },
                    '다운로드',
                    '취소',
                );
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('Data sending failed:', error);
            pAlert('데이터 전송에 실패했습니다. 다시 시도해주세요.');
        } finally {
            pLoadingOff();
        }
    };

    
    return (
        <>
            <ConfirmComponent/>
            <div className="components_wrap">
                <p className='page_title'>행사 목록</p>
                <div className="cm_table_wrap">
                    <div
                        className={`table_box`}
                        style={{ width: `${leftWidth}%` }}
                    >
                        <DataTable
                            columnDefs={columnDefs}
                            onRowSelected={handleRowSelected}
                            ref={dataTableRef}  // ref 전달
                            tableLoadData={tableLoadData}  // DataTable에 전달할 값
                        />
                    </div>
                    <Resizer onResize={setLeftWidth} initialLeftWidth={leftWidth} />
                    <div
                        className={`table_detail_editor_wrap`}
                        style={{ width: `${100 - leftWidth}%` }}
                    >

                        <div className="edit_btn_box_isdata_top">
                            {selectedData?.resetStatus === false && (
                                <button onClick={handleBack} className='edit_btn_ccc'>게시물 작성</button>
                            )}
                        </div>
                        <div className="table_detail_editor_box">
                            <div className="detail_editor_default_tit">
                                {selectedData?.resetStatus === false ? (
                                    <p>상세 정보</p>
                                ) : (
                                    <p>게시물 작성</p>
                                )}
                            </div>

                            <div className="detail_editor_default_wrap">
                                {selectedData?.idx && (
                                    <div className="sub_table_detail_box">
                                        <div className="sub_table_detail">
                                            <div className="sub_table_event_btn_box">
                                                <button onClick={handleRegist}>참가신청</button>
                                                <button onClick={handleExcelSelectedRows}>EXCEL</button>
                                            </div>
                                            <DataTable
                                                columnDefs={columnDefsSub}
                                                ref={subTableRef}  // ref 전달
                                                tableLoadData={userTableLoadData}  // DataTable에 전달할 값
                                                targetEventIDX = {selectedData.idx}
                                            />
                                        </div>
                                    </div>
                                )}
                                <Editor
                                    selectedData={selectedData}
                                    setSelectedData={setSelectedData}
                                    formData={formData}
                                    setFormData={setFormData}
                                    refreshTable={() => dataTableRef.current.refreshTable()}//데이터테이블 리로드용
                                    dataTableRef={dataTableRef} // dataTableRef 전달
                                    tempFormRef={tempFormRef}
                                    quillRef={quillRef}
                                />

                                {selectedData && selectedData.idx && (
                                    <Comment targetIdx={selectedData.idx} type={'Event'} totalCount={selectedData.likeCount} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Event;