import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { FaCalendarDay } from 'react-icons/fa';
import { addMonths, format } from 'date-fns';

function ExcelModal({ data, onUpdate }) {
    const { excel_column, select_column } = data;

    const [chkArr, setChkArr] = useState(excel_column);

    // selArr의 초기 값을 배열로 설정
    const [selArr, setSelArr] = useState(() => {
        const initialSelArr = {};
        excel_column.forEach(column => {
            if (select_column[column]) {
                initialSelArr[column] = ['전체'];
            }
        });
        return initialSelArr;
    });

    // const [startDate, setStartDate] = useState(format(addMonths(new Date(), -1), 'yyyy-MM-dd'));
    // const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));

    const handleCheckboxChange = (column) => {
        setChkArr((prevChkArr) => {
            if (prevChkArr.includes(column)) {
                // 체크 해제 시 selArr에서 해당 항목 제거
                const updatedSelArr = { ...selArr };
                delete updatedSelArr[column];
                setSelArr(updatedSelArr);

                return prevChkArr.filter(item => item !== column);
            } else {
                return [...prevChkArr, column];
            }
        });
    };

    const handleSelectChange = (column, selectedOption) => {
        setSelArr((prevSelArr) => ({
            ...prevSelArr,
            [column]: [selectedOption.value] // 값을 배열로 저장
        }));
    };

    useEffect(() => {
        const returnData = {
            // startDate,
            // endDate,
            chkArr,
            selArr
        };

        if (onUpdate) {
            onUpdate(returnData);
        }
    }, [chkArr, selArr, onUpdate]);

    const allOptions = [{ value: '전체', label: '전체' }];

    return (
        <div className="excel_model_wrap">
            {/* <div className="excel_model_box">
                <div className="excel_model_date_tit_box">
                    <p>날짜 선택</p>
                </div>
                <div className="excel_model_date__cont_box">
                    <div className="excel_model_cont_date_box">
                        <div className="datePickerWrapper">
                            <DatePicker
                                className='p_ip'
                                dateFormat="yyyy-MM-dd"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="0000-00-00"
                                selected={new Date(startDate)}
                                onChange={(date) => setStartDate(format(date, 'yyyy-MM-dd'))}
                            />
                            <FaCalendarDay className="calendarIcon" />
                        </div>
                        <span>-</span>
                        <div className="datePickerWrapper">
                            <DatePicker
                                className='p_ip'
                                dateFormat="yyyy-MM-dd"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="0000-00-00"
                                selected={new Date(endDate)}
                                onChange={(date) => setEndDate(format(date, 'yyyy-MM-dd'))}
                            />
                            <FaCalendarDay className="calendarIcon" />
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="excel_model_chk_box">
                {excel_column.map((column) => (
                    <div className="excel_model_box" key={column}>
                        <label className="p_chkbox">
                            <input
                                type="checkbox"
                                checked={chkArr.includes(column)}
                                onChange={() => handleCheckboxChange(column)}
                            />
                            <span>{column}</span>
                        </label>
                        {select_column[column] && (
                            <Select
                                value={selArr[column] ? { value: selArr[column][0], label: selArr[column][0] } : null}
                                onChange={(option) => handleSelectChange(column, option)}
                                options={[...allOptions, ...select_column[column].map(option => ({ value: option, label: option }))]}
                                placeholder="전체"
                                isDisabled={!chkArr.includes(column)}  // 체크 해제 시 비활성화
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ExcelModal;