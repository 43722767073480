import styles from './UserGrade.module.scss';
import { useState, useRef, useEffect } from 'react';
import { DataTable } from "../../components";
import common from '../../common';
import { FaMousePointer } from 'react-icons/fa';
import axios from 'axios';
import { FaCalendarDay } from 'react-icons/fa';


import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


function UserGrade() {
    const { useAlert, useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent } = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    const dataTableRef = useRef(null); // 등록 시 데이터테이블 리로드를 위한 함수

    const [columnDefs] = useState([
        { field: 'tableType', headerName: 'tableType' },
        { field: "idx", headerName: "번호" },
        { field: "name", headerName: "이름", minWidth: 80 },
        { field: "birth", headerName: "생년월일", minWidth: 100 },
        { field: "baseDate", headerName: "베이스날짜", minWidth: 100, filter: 'agDateColumnFilter', },
        { field: "gradeName", headerName: "등급", minWidth: 100, sort: 'desc', sortIndex: 0 },
        // { field: "nextBaseDate", headerName: "다음베이스날짜", minWidth: 100 },
        // { field: "nextGradeName", headerName: "다음등급", minWidth: 100 },
        { field: "sixMonthsLater", headerName: "단위(6개월)", minWidth: 115 },
        { field: "oneYearLater", headerName: "단위(12개월)", minWidth: 115 },
        { field: "oneYearSixMonthsLater", headerName: "단위(18개월)", minWidth: 115 },
        { field: "friendCount", headerName: "회원교류활동", minWidth: 130 },
        { field: "socialCount", headerName: "사회교류활동", minWidth: 130 },
        { field: "studyCount", headerName: "학술교류활동", minWidth: 130 },
        { field: "exCount", headerName: "기타" },
    ]);


    const tableLoadData = {
        tableType: 'UserGrade',
    };

    // ====================== 디테일 정보 뿌리기 ======================

    // info_cont 데이터
    const [selectedUserData, setSelectedUserData] = useState(null);

    const handleRowSelected = (data) => {
        const newData = data.map(item => ({
            ...item,
            resetStatus: false // 필수값임!!! 수정이나 삭제할 때 디테일 창 초기화 시킴.
        }));
        setSelectedUserData(newData);
    };


    // ====================== 디테일 정보 뿌리기 ======================

    // 삭제 함수
    const gradeDeleteFunction = async(idx ) => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/admin_grade/in/grade_delete`, {grade_idx : idx}, {
                withCredentials: true
            });
            if (response.data.result === 't') {
                pAlert('삭제가 완료되었습니다.');
                await dataTableRef.current.refreshTable();
                setSelectedUserData(prevFormData => {
                    const updatedData = {
                        ...prevFormData,
                        resetStatus: true
                    };
                    return updatedData;

                });
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('Data sending failed:', error);
            pAlert('데이터 전송에 실패했습니다. 다시 시도해주세요.');
        } finally {
            pLoadingOff();
        }
    }


    return (
        <>
            <AlertComponent />
            <ConfirmComponent />
            <LoadingComponent />
            <div className="components_wrap">
                <p className='page_title'>회원 등급</p>
                <div className={styles.cm_table_wrap}>
                    <div className={styles.user_table_wrap}>
                        <div className={styles.user_grade_sort_container}>
                            <div className={styles.user_grade_sort_top_container}>
                                <div className={styles.user_table_box}>
                                    <DataTable
                                        columnDefs={columnDefs}
                                        ref={dataTableRef}  // ref 전달
                                        onRowSelected={handleRowSelected}
                                        tableLoadData={tableLoadData}  // DataTable에 전달할 값
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.user_grade_sort_bot_container}>
                        </div>
                        <div className={`${styles.user_detail_box} ${styles.scroll_change}`}>
                            {Array.isArray(selectedUserData) ? (
                                <div className={styles.detail_wrap}>
                                    <div className={styles.detail_title}>상세 정보</div>
                                    <div className={styles.detail_info_box}>
                                        {selectedUserData.map((item, index) => (
                                            <div key={item.idx} className={styles.detail_info}>
                                                <span className={styles.info_title}>
                                                    등급명 : {item.gradeName}
                                                    {item.dateStatus === 'now' && (
                                                        <span className={styles.current_grade}> [현재 등급]</span>
                                                    )}
                                                </span>
                                                <span className={styles.info_content}>기준일 : {item.baseDate}</span>
                                                <span className={styles.info_content}>생성 시간 : {item.createTime}</span>
                                                <span className={styles.info_content}>상태 : {item.dateStatus}</span>
                                                <button
                                                    className={styles.delete_button}
                                                    onClick={() => pConfirm('제목','등급을 삭제하시겠습니까?','300px','auto',() => gradeDeleteFunction(item.idx))}
                                                >
                                                    X
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div className={styles.default_wrap}>
                                    <FaMousePointer className={`${styles.xi_mouse_pointer} ${styles.mouse_icon}`} />
                                    <span className={styles.exp_default_txt}>데이터를 선택해주세요.</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserGrade;
