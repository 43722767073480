import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

const Resizer = ({ onResize, initialLeftWidth }) => {
    const resizerRef = useRef(null);
    const frameRef = useRef(null);

    const handleMouseMove = useCallback((e) => {
        if (frameRef.current) {
            cancelAnimationFrame(frameRef.current);
        }
        frameRef.current = requestAnimationFrame(() => {
            onResize((e.clientX / window.innerWidth) * 100);
        });
    }, [onResize]);

    const handleMouseUp = useCallback(() => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    }, [handleMouseMove]);

    const handleMouseDown = useCallback(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }, [handleMouseMove, handleMouseUp]);

    useEffect(() => {
        const resizer = resizerRef.current;
        if (resizer) {
            resizer.addEventListener('mousedown', handleMouseDown);
        }

        return () => {
            if (resizer) {
                resizer.removeEventListener('mousedown', handleMouseDown);
            }
        };
    }, [handleMouseDown]);

    return <div className={"resizer"} ref={resizerRef} />;
};

Resizer.propTypes = {
    onResize: PropTypes.func.isRequired,
    initialLeftWidth: PropTypes.number.isRequired,
};

export default Resizer;