import React, { useState, useRef, useEffect, useCallback } from 'react';
import { DataTable, Editor, Resizer, Comment } from "../../components";
import common from '../../common';
import axios from 'axios';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function QuestionBoard({ cateIdx, type }) {
    const { pToast } = common();
    const dataTableRef = useRef(null); // 등록 시 데이터테이블 리로드를 위한 함수
    const [leftWidth, setLeftWidth] = useState(50); // 데이터테이블 영역 너비 %
    const [selectedData, setSelectedData] = useState(null); // 데이터테이블 선택 시 디테일 정보
    const quillRef = useRef(null);
    const [formData, setFormData] = useState({
        cateIdx: cateIdx,//공통
        type: type,//공통
        viewStatus: true,//공통
        title: '',//공통
        contents: '',//공통

        writeType: '', // 공통
        writeTypeOption: [{ label: '일반', value: 'N' },{ label: '중요', value: 'Y' }], // 공통

        file: [], //공통
        fileDel: [], //공통
        fileList: [],//공통
    });

    const tableLoadData = {
        tableType: 'QuestionBoard',
        type: type,
        cate: [cateIdx]
    };

    const columnDefs = [
        { headerName: 'tableType', field: 'tableType' },
        { headerName: '번호', field: 'idx' },
        { headerName: '제목', field: 'title', flex: 1 },
        { headerName: '작성자', field: 'member', maxWidth: 80 },
        { headerName: '작성일', field: 'createTime', sort: 'desc', maxWidth: 150 },
        { headerName: '조회수', field: 'view', maxWidth: 80 },
        { headerName: '추천수', field: 'likeCount', maxWidth: 80 },
        { headerName: '공개', field: 'status', maxWidth: 65 },
        { headerName: '중요글', field: 'writeType', maxWidth: 75 },
    ];

    const writeTypeOption = [{ label: '일반', value: 'N' },{ label: '중요', value: 'Y' }];

    const handleRowSelected = async (data) => {

        const selectedWriteTypeOption = writeTypeOption.find(option => option.value == data?.writeType);

        setFormData((prevFormData) => ({
            ...prevFormData,
            viewStatus: data?.viewStatus === '1' ? true : false,
            title: data?.title || '',
            contents: data?.contents || '',

            writeType: selectedWriteTypeOption,
            writeTypeOption: writeTypeOption,

            fileList: data?.file || [],
            file: [],
            fileDel: [],
        }));
        setSelectedData({
            ...data,
            viewStatus: data?.viewStatus === '1' ? true : false,
            title: data?.title || '',
            contents: data?.contents || '',

            writeType: selectedWriteTypeOption,
            writeTypeOption: writeTypeOption,

            file: [],
            fileDel: [],
            resetStatus: false, // 필수값임!!! 수정이나 삭제할 때 디테일 창 초기화 시킴.
        });
    };



    const handleBack = useCallback(() => {
        const currentFormData = formData || {};
        const currentQuill = quillRef.current;

        setFormData(() => ({
            cateIdx: currentFormData.cateIdx || '',
            type: currentFormData.type || '',

            writeType: writeTypeOption[0] || '',
            writeTypeOption: writeTypeOption,
            
            viewStatus: true,
            title: '',
            contents: '',
            fileList: [],
        }));
        setSelectedData(() => ({
            resetStatus: true, // 필수값임!!! 수정이나 삭제할 때 디테일창 초기화 시킴.
        }));
        if (dataTableRef.current) {
            dataTableRef.current.clearSelectedRow(); // 선택된 행 초기화
        }
        if (currentQuill) {
            currentQuill.root.innerHTML = '';
        } else {
            pToast("시스템 오류");
        }
        // table_detail_box 클래스를 가진 요소를 선택하여 맨 위로 스크롤
        const tableDetailBox = document.querySelector('.table_detail_box');
        if (tableDetailBox) {
            tableDetailBox.scrollTo({
                top: 0,
                behavior: 'smooth' // 부드럽게 스크롤
            });
        }
    }, [formData]);

    return (
        <>
            <div className="components_wrap">
                <p className='page_title'>질문게시판</p>
                <div className="cm_table_wrap">
                    <div
                        className={`table_box`}
                        style={{ width: `${leftWidth}%` }}
                    >
                        <DataTable
                            columnDefs={columnDefs}
                            onRowSelected={handleRowSelected}
                            ref={dataTableRef}  // ref 전달
                            tableLoadData={tableLoadData}  // DataTable에 전달할 값
                        />
                    </div>
                    <Resizer onResize={setLeftWidth} initialLeftWidth={leftWidth} />
                    <div
                        className={`table_detail_editor_wrap`}
                        style={{ width: `${100 - leftWidth}%` }}
                    >
                        <div className="edit_btn_box_isdata_top">
                            {selectedData?.resetStatus === false && (
                                <button onClick={handleBack} className='edit_btn_ccc'>게시물 작성</button>
                            )}
                        </div>
                        <div className="table_detail_editor_box">
                            <div className="detail_editor_default_tit">
                                {selectedData?.resetStatus === false ? (
                                    <p>상세 정보</p>
                                ) : (
                                    <p>게시물 작성</p>
                                )}
                            </div>
                            <div className="detail_editor_default_wrap">
                                <Editor
                                    selectedData={selectedData}
                                    setSelectedData={setSelectedData}
                                    formData={formData}
                                    setFormData={setFormData}
                                    refreshTable={() => dataTableRef.current.refreshTable()}//데이터테이블 리로드용
                                    dataTableRef={dataTableRef} // dataTableRef 전달
                                    quillRef={quillRef}
                                />
                                {selectedData && selectedData.idx && (
                                    <Comment targetIdx={selectedData.idx} type={'document'} totalCount={selectedData.likeCount} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default QuestionBoard;